.aboutTeam {
    display: flex;
    flex-direction: column;
    margin-top: 2vw;
    align-items: center;
    width: 95%;
    margin: auto;
    @include biggerMobile {
        width: 100%;
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5rem;
        gap: 1rem;
        position: relative;
        padding-bottom: 2rem;
        border-radius: 10px;
        @include maxSizeWeb{
            margin-bottom: 3rem;
        }
        @include maxWeb {
            flex-direction: column-reverse;
        }
        @include pad {
            @include arrowguide;
            &::after {
                bottom: -12rem;
                right: 0;
            }
        }
        &:nth-child(2n + 1) {
            flex-direction: row-reverse;
            @include maxWeb {
                flex-direction: column-reverse;
            }
        }
        .content {
            display: flex;
            flex-direction: column;
            gap: 2.08vw;
            @include maxWeb {
                align-items: center;
                justify-content: center;
                width: 100%;
                gap: 2rem;
            }
            .aboutMainVisualTitle {
                @include fontitle;
                font-weight: $fontWeightB;
                color: $colorA;
                @include letterSpacingC;
                position: relative;
                align-self: center;
                @include maxWeb {
                    margin: auto;
                    margin-top: 3rem;
                }
                &::after {
                    @include mobile {
                        content: unset;
                    }
                }
                @include pad {
                    padding-right: unset;
                }
                @include biggerMobile {
                    @include letterSpacingA;
                    @include fonSectitle;
                }
            }

            .contentContainer {
                @include maxWeb {
                    width: 70%;
                    display: flex;
                    flex-direction: column;
                }
                @include pad {
                    width: 100%;
                }
                .jobListContainer {
                    display: flex;
                    flex-direction: column;
                    margin: auto;
                  
                    .jobList {
                        @include fonSectitle;
                        font-weight: $fontWeightB;
                        color: $colorC;
                        @include letterSpacingC;
                        padding-left: 1rem;
                        position: relative;
                        margin-bottom: 1rem;
                        @include biggerMobile {
                            @include letterSpacingA;
                            @include fontrdtitle;
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            width: 7px;
                            height: 7px;
                            border-radius: 50%;
                            background-color: $colorC;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
        }
        .mainVisualImg {
            width: 50%;
            max-width: 35rem;
            max-height: 45rem;
          
            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
            @include maxWeb {
                width: 70%;
            }
            @include biggerMobile {
                width: 100%;
            }
        }
    }
}
.person {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    text-align: right;
    @include maxWeb {
        justify-content: center;
        width: 100%;
    }
    &:hover {
        .personContainer {
            transition: all 0.3s linear;
            background-color: $colorA;
            color: $colorB;
            .personTitleContainer {
                .personTitle {
                    .name {
                        transition: all 0.3s linear;
                        color: $colorB;
                    }
                }
            }
        }
    }
    @include maxWeb {
        text-align: unset;
        padding-right: unset;
    }
    .ImgVisual {
        width: 10rem;
        height: 10rem;
        border-radius: 50%;
        object-fit: cover;
        @include mobile {
            width: 8rem;
            height: 8rem;
        }
    }
    .personContainer {
        background-color: $colorF;
        @include borderRadiusA;
        position: relative;
        left: -2rem;
        padding: 1.25rem 1.25rem 1.25rem 3rem;
        z-index: -1;
        height: 50%;
        @include smallerMobile {
            width: 100%;
            left: -2rem;
            padding-left: 3rem;
        }
        .personTitleContainer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .personTitle {
                display: flex;
                align-items: flex-end;
                gap: 0.5rem;
                margin-bottom: 0.5vw;
                @include pad {
                    flex-wrap: wrap;
                }
                .name {
                    @include fontitle;
                    font-weight: $fontWeightB;
                    color: $colorC;
                    @include letterSpacingC;
                    position: relative;
                }
                span {
                    @include fonSectitle;
                    @include enFont;
                    font-weight: $fontWeightB;
                    display: block;
                    padding-bottom: 2px;
                }
            }
        }
        .position {
            @include letterSpacingB;
            @include fonSectitle;
            font-weight: $fontWeightC;
        }
    }
}
