.newsbtn {
    padding: 0.5rem 0.8rem;
    border: 1px solid $colorH;
    @include borderRadiusC;
    @include fonSectitle;
    @include letterSpacingB;
    color: $colorA;
    font-weight: $fontWeightA;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    margin: 2rem auto;
    &:hover {
        transition: all 0.5s linear;
        background-color: $colorA;
        color: $colorB;
    }
    @include maxWeb {
        width: 50%;
    }
    @include smallerMobile {
        @include fonSectitle;
    }
}

.leftLinkContainer {
    position: relative;
    width: fit-content;
    &:hover {
        &::after {
            display: block;
        }
    }
    &::after {
        content: "";
        position: absolute;
        width: 90%;
        background-color: $colorE;
        height: 2px;
        bottom: -5px;
        left: 0;
        display: none;
        transition: all 0.2s linear;
    }
    @include maxWeb {
        left: 50%;
        transform: translateX(-50%);
    }
    @include biggerMobile {
        left: unset;
        transform: unset;
    }
    .leftLink {
        @include fonSectitle;
        @include letterSpacingB;
        color: $colorH;
        font-weight: $fontWeightB;
        position: relative;
        padding-right: 2rem;
        &:hover {
            &::after {
                background-color: $colorH;
            }
            &::before {
                background-image: url("/src/frontImage/vectorWhite.svg");
            }
        }
        &::after {
            content: "";
            position: absolute;
            width: 2rem;
            height: 2rem;
            border-radius: 50%;
            right: -2rem;
            top: 50%;
            transform: translateY(-50%);
            border: 2px solid $colorA;
            transition: all 0.2s linear;
        }
        &::before {
            content: "";
            position: absolute;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            right: -2rem;
            z-index: 10;
            top: 50%;
            transform: translate(-50%, -50%);
            background-image: url("/src/frontImage/Vector.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            transition: all 0.3s linear;
        }
    }
}
//
.loading {
    position: fixed;
    background-color: $colorB;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    //loading的話要隱藏畫面
    .imgContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 8%;
        height: 8%;
        background-image: url("/src/frontImage/loading.gif");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin: auto;
        @include pad {
            width: 15%;
            height: 15%;
        }
        @include mobile {
            width: 20%;
            height: 20%;
        }
    }
}
//cookie
.cookie {
    .CookieConsent {
        left: unset !important;
        bottom: 2rem !important;
        padding: 1.5rem 3rem 1.5rem 2rem;
        width: 40% !important;
        border-radius: 10px 0 0 10px;
        align-items: center !important ;
        flex: unset !important;
        flex-wrap: unset !important;
        animation: move 0.8s linear;

        @keyframes move {
            0% {
                transform: translateX(100%);
            }
        }
        @include fontrdtitle;
        @include largeWeb {
            width: 65% !important;
        }
        @include maxWeb {
            width: 75% !important;
            left: 50% !important;
            padding: 1rem;
            transform: translateX(-50%);
            border-radius: 10px;
        }
        // @include pad {
        //     width: 8% !important;
        // }
        @include biggerMobile {
            flex: unset !important;
            width: 75% !important;
        }
        @include mobile {
            padding: 0 1rem 1rem 1rem;
            flex-direction: column;
        }
        div {
            &:nth-child(1) {
                width: 60%;
                flex: unset !important;
                @include mobile {
                    width: 100%;
                }
                @include smallerMobile {
                    width: 100%;
                    margin: unset;
                }
            }
        }
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 1rem 0 0 !important;
            padding: 1rem 2rem !important;
            background-color: $colorB !important;
            border: 1px solid $colorB !important;
            font-family: $fontChFamil;
            font-weight: $fontWeightB;
            @include letterSpacingB;
            &:hover {
                color: $colorI;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
                transition: all 0.3s linear;
            }
            @include biggerMobile {
                padding: 1rem !important;
            }
            @include mobile {
                padding: 0.5rem !important;
            }
        }
    }
}
//
.alert {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    letter-spacing: 2px;
    margin: 0 auto;
    backdrop-filter: blur(3px);
    box-shadow: 0 0 5px rgba(#000, 0.5);
    z-index: 11000;
    @include mobile {
        width: 100%;
    }
    .alertContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 30%;
        height: 10%;
        border-radius: 5px;
        z-index: 111;
        @include pad {
            width: 40%;
        }
        @include mobile {
            width: 50%;
        }
        .alertTxt {
            color: $colorB;
            font-weight: bold;
            text-align: center;
            margin: auto;
            line-height: 200%;
            padding-top: 0.8rem;
            z-index: 111;
        }
    }
}
//導引箭頭
@mixin arrowguide {
    &::after {
        content: "";
        position: absolute;
        background-image: url("/src/frontImage/側邊裝飾.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        width: 23px;
        height: 200px;
    }
    @include biggerMobile {
        &::after {
            content: unset;
        }
    }
}

//pad以下的版才會出現的scroll
.arrowDownArrow {
    transform: rotate(90deg);
    position: relative;
    @keyframes arrowdown {
        0% {
            opacity: 0;
            top: -1rem;
        }
        100% {
            opacity: 1;
            top: 0px;
        }
    }
    animation: arrowdown 2s linear infinite;
}
.arrowDownContainer {
    display: none;
    @include pad {
        display: block;
    }
    .arrowDown {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 3.25rem;
        gap: 0.5rem;
        .text {
            @include enFont;
            @include fonSectitle;
            color: $colorA;
            margin-bottom: 1rem;
        }
    }
}
