.main {
    padding: 10.875vw 5.1vw 2vw 6.1vw;
    position: relative;
    margin-bottom: 6rem;
    @include pad {
        padding: 8rem 5.1vw 2vw 6.1vw;
    }
    @include biggerMobile {
        padding: 8rem 0 1rem 0;
    }
    .carouselContainer {
        left: 25.5%;
        width: 66vw;
        height: 32vw;
        position: relative;
        @include pad {
            width: 100%;
            height: 23.75rem;
            left: 0;
        }
        @include biggerMobile {
            height: 16rem;
        }
    }
}
.bannerTitleContainer {
    width: 33vw;
    height: 14vw;
    z-index: 10;
    top: 16.6vw;
    opacity: 1;

    @include pad {
        display: none;
    }

    @keyframes fadeout {
        0% {
            transform: translateY(50px);
            opacity: 0;
        }
        50% {
            transform: translateY(10px);
            opacity: 0.3;
        }
        100%{
            transform: translateY(0px);
            opacity: 1;
        }
    }
    img {
        animation: fadeout 5s linear 
    }
}
.caorouselLink {
    position: absolute;
    top: 18vw;
    opacity: 1;
    animation: fadeout 5s linear 
}
.carouselContainer {
    @include mobile{
        height: auto;
    }

   
    .homeCarouselSwiper {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 100%;
        height: 100%;
        @include mobile{
            height: auto;
        }
   
        div {
            width: 100%;
            height: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .slick-initialized {
            .slick-slide {
                position: relative;
            }
        }
        .slick-dots {
            bottom: -40px !important;
            left: 0 !important;
            display: flex !important;
            gap: 0.2rem;
            @include pad {
                justify-content: center;
            }

            li {
                margin: 0px !important;
                button {
                    width: 0.72vw !important;
                    height: 0.72vw !important;
                    border-radius: 50%;
                    border: 1px solid $colorA !important;
                    position: relative;
                    &:hover {
                        &::before {
                            transition: all 0.3s linear;
                            background-color: $colorA;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        width: 100% !important;
                        height: 100% !important;
                        border-radius: 50%;
                        font-size: 0 !important;
                    }
                }
            }
            .slick-active {
                button {
                    background-color: $colorA !important;
                }
            }
        }
    }
}
