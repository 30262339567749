.aboutEsq {
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5rem;
        &:nth-child(odd) {
            flex-direction: row-reverse;
            position: relative;
            //側邊裝飾
            @include arrowguide;
            &::after {
                right: 0;
                bottom: -8rem;
            }
            @include pad {
                flex-direction: column-reverse;
            }
        }

        margin-bottom: 7.8vw;

        @include pad {
            flex-direction: column-reverse;
            margin-bottom: 8rem;
        }
        @include biggerMobile {
            gap: unset;
        }
        .content {
            display: flex;
            flex-direction: column;

            width: 40%;
            gap: 2.08vw;
            @include pad {
                align-items: center;
                justify-content: center;
                width: 90%;
                gap: unset;
            }
            @include mobile {
                width: 100%;
            }
            .aboutMainVisualTitle {
                @include fontitle;
                font-weight: $fontWeightB;
                color: $colorA;
                @include letterSpacingC;
                width: fit-content;
                padding-right: 1rem;
                position: relative;
                &::after {
                    @include yellowDirection;
                }
                @include pad {
                    margin: 4.5rem 0 3rem 0;
                }
                @include smallerMobile {
                    @include fontrdtitle;
                    padding-right: unset;
                    &::after {
                       content: unset;
                    }
                }
            }

            .contentContainer {
                margin-left: 1rem;
                @include pad{
                    margin-left: unset
                }
                p {
                    @include fontitle;
                    font-weight: $fontWeightB;
                    color: $colorC;
                    @include letterSpacingC;
                    padding-left: 0.5rem;
                    position: relative;
                    margin-bottom: 0.5vw;
                    &::before {
                        content: "";
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background-color: $colorC;
                        top: 50%;
                        left: -0.8rem;
                        transform: translateY(-50%);
                    }
                    @include pad {
                        @include fonSectitle;
                        margin-bottom: 0.8rem;
                    }
                    @include smallerMobile {
                        @include fontrdtitle;
                    }
                }
            }
        }
        .mainVisualImg {
            width: 60%;
            @include pad {
                width: 80%;
            }
            @include mobile {
                width: 100%;
            }
            img {
                margin: auto;
            }
        }
    }
}
