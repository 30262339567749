.awardSection {
    margin: 7rem 0;
    display: flex;
    justify-content: center;
    @include biggerMobile{
        margin-top: 3rem;
    }
    .container {
        display: flex;
        flex-direction: column;
        .card {
            &:nth-last-child(1) {
                .lineDeca {
                    display: none;
                }
            }
            .contentContainer {
                display: flex;
                align-items: center;
                position: relative;
                width: 95%;
                align-self: flex-end;
                margin: auto;
                position: relative;
                &::after {
                    content: "";
                    background-color: $colorG;
                    width: 90%;
                    height: 80%;
                    position: absolute;
                    z-index: -1;
                    border-radius: $borderRadiusA 0 0 $borderRadiusA;
                    @include pad {
                        display: none;
                    }
                }
                @include pad {
                    width: 70%;
                    align-self: center;
                }
                @include mobile {
                    width: 100%;
                }
                &:nth-last-child(1) {
                    &::after {
                        content: unset;
                    }
                }
                &:hover {
                    &::after {
                        transition: all 0.3s linear;
                        background-color: $colorA;
                        color: $colorB;
                    }
                    .content {
                        transition: all 0.3s linear;
                        color: $colorB;
                        .year {
                            transition: all 0.3s linear;
                            color: $colorB;
                        }
                    }

                    @include pad {
                        .content {
                            transition: all 0.3s linear;
                            background-color: $colorA;
                            color: $colorB;
                            .year {
                                transition: all 0.3s linear;
                                color: $colorB;
                            }
                        }
                    }
                }
                .content {
                    display: flex;
                    justify-content: space-between;
                    gap: 2rem;
                    padding-right: 3rem;
                    padding: 2rem;
                    width: 95%;
                    align-items: center;
                    @include pad {
                        flex-direction: column;
                        padding: 2rem 0;
                        width: 90%;
                        gap: unset;
                        background-color: $colorG;
                        margin-bottom: 0.5rem;
                        border-radius: $borderRadiusA;
                    }
                    @include biggerMobile {
                        padding-right: 0;
                        width: 100%;
                    }
                    .year {
                        padding-right: 3rem;
                        color: $colorA;
                        @include enFont;
                        @include fontBiggestTitle;
                        position: relative;
                        @include biggerMobile {
                            padding: unset;
                        }
                        &::after {
                            content: "";
                            position: absolute;
                            width: 1.5rem;
                            height: 2rem;
                            background-image: url("/src/frontImage/vector_yellow.svg");
                            background-repeat: no-repeat;
                            background-size: contain;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            @include biggerMobile {
                                display: none;
                            }
                        }
                    }
                    .detail {
                        width: 90%;
                        p {
                            width: 90%;
                            @include fontrdtitle;
                            font-weight: $fontWeightA;
                            padding-right: 3rem;
                            @include letterSpacingA;

                            @include pad {
                                padding-right: unset;
                                padding: 1.5rem;
                            }
                            @include maxWeb {
                                padding-right: 0;
                            }
                            @include biggerMobile {
                                width: 100%;
                                padding: 1rem;
                            }
                        }
                    }

                }
                .imgContainer {
                    width: 25rem;
                    height: 12rem;
                    @include pad {
                        width: 90%;
                        height: 15.5rem;
                    }
                    @include biggerMobile {
                        width: 100%;
                    }
                    img {
                        @include borderRadiusA;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                @include pad {
                    flex-direction: column;
                }
                @include biggerMobile {
                    flex-direction: column;
                    gap: 1rem;
                }
            }

            .lineDeca {
                display: block;
                height: 5rem;
                margin: 2rem;
                background-repeat: no-repeat;
                background-image: url("/src/frontImage/awardSectionLine.png");

                @include pad {
                    background-position: center;
                }
            }
        }
    }
}
