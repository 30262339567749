.serviceSpaceeMain {
    margin-top: 3rem;
    margin-bottom: 10rem;
    @include maxWeb {
        margin-top: unset;
        margin-bottom: 8rem;
        align-items: center;
    }
    @include biggerMobile {
        margin-bottom: 5rem;
    }
    .container {
        display: flex;
        gap: 4rem;
        position: relative;
        align-items: center;
        @include maxSizeWeb {
            gap: 2rem;
        }
        @include maxWeb {
            width: 70%;
            flex-direction: column-reverse;
            margin: auto;
            align-items: center;
            justify-content: center;
            gap: unset;
        }
        @include smallerPad {
            width: 90%;
        }
        @include mobile {
            width: 100%;
            gap: 1.5rem;
        }
        .arrow {
            position: absolute;
            width: 3vw;
            background-repeat: no-repeat;
            bottom: -6.4vw;
            right: 3vw;
            z-index: 100;
            background-image: url("/src/frontImage/serviceSpacee04.svg");
        }
        .content {
            // width: 45%;
            width: 50%;
            @include maxWeb {
                width: 100%;
            }

            .contentContainer {
                display: flex;
                flex-direction: column;
                &:nth-child(1) {
                    .contentList {
                        position: relative;
                        z-index: 10;

                        &::before {
                            content: "";

                            position: absolute;
                            width: 72px;
                            height: 132px;
                            z-index: 5;
                        }
                    }
                }
                &:nth-last-child(1) {
                    padding-top: 2rem;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        right: 0.15rem;
                        top: -0.15rem;
                        z-index: 5;
                        background-image: url("/src/frontImage/grayVector.svg");
                        background-repeat: no-repeat;
                        background-size: contain;
                        background-position: top right;
                        width: 4rem;
                        height: 6rem;
                        z-index: 10;
                        @keyframes fadedown {
                            0% {
                                top: 0;
                            }
                            100% {
                                top: -0.15rem;
                            }
                        }
                        animation: fadedown 2s linear 0s 1 normal both;
                        @include smallerMobile {
                            content: unset;
                        }
                    }
                    .contentList {
                        background-color: $colorH;
                        li {
                            color: $colorB;
                            &::before {
                                content: "";
                                background-image: url("/src/frontImage/servicespace02.svg");
                            }
                        }
                    }
                }
                .title {
                    margin-bottom: 1vw;
                    padding-right: 1rem;
                    width: fit-content;
                    position: relative;
                    font-weight: $fontWeightB;
                    @include fontitle;
                    @include letterSpacingA;

                    &::after {
                        content: "";
                        @include yellowDirection;
                    }
                    @include biggerMobile {
                        left: 1.5rem;
                        margin-bottom: 1rem;
                        max-width: 100%;
                        @include fonSectitle;
                    }
                }
                .contentList {
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;
                    background-color: $colorG;
                    padding: 2vw 0.5vw 2vw 1vw;
                    position: relative;
                    @include borderRadiusB;
                    @include maxWeb {
                        padding: 2rem 1.6rem 2.75rem 1.6rem;
                    }
                    @include biggerMobile {
                        padding: 1.5rem;
                        gap: 0.5rem;
                    }

                    li {
                        @include fonSectitle;
                        @include letterSpacingA;
                        font-weight: $fontWeightB;
                        color: $colorC;
                        position: relative;
                        padding-left: 2.5rem;
                        @include biggerMobile {
                            padding-left: 1.5rem;

                            @include fontContent;
                        }

                        &::before {
                            content: "";
                            position: absolute;
                            width: 1.5rem;
                            height: 100%;
                            top: 0;
                            left: 0;
                            background-image: url("/src/frontImage/servicespace01.svg");
                            background-repeat: no-repeat;
                            background-position: center;
                            @include biggerMobile {
                                width: 1rem;
                            }
                        }
                        span {
                            display: block;
                            width: 1.6vw;
                            height: 1.6vw;
                            img {
                                width: 2vw;
                                height: 2vw;
                            }
                        }
                    }
                }
            }
        }
        .mainVisualImg {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 80%;
            img {
                object-position: right center;
            }
            @include maxSizeWeb {
                width: 55%;
            }
            @include pad {
                width: 100%;
            }
        }
    }
}
.serviceSpaceSection {
    margin-top: 3vw;
    @include maxWeb {
        margin-top: unset;
    }
    .container {
        display: flex;
        gap: 7vw;
        margin-bottom: 11.97vw;
        &::after {
            @include mobile {
                content: unset;
            }
            content: "";
            position: absolute;
            right: 0;
            bottom: -7.5rem;
            width: 23px;
            height: 200px;
            z-index: -1;
            background-image: url("/src/frontImage/側邊裝飾.png");
            background-repeat: no-repeat;
            background-size: contain;
        }
        &:nth-child(even) {
            &::after {
                bottom: -8rem;
                left: 0.25rem;
                right: unset;
            }
        }
        &:nth-last-child(1) {
            &::after {
                content: unset;
            }
        }
        @include maxWeb {
            align-items: center;
            justify-content: center;
            flex-direction: column-reverse;
            margin-bottom: 10rem;
            gap: 5rem;
        }
        @include biggerMobile {
            margin-bottom: 4rem;
        }
        &:nth-child(odd) {
            @include maxWeb {
                flex-direction: column-reverse;
            }
            .slick-dots {
                right: unset !important;
                left: -30px !important;
                @include biggerMobile {
                    right: 0;
                    left: 0 !important;
                }
            }
        }
        &:nth-child(even) {
            flex-direction: row-reverse;
            @include maxWeb {
                flex-direction: column-reverse;
            }
        }
        .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
            @include maxWeb {
                align-items: center;
                justify-content: center;
                width: 100%;
            }
            .titleContainer {
                margin-bottom: 2.5rem;
                .iconContainer {
                    width: 4.4vw;
                    height: 4.7vw;
                    @include maxWeb {
                        width: 5.5rem;
                        height: 5.5rem;
                        margin: auto;
                        padding-bottom: 1rem;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                    @include mobile {
                        width: 5.3rem;
                        height: 5.7rem;
                    }
                }
                h4 {
                    font-weight: $fontWeightB;
                    @include fontitle;
                    @include letterSpacingA;
                    color: $colorH;
                }
            }
            .contentContainer {
                @include fontrdtitle;
                @include letterSpacingB;
                margin-bottom: 4rem;
                color: $colorC;
                @include maxWeb {
                    width: 65%;
                }
                @include biggerMobile {
                    width: 80%;
                }
                @include mobile {
                    width: 100%;
                }
                .content {
                    margin-bottom: 2.5rem;
                }
                .detail {
                    word-break: break-all;
                }
            }
            .linkContainer {
                justify-self: flex-end;
                a {
                }
            }
        }

        .carouselContainer {
            width: 35rem;
            height: 25rem;
            cursor: pointer;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &:hover {
                > .homeNewImgTitle {
                    height: 100%;
                    width: 100%;
                }
            }
            @include largeWeb {
                align-self: center;
            }
            @include maxWeb {
                width: 468px;
                height: 340px;
            }
            @include biggerMobile {
                width: 100%;
                height: auto;
            }
            > .slick-list {
                @include borderRadiusA;
            }
        }
        .slick-initialized {
            .slick-slide {
                position: relative;
            }
        }
        .slick-dots {
            bottom: 0px !important;
            width: 0.72vw !important;
            right: -25px !important;
            left: unset !important;
            display: grid !important;
            gap: 0.5vw;
            @include biggerMobile {
                bottom: -35px !important;
                width: 100% !important;
                right: 0 !important;
                left: 0 !important;
                display: block !important;
                gap: 0.5vw;
            }
            li {
                margin: 0px !important;
                button {
                    width: 0.72vw !important;
                    height: 0.72vw !important;
                    border-radius: 50%;
                    border: 1px solid $colorH !important;
                    position: relative;
                    &:hover {
                        &::before {
                            background-color: $colorH;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        width: 100% !important;
                        height: 100% !important;
                        border-radius: 50%;
                        font-size: 0 !important;
                    }
                }
            }
            .slick-active {
                button {
                    background-color: $colorH !important;
                    &:hover {
                        &::before {
                            background-color: $colorH;
                        }
                    }
                    &:before {
                        font-size: 20px;
                        left: 0;
                        right: 0;
                        border-radius: 50%;
                        opacity: 1 !important;
                        color: $colorH !important;
                    }
                }
            }
        }
    }
}
