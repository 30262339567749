.newsNav {
    position: absolute;
    right: 3.4rem;
    top: 30rem;
    max-width: 20%;
    @include pad {
        display: none;
    }
    .titleContainer {
        @include borderRadiusC;
        border: 1px solid $colorA;
        text-align: center;
        padding: 0.5rem;
        margin-bottom: 3rem;
        @include fonSectitle;
        .title {
            color: $colorA;
        }
    }
    .container {
        .containerCard {
            position: relative;
            &:hover {
                .pinImage {
                    transition: all 0.5s linear;
                    background-image: url("/src/frontImage/pinafter.png");
                }
            }
            .pinImage {
                position: absolute;
                top: 0;
                right: .5rem;
                width: 1vw;
                height: 2vw;
                background-image: url("/src/frontImage/pinbefore.png");
                background-size: contain;
                background-repeat: no-repeat;
                @include pad {
                    width: 3rem;
                    height: 4rem;
                }
            }
            .contentContainer {
                display: flex;
                gap: 1rem;
                padding: 1rem ;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                margin: 1.25rem 0;
                @include borderRadiusC;
                &:hover {
                    transition: all 0.5s linear;
                    background-color: $colorA;
                    color: $colorB;
                    .content {
                        .title {
                            color: $colorB;
                        }
                    }
                }
                @include maxWeb {
                    flex-wrap: wrap;
                    justify-content: center;
                }
                .imgContainer {
                    width: 5.875rem;
                    height: 5.875rem;
                    @include borderRadiusC;
                    img {
                        border-radius: $borderRadiusA;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .content {
                    max-width: 45%;
                    .title {
                        display: flex;
                        color: $colorA;
                        @include fontContent;
                        font-weight: $fontWeightA;
                        margin-bottom: .5rem;
                        flex-wrap: wrap;
                        @include letterSpacingB;
                        @include maxWeb {
                            justify-content: center;
                        }
                    }
                    .descruption {
                        @include fontSecContent;
                    }
                }
            }
        }
    }
}
