$navDot: 0.46875vw;
.headerFront {
    position: fixed;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 2.26vw 5.7vw 2.26vw 6.1vw;
    z-index: 100;
    top: 0;
    @include maxWeb{
        font-size: 1rem;
    }
    @include pad {
        padding: 3rem 2.125rem 2rem 2.125rem;
    }
    @include mobile{
        justify-content: center;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 0.3s;
        pointer-events: none;
        background-color: rgba(255, 255, 255, 0.94);
        backdrop-filter: blur(3px);
        box-shadow: 0 0 5px rgba(rgba(210, 209, 209, 0.348), 0.5);
        background-size: 100% 5px;
        width: 100%;
        z-index: -1;
        height: 100%;
    }
}
.headerLogo {
    position: relative;
    display: block;
    width: 12.18vw;
    height: 2.1875vw;
    background-image: url("/src/frontImage/logo.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @include pad {
        width: 9rem;
        height: 1.6rem;
    }
}
.navContainer {
    gap: 3.5vw;
    @include pad {
        display: none;
    }
    > div {
        @include headerfont;
        color: $colorA;
        font-weight: $fontWeightB;
        a {
            position: relative;
            padding-bottom: .25rem;
            width: fit-content;
            &::before {
                content: "";
                display: inline-block;
                vertical-align: middle;
                width: $navDot;
                height: $navDot;
                border-radius: 50%;
                background-color: $colorA;
                position: relative;
                left: -1.2vw;
            }
            &::after {
                content: "";
                width: 100%;
                height: 2px;
                position: absolute;
                bottom: 0;
                left: 0;
                background-color: $colorD;
                background-color: transparent;
                transition: all 0.3s linear;
                border-radius: 10px;
            }
            &:hover {
                &::after {
                    background-color: $colorD;
                }
            }
        }
    }
}

.headerIcon {
    width: 1.5rem;
}
