.awardMain {
    margin-bottom: 8rem;
    display: flex;
    justify-content: flex-end;
    padding-right: 3rem;
    @include largeWeb {
        padding-right: 1rem;
    }
    @include mobile{
        padding-right: unset;
    }
    .container {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        width: 90%;
        @include largeWeb {
            width: 95%;
        }
        @include maxWeb {
            gap: 8rem;
            width: 80%;
            margin: auto;
        }
        @include biggerMobile {
            gap: 4em;
            width: 100%;
        }
        @include smallerMobile {
            gap: unset;
        }
        .contentContainer {
            cursor: pointer;
            display: flex;
            align-items: center;
            position: relative;
            margin-top: 3rem;

            &:hover {
                .content {
                    transition: all 0.3s linear;
                    background-color: $colorA;
                    color: $colorB;
                }
            }

            .title {
                display: flex;
                gap: 2.5rem;
                position: relative;
                
                @include maxWeb {
                    gap: 1rem;
                    margin-bottom: 1.5rem;
                }
                @include mobile {
                    max-width: 100%;
                    justify-content: center;
                }
                @include smallerMobile {
                    flex-wrap: wrap;
                }
                .trophy {
                    width: 11.5rem;
                    height: 14rem;
                    @include pad {
                        width: unset;
                        height: unset;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .titleContainer {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 2rem;
                    padding-right: 3rem;
                    font-weight: $fontWeightB;
                    @include letterSpacingB;
                    width: 100%;
                    @include largeWeb {
                        padding-right: 0;
                    }
                    @include maxWeb {
                        padding-right: 3rem;
                    }
                    @include mobile {
                        padding-right: 0;
                        align-self: center;
                    }
                    h4 {
                        @include fontrdtitle;
                        @include pad {
                            text-align: center;
                        }
                        position: relative;
                        @include mobile{
                            &::after {
                                content: "";
                                position: absolute;
                                right: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                background-image: url("/src/frontImage/vector_yellow.svg");
                                height: 1.25rem;
                                width: 1.25rem;
                                background-size: contain;
                                background-repeat: no-repeat;
                                background-position: center;
                            }
                        }
                       
                    }
                    .imgContainer {
                        width: 9rem;
                        height: 3.5rem;
                        @include pad {
                            margin: auto;
                        }
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 2rem;
                    height: 2rem;
                    background-image: url("/src/frontImage/vector_yellow.svg");
                    background-repeat: no-repeat;
                    background-size: contain;
                    top: 50%;
                    right: 2.5rem;
                    transform: translateY(-50%);
                    @include largeWeb {
                        display: none;
                    }
                    @include maxWeb {
                        display: block;
                        right: 0rem;
                    }
                    @include mobile {
                        display: none;
                    }
                }
            }
            .content {
                display: flex;
                align-items: center;
                background-color: $colorG;
                border-radius: $borderRadiusA 0 0 $borderRadiusA;
                padding: 2.5rem 3.75rem;
                width: 60%;
                word-break: break-all;
                @include largeWeb {
                    padding: 2rem 3rem;
                }
                @include borderRadiusA;
                @include maxWeb {
                    width: 100%;
                }
                @include pad {
                    margin-top: 3rem;
                }
                @include biggerMobile {
                    padding: 1rem;
                }

                p {
                    @include fontrdtitle;
                    font-weight: $fontWeightA;
                    @include letterSpacingA;
                }
            }

            @include maxWeb {
                flex-direction: column;
            }
            @include mobile {
                flex-direction: column;
                margin-bottom: 5rem;
                gap: 0.5rem;
            }
        }
    }
}
