@mixin cardPoclyStyle {
    @include fontContent;
    font-weight: $fontWeightA;
    @include letterSpacingB;
    background-color: $colorG;
    padding: 2.6vw 0 1.5vw 0;
    @include biggerMobile {
        border-radius: 10px;
        padding: 1.25rem;
    }
}
.hirePolicyMain {
    .mainVisual {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 6vw;
        margin-bottom: 8rem;
        @include largeWeb {
            gap: unset;
        }

        @include maxWeb {
            flex-wrap: wrap;
            width: 70%;
            margin: auto;
            flex-direction: column-reverse;
        }
        @include mobile {
            width: 90%;
            margin-bottom: 2rem;
        }
        .mainVisualContent {
            display: flex;
            flex-direction: column;
            gap: 2.08vw;
            width: 40%;
            padding: 8vw 0;
            @include maxWeb {
                align-items: center;
                width: 90%;
                gap: 1rem;
            }
            @include mobile {
                width: 100%;
            }

            h2 {
                @include fontitle;
                font-weight: $fontWeightB;
                color: $colorH;
                @include letterSpacingA;
                @include mobile {
                    @include fonSectitle;
                }
            }
            p {
                @include fonSectitle;
                font-weight: $fontWeightA;
                color: $colorC;
                @include letterSpacingB;
                @include biggerMobile {
                    @include fontContent;
                }
            }
        }
        .mainVisualImg {
            width: 60%;
            @include maxWeb {
                width: 90%;
            }
            @include mobile {
                width: 100%;
            }
        }
    }

    .container {
        margin-bottom: 12vw;
        position: relative;
       
        @include arrowguide;
        &::after{
            right:-2rem;
            bottom: 12vw;
        }
        @include maxWeb {
            width: 90%;
            margin: 8rem auto;
        }
        @include mobile {
            width: 100%;
            margin: 4rem auto;
        }

        .titleContainer {
            display: flex;
            position: relative;
            @include fontitle;
            font-weight: $fontWeightA;
            @include letterSpacingA;
            width: fit-content;
            padding-right: 1rem;
            gap: 1vw;
            margin-bottom: 3vw;
            @include maxWeb {
                margin: auto;
                margin-bottom: 2rem;
                flex-wrap: wrap;
            }
            @include mobile {
                justify-content: space-around;
                @include fonSectitle;
            }

            span {
                @include enFont;
                color: $colorA;
            }
            h4 {
                font-weight: $fontWeightB;
                position: relative;
                @include mobile {
                    padding-right: 1rem;
                    &::after {
                        @include yellowDirection;
                    
                    }
                }
            }
            
        }
        .content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 1.09vw 1.56vw;
            align-items: center;
            @include maxWeb {
                width: 90%;
                margin: auto;
                gap: 1.5rem;
                grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
                justify-items: center;
            }
            @include smallerMobile {
                display: block;
                width: 100%;
            }
            .contentContainer {
                position: relative;
                cursor: pointer;
                text-align: center;
                width: 100%;

                .contenFirst {
                    @include cardPoclyStyle;

                    @include maxWeb {
                        padding: 2rem;
                    }
                    @include smallerMobile {
                        margin-bottom: 2rem;
                        padding: 1.5rem 2rem;
                    }
                    .imgcontainer {
                        width: 14vw;
                        height: 8vw;
                        margin: auto;
                        @include maxWeb {
                            width: 8rem;
                            height: 7rem;
                        }
                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                        }
                    }

                    h4 {
                        margin-top: 1.5vw;
                        font-weight: $fontWeightB;
                    }
                    span {
                        color: $colorH;
                        @include enFont;
                    }
                    p {
                        display: none;
                        font-weight: $fontWeightB;
                    }
                }
                .contentSec {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgb(255, 238, 0);
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: all 0.5s;
                    @include cardPoclyStyle;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    &:hover {
                        opacity: 1;
                    }
                    h4 {
                        @include fontContent;
                        font-weight: $fontWeightB;
                    }
                    span {
                        color: $colorH;
                        margin-bottom: 1vw;
                        font-weight: $fontWeightB;
                        @include enFont;
                    }
                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 0.2vw;
                        text-align: left;
                        margin-top: .5rem;
                        li {
                            position: relative;
                            font-weight: $fontWeightB;
                            &::after {
                                content: "";
                                position: absolute;
                                width: 0.25rem;
                                height: 0.25rem;
                                background-color: $colorC;
                                border-radius: 50%;
                                top: 50%;
                                left: -0.85rem;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
        }
    }
    .secContainer {
        display: flex;
        flex-direction: column;
        gap: 3vw;
        margin-bottom: 15vw;
        @include maxWeb {
            margin-top: 8rem;
        }
        .secContentContainer {
            display: flex;
            justify-content: space-between;
            gap: 2.6vw;
            @include pad {
                flex-direction: column-reverse;
                align-items: center;
            }
            &:nth-child(2n) {
                flex-direction: row-reverse;
                @include pad {
                    flex-direction: column-reverse;
                }
                .content {
                    width: 50%;
                    @include pad {
                        width: 100%;
                    }
                    h2 {
                        padding-left: 2vw;
                    }
                    .contentContainer {
                        padding-left: 3.1vw;
                    }
                }
                .mainVisual {
                    width: 55%;
                    @include pad {
                        width: 70%;
                    }
                    @include mobile {
                        width: 100%;
                    }
                }
            }
         
            .content {
                width: 55%;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @include pad {
                    width: 100%;
                }

                h2 {
                    color: $colorH;
                    @include fontitle;
                    @include letterSpacingC;
                    position: relative;
                    width: fit-content;
                    padding-right: 1rem;
                    margin-bottom: 2rem;
                    font-weight: $fontWeightB;
                    &::after {
                        @include yellowDirection;
                        @include biggerMobile {
                            display: none;
                        }
                    }
                    @include pad {
                        margin: 3rem 0 2rem 0;
                    }
                    @include biggerMobile{
                        @include fonSectitle;

                    }
                }
                .contentContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;
                    padding-left: 0.8vw;
                    @include letterSpacingA;
                    @include pad {
                        margin: auto;
                    }

                    li {
                        padding-left: 1rem;
                        position: relative;
                        @include fonSectitle;
                        font-weight: $fontWeightB;
                        @include mobile {
                            @include fontContent;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            width: 0.5rem;
                            height: 0.5rem;
                            background-color: $colorC;
                            border-radius: 50%;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            .mainVisual {
                width: 45%;
                @include pad {
                    width: 100%;
                }
            }
        }
    }
}
