//font;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Noto+Sans+TC:wght@300;500;700;900&display=swap");
$lineHeight: 200%;
$fontWeightA: 500;
$fontWeightB: 700;
$fontWeightC: 900;
$fontWeightD: 300;
$fontEnFamil: "Inter", sans-serif;
$fontChFamil: "Noto Sans TC", sans-serif;

@mixin enFont {
    font-family: $fontEnFamil;
    font-weight: $fontWeightD;
}
// color
$colorA: #0054a7;
$colorB: #ffffff;
$colorC: #000000;
$colorD: #ffd701;
$colorE: #ffd701;
$colorF: #f0f0f0;
$colorG: #f5f5f5;
$colorH: #076baf;
$colorI: #2d4390;
$colorJ: #d9d9d9;
$backgroundA: radial-gradient(82.47% 5023.99% at 10.42% 56.3%, #2d4390 0%, #076baf 100%);

// RWD 尺寸規範

@mixin maxSizeWeb {
    @media (max-width: 1536px) {
        @content;
    }
}

@mixin largeWeb {
    @media (max-width: 1440px) {
        @content;
    }
}
@mixin maxWeb {
    @media (max-width: 1240px) {
        @content;
    }
}
@mixin web {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin pad {
    @media (max-width: 1024px) {
        @content;
    }
}
@mixin smallerPad {
    @media (max-width: 768px) {
        @content;
    }
}
@mixin biggerMobile {
    @media (max-width: 640px) {
        @content;
    }
}
@mixin mobile {
    @media (max-width: 480px) {
        @content;
    }
}
@mixin smallerMobile {
    @media (max-width: 375px) {
        @content;
    }
}
@mixin selfLogoRwd {
    @media (max-width: 1200px) {
        @content;
    }
}
// font
@mixin fontBiggestTitle {
    font-size: 2.25rem;
    @include pad {
        font-size: 2.25rem;
    }
}
@mixin headerfont {
    font-size: 1.25rem;
    @include maxWeb {
        font-size: 1rem;
    }
}
//24px
@mixin fontitle {
    font-size: 1.5rem;
}
//20px
@mixin fonSectitle {
    font-size: 1.25rem;
}
//18px
@mixin fontrdtitle {
    font-size: 1.125rem;
}
//16px
@mixin fontContent {
    font-size: 1rem;
}
//14px
@mixin fontSecContent {
    font-size: 0.875rem;
}
//12px
@mixin fontmiddleContent {
    font-size: 0.75rem;
}

@mixin letterSpacingA {
    letter-spacing: 2px;
}
@mixin letterSpacingB {
    letter-spacing: 1px;
}
@mixin letterSpacingC {
    letter-spacing: 6px;
}
//borderRadiusA
$borderRadiusA: 15px;
@mixin borderRadiusA {
    border-radius: 15px;
}
@mixin borderRadiusB {
    border-radius: 30px;
}
@mixin borderRadiusC {
    border-radius: 10px;
}
@mixin borderRadiusD {
    border-radius: 6px;
}
@mixin labelstyle {
    cursor: pointer;
    @include fonSectitle;
    font-weight: $fontWeightB;
    @include letterSpacingB;
    position: relative;
    margin-bottom: 1.25rem;

    a {
        display: block;
        position: relative;
        padding-left: 1rem;
        text-align: center;
        &:hover {
            color: $colorD;
        }
        padding-right: 12.75rem;
        &::after {
            content: "";
            position: absolute;
            width: 45%;
            height: 2px;
            background-color: $colorB;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
        }
        @include biggerMobile {
            padding-right: unset;
            &::after {
                content: unset;
            }
        }

        &::before {
            content: "";
            position: absolute;
            width: 10px;
            height: 10px;
            background-color: $colorE;
            border-radius: 50%;
            top: 50%;
            left: 0rem;
            transform: translateY(-50%);
            @include biggerMobile {
                left: 1rem;
            }
        }
    }
}
@mixin insideMenustyle {
    @include fontContent;
    @include letterSpacingB;
    cursor: pointer;
    padding: 0rem 0rem 2rem 0rem;
    text-align: left;
    @include biggerMobile {
        text-align: center;
        padding: 1rem 0;
    }
    a {
        display: block;
        &:hover {
            color: $colorD;
        }
    }
}
@mixin navline {
    content: "";
    position: absolute;
    background-color: $colorB;
    width: 80%;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
}
body {
    font-family: "Noto Sans TC", sans-serif;
}

//共同container
html {
    scroll-behavior: smooth;
}
// 整體頁面
.ellipsis {
    overflow: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
body {
    background-color: $colorB;
}
// 針對圖片有連結沒聯結得樣式改變
.noUrl {
    cursor: context-menu;
}
.hasUrl {
    cursor: pointer;
}
.urlContainer {
    width: 100%;
    height: 100%;
    display: block;
}
.selfContainer {
    margin-left: 28%;
    // padding: 10rem 4rem 0 0;
    padding: 10rem 5.7vw 0 0;
    margin-bottom: 7.2vw;
    @include largeWeb {
        padding: 10rem 4rem 0 0;
    }
    @include pad {
        margin: 8rem 2.8rem 8.75rem 2.8rem;
        padding: unset;
    }
    @include pad {
        margin: 8rem 2rem 8.75rem 2rem;
        padding: unset;
    }
}
.selfContainerPage {
    padding: 8.14vw 5.7vw 2vw 5.7vw;
    @include pad {
        padding: 8rem 5.7vw 2vw 5.7vw;
    }
    @include mobile {
        margin-left: 0;
    }
}
//  yellowDirection
@mixin yellowDirection {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 3px;
    height: 70%;
    background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
}
// asideContactContainer可以跟跟這合併
.downContactContainer {
    width: 11.5rem;
    display: none;
    margin: auto;
    @include pad {
        display: block;
    }
    cursor: pointer;
    .asideContact {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border: 2px solid $colorA;
        padding: 0.5rem 0rem;
        border-radius: 15px;
        width: 90%;
        cursor: pointer;
        background: $colorB;
        transition: all 0.3s linear;
        &:hover {
            background: rgb(45, 67, 144);
            background: linear-gradient(90deg, rgba(45, 67, 144, 1) 0%, rgba(7, 107, 175, 1) 100%);
            .asideContactArrow {
                transition: all 0.3s linear;
                background-image: url("/src/frontImage/vectorWhite.svg");
            }
            a {
                transition: all 0.3s linear;
                color: $colorB;
            }
            .asideContactImgContainer {
                transition: all 0.3s linear;
                background-image: url("/src/frontImage/asideContact03.svg");
            }
        }
        .asideContactImgContainer {
            width: 2.5rem;
            height: 2.5rem;
            background-image: url("/src/frontImage/asideContact01.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
        a {
            @include fontContent;
            color: $colorA;
            @include letterSpacingB;
            span {
                @include fontContent;
                font-weight: $fontWeightB;
            }
        }
        .asideContactArrow {
            width: 1.25rem;
            height: 1.25rem;
            background-image: url("/src/frontImage/asideContact02.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
//
@mixin linkDot {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: $colorC;
    top: 50%;
    left: -0.8rem;
    transform: translateY(-50%);
}
@mixin opacityAnimate {
    opacity: 1;
    animation: opacity 2s linear;
    @keyframes opacity {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 0.3;
        }
        100% {
            opacity: 1;
        }
    }
}
.slick-next {
    display: none !important;
}
.slick-prev {
    display: none !important;
}
.errora {
    background-color: #e07a5f;
}
.successa {
    background-color: #81b29a;
}
@keyframes opacityBanner {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.opacityBanner{
    animation: opacityBanner 2s linear ;
}