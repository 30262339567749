
.navRwd {
    gap: 2.5rem;
    @include selfLogoRwd {
        gap: 1.5rem;
    }
    @include pad {
        display: none;
    }
}
.selfGoogleSearch {
    position: absolute;
    bottom: 0px;
    height: 100%;
    background-color: #fff;
    width: 100%;
    left: 0;
    @include web {
        right: 0;
    }
}
.searchTxt {
    font-size: 1.2rem;
    @include web {
        font-size: 1rem;
    }
}
.labelTxt {
    font-size: 1.2rem;
    @include maxWeb {
        font-size: 1rem;
    }
    @include web {
        font-size: 0.875rem;
    }
}
