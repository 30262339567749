.policy {
    padding-bottom: 8rem;
    width: 80%;
    margin: auto;
    padding-top: 10rem;
    .titleContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        @include letterSpacingA;
        margin-bottom: 6rem;
        gap: 1.5rem;
        @include pad {
            flex-direction: column;
        }
        h4 {
            color: $colorA;
            @include enFont;
            @include fontBiggestTitle;
        }
        span {
            align-self: flex-end;
            @include fonSectitle;
            transform: translateY(-0.5rem);
            font-weight: $fontWeightB;
            @include pad {
                transform: unset;
                align-self: center;
            }
        }
    }
    .contentContainer {
        .container {
            @include letterSpacingA;
            margin-bottom: 5rem;
            &:nth-child(1) {
                li {
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        width: 0.5rem;
                        height: 0.5rem;
                        background-color: $colorC;
                        border-radius: 30%;
                        top: 0;
                        transform: translateY(90%);
                    }
                }
            }
            &:nth-child(2) {
                li {
                    &::after {
                        content: "";
                        position: absolute;
                        left: 0;
                        width: 0.5rem;
                        height: 0.5rem;
                        background-color: $colorC;
                        border-radius: 30%;
                        top: 0;
                        transform: translateY(90%);
                    }
                }
            }
            h5 {
                color: $colorA;
                margin-bottom: 2rem;
                padding-right: 1.5rem;
                position: relative;
                width: fit-content;
                @include fontitle;
                font-weight: $fontWeightB;
                &::after {
                    @include yellowDirection;
                }
            }
            p {
                margin-bottom: 1.5rem;
                font-weight: $fontWeightA
                ;
            }
            li {
                padding-left: 1rem;
                margin-bottom: 1rem;
                position: relative;
            }
        }
    }
}
