@charset "UTF-8";
/*
*  html5resetcss
*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@900&family=Noto+Sans+TC:wght@300;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap");
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
  color: unset;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
  border: unset;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

p,
h1,
h2,
a,
span {
  font-family: "Noto Sans TC", sans-serif;
  word-wrap: break-word;
}

li {
  text-decoration: none;
  list-style: none;
}

body {
  background-color: #fcfcfc;
}

textarea,
input:focus,
select:focus {
  outline: none;
}

textarea {
  resize: none;
}

button {
  border: 0;
  background-color: transparent;
  outline: none;
}

img {
  height: auto;
  max-width: 100%;
}

body {
  font-family: "Noto Sans TC", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.ellipsis {
  overflow: hidden;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

body {
  background-color: #ffffff;
}

.noUrl {
  cursor: context-menu;
}

.hasUrl {
  cursor: pointer;
}

.urlContainer {
  width: 100%;
  height: 100%;
  display: block;
}

.selfContainer {
  margin-left: 28%;
  padding: 10rem 5.7vw 0 0;
  margin-bottom: 7.2vw;
}
@media (max-width: 1440px) {
  .selfContainer {
    padding: 10rem 4rem 0 0;
  }
}
@media (max-width: 1024px) {
  .selfContainer {
    margin: 8rem 2.8rem 8.75rem 2.8rem;
    padding: unset;
  }
}
@media (max-width: 1024px) {
  .selfContainer {
    margin: 8rem 2rem 8.75rem 2rem;
    padding: unset;
  }
}

.selfContainerPage {
  padding: 8.14vw 5.7vw 2vw 5.7vw;
}
@media (max-width: 1024px) {
  .selfContainerPage {
    padding: 8rem 5.7vw 2vw 5.7vw;
  }
}
@media (max-width: 480px) {
  .selfContainerPage {
    margin-left: 0;
  }
}

.downContactContainer {
  width: 11.5rem;
  display: none;
  margin: auto;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .downContactContainer {
    display: block;
  }
}
.downContactContainer .asideContact {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 2px solid #0054a7;
  padding: 0.5rem 0rem;
  border-radius: 15px;
  width: 90%;
  cursor: pointer;
  background: #ffffff;
  transition: all 0.3s linear;
}
.downContactContainer .asideContact:hover {
  background: rgb(45, 67, 144);
  background: linear-gradient(90deg, rgb(45, 67, 144) 0%, rgb(7, 107, 175) 100%);
}
.downContactContainer .asideContact:hover .asideContactArrow {
  transition: all 0.3s linear;
  background-image: url("/src/frontImage/vectorWhite.svg");
}
.downContactContainer .asideContact:hover a {
  transition: all 0.3s linear;
  color: #ffffff;
}
.downContactContainer .asideContact:hover .asideContactImgContainer {
  transition: all 0.3s linear;
  background-image: url("/src/frontImage/asideContact03.svg");
}
.downContactContainer .asideContact .asideContactImgContainer {
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("/src/frontImage/asideContact01.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.downContactContainer .asideContact a {
  font-size: 1rem;
  color: #0054a7;
  letter-spacing: 1px;
}
.downContactContainer .asideContact a span {
  font-size: 1rem;
  font-weight: 700;
}
.downContactContainer .asideContact .asideContactArrow {
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("/src/frontImage/asideContact02.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.slick-next {
  display: none !important;
}

.slick-prev {
  display: none !important;
}

.errora {
  background-color: #e07a5f;
}

.successa {
  background-color: #81b29a;
}

@keyframes opacityBanner {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.opacityBanner {
  animation: opacityBanner 2s linear;
}

.newsbtn {
  padding: 0.5rem 0.8rem;
  border: 1px solid #076baf;
  border-radius: 10px;
  font-size: 1.25rem;
  letter-spacing: 1px;
  color: #0054a7;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  margin: 2rem auto;
}
.newsbtn:hover {
  transition: all 0.5s linear;
  background-color: #0054a7;
  color: #ffffff;
}
@media (max-width: 1240px) {
  .newsbtn {
    width: 50%;
  }
}
@media (max-width: 375px) {
  .newsbtn {
    font-size: 1.25rem;
  }
}

.leftLinkContainer {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}
.leftLinkContainer:hover::after {
  display: block;
}
.leftLinkContainer::after {
  content: "";
  position: absolute;
  width: 90%;
  background-color: #ffd701;
  height: 2px;
  bottom: -5px;
  left: 0;
  display: none;
  transition: all 0.2s linear;
}
@media (max-width: 1240px) {
  .leftLinkContainer {
    left: 50%;
    transform: translateX(-50%);
  }
}
@media (max-width: 640px) {
  .leftLinkContainer {
    left: unset;
    transform: unset;
  }
}
.leftLinkContainer .leftLink {
  font-size: 1.25rem;
  letter-spacing: 1px;
  color: #076baf;
  font-weight: 700;
  position: relative;
  padding-right: 2rem;
}
.leftLinkContainer .leftLink:hover::after {
  background-color: #076baf;
}
.leftLinkContainer .leftLink:hover::before {
  background-image: url("/src/frontImage/vectorWhite.svg");
}
.leftLinkContainer .leftLink::after {
  content: "";
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  right: -2rem;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #0054a7;
  transition: all 0.2s linear;
}
.leftLinkContainer .leftLink::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  right: -2rem;
  z-index: 10;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("/src/frontImage/vector.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s linear;
}

.loading {
  position: fixed;
  background-color: #ffffff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 101;
}
.loading .imgContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 8%;
  height: 8%;
  background-image: url("/src/frontImage/loading.gif");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: auto;
}
@media (max-width: 1024px) {
  .loading .imgContainer {
    width: 15%;
    height: 15%;
  }
}
@media (max-width: 480px) {
  .loading .imgContainer {
    width: 20%;
    height: 20%;
  }
}

.cookie .CookieConsent {
  left: unset !important;
  bottom: 2rem !important;
  padding: 1.5rem 3rem 1.5rem 2rem;
  width: 40% !important;
  border-radius: 10px 0 0 10px;
  align-items: center !important;
  flex: unset !important;
  flex-wrap: unset !important;
  animation: move 0.8s linear;
  font-size: 1.125rem;
}
@keyframes move {
  0% {
    transform: translateX(100%);
  }
}
@media (max-width: 1440px) {
  .cookie .CookieConsent {
    width: 65% !important;
  }
}
@media (max-width: 1240px) {
  .cookie .CookieConsent {
    width: 75% !important;
    left: 50% !important;
    padding: 1rem;
    transform: translateX(-50%);
    border-radius: 10px;
  }
}
@media (max-width: 640px) {
  .cookie .CookieConsent {
    flex: unset !important;
    width: 75% !important;
  }
}
@media (max-width: 480px) {
  .cookie .CookieConsent {
    padding: 0 1rem 1rem 1rem;
    flex-direction: column;
  }
}
.cookie .CookieConsent div:nth-child(1) {
  width: 60%;
  flex: unset !important;
}
@media (max-width: 480px) {
  .cookie .CookieConsent div:nth-child(1) {
    width: 100%;
  }
}
@media (max-width: 375px) {
  .cookie .CookieConsent div:nth-child(1) {
    width: 100%;
    margin: unset;
  }
}
.cookie .CookieConsent button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 0 0 !important;
  padding: 1rem 2rem !important;
  background-color: #ffffff !important;
  border: 1px solid #ffffff !important;
  font-family: "Noto Sans TC", sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}
.cookie .CookieConsent button:hover {
  color: #2d4390;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  transition: all 0.3s linear;
}
@media (max-width: 640px) {
  .cookie .CookieConsent button {
    padding: 1rem !important;
  }
}
@media (max-width: 480px) {
  .cookie .CookieConsent button {
    padding: 0.5rem !important;
  }
}

.alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  letter-spacing: 2px;
  margin: 0 auto;
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  z-index: 11000;
}
@media (max-width: 480px) {
  .alert {
    width: 100%;
  }
}
.alert .alertContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 10%;
  border-radius: 5px;
  z-index: 111;
}
@media (max-width: 1024px) {
  .alert .alertContainer {
    width: 40%;
  }
}
@media (max-width: 480px) {
  .alert .alertContainer {
    width: 50%;
  }
}
.alert .alertContainer .alertTxt {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin: auto;
  line-height: 200%;
  padding-top: 0.8rem;
  z-index: 111;
}

.arrowDownArrow {
  transform: rotate(90deg);
  position: relative;
  animation: arrowdown 2s linear infinite;
}
@keyframes arrowdown {
  0% {
    opacity: 0;
    top: -1rem;
  }
  100% {
    opacity: 1;
    top: 0px;
  }
}

.arrowDownContainer {
  display: none;
}
@media (max-width: 1024px) {
  .arrowDownContainer {
    display: block;
  }
}
.arrowDownContainer .arrowDown {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 3.25rem;
  gap: 0.5rem;
}
.arrowDownContainer .arrowDown .text {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1.25rem;
  color: #0054a7;
  margin-bottom: 1rem;
}

.headerFront {
  position: fixed;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2.26vw 5.7vw 2.26vw 6.1vw;
  z-index: 100;
  top: 0;
}
@media (max-width: 1240px) {
  .headerFront {
    font-size: 1rem;
  }
}
@media (max-width: 1024px) {
  .headerFront {
    padding: 3rem 2.125rem 2rem 2.125rem;
  }
}
@media (max-width: 480px) {
  .headerFront {
    justify-content: center;
  }
}
.headerFront::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.94);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  box-shadow: 0 0 5px rgba(210, 209, 209, 0.5);
  background-size: 100% 5px;
  width: 100%;
  z-index: -1;
  height: 100%;
}

.headerLogo {
  position: relative;
  display: block;
  width: 12.18vw;
  height: 2.1875vw;
  background-image: url("/src/frontImage/logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 1024px) {
  .headerLogo {
    width: 9rem;
    height: 1.6rem;
  }
}

.navContainer {
  gap: 3.5vw;
}
@media (max-width: 1024px) {
  .navContainer {
    display: none;
  }
}
.navContainer > div {
  font-size: 1.25rem;
  color: #0054a7;
  font-weight: 700;
}
@media (max-width: 1240px) {
  .navContainer > div {
    font-size: 1rem;
  }
}
.navContainer > div a {
  position: relative;
  padding-bottom: 0.25rem;
  width: -moz-fit-content;
  width: fit-content;
}
.navContainer > div a::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 0.46875vw;
  height: 0.46875vw;
  border-radius: 50%;
  background-color: #0054a7;
  position: relative;
  left: -1.2vw;
}
.navContainer > div a::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #ffd701;
  background-color: transparent;
  transition: all 0.3s linear;
  border-radius: 10px;
}
.navContainer > div a:hover::after {
  background-color: #ffd701;
}

.headerIcon {
  width: 1.5rem;
}

.navRwd {
  gap: 2.5rem;
}
@media (max-width: 1200px) {
  .navRwd {
    gap: 1.5rem;
  }
}
@media (max-width: 1024px) {
  .navRwd {
    display: none;
  }
}

.selfGoogleSearch {
  position: absolute;
  bottom: 0px;
  height: 100%;
  background-color: #fff;
  width: 100%;
  left: 0;
}
@media (max-width: 992px) {
  .selfGoogleSearch {
    right: 0;
  }
}

.searchTxt {
  font-size: 1.2rem;
}
@media (max-width: 992px) {
  .searchTxt {
    font-size: 1rem;
  }
}

.labelTxt {
  font-size: 1.2rem;
}
@media (max-width: 1240px) {
  .labelTxt {
    font-size: 1rem;
  }
}
@media (max-width: 992px) {
  .labelTxt {
    font-size: 0.875rem;
  }
}

.navMenu {
  position: fixed;
  z-index: 101;
  top: 3rem;
  right: 2%;
  display: none;
}
@media (max-width: 1024px) {
  .navMenu {
    display: block;
  }
}
.navMenu .hamburger {
  z-index: 101;
  cursor: pointer;
  position: relative;
}
.navMenu .hamburger .ham .hamLine {
  width: 1.625rem;
  height: 2px;
  border-radius: 3px;
  background-color: #0054a7;
  margin: 5.5px;
  transition: all 0.3s ease-in-out;
}
.navMenu .hamburger .times .hamLine {
  width: 1.625rem;
  height: 2px;
  border-radius: 3px;
  margin: 5.5px;
  transition: all 0.3s linear;
}
.navMenu .hamburger .times .topChange {
  transform: rotate(-45deg);
  margin-top: 25px;
  background-color: white;
  z-index: 101;
}
.navMenu .hamburger .times .bottomChange {
  transform: rotate(45deg);
  margin-top: -7px;
  background-color: white;
  z-index: 101;
}
.navMenu .toggleContainer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-height: 100%;
  z-index: 100;
  overflow-y: auto;
}
@media (max-width: 1024px) {
  .navMenu .toggleContainer {
    width: 100%;
  }
}
.navMenu .toggleContainer .toggleContainerBc {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 8, 20, 0.5882352941);
}
.navMenu .toggleContainer .toggleContent {
  background-color: #0054a7;
  color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.1s linear;
  align-items: center;
  overflow-y: scroll;
  width: 100%;
  z-index: 10;
  position: relative;
}
.navMenu .toggleContainer .toggleContent .content {
  padding-bottom: 1rem;
}
@media (max-width: 1024px) {
  .navMenu .toggleContainer .toggleContent .content li {
    display: flex;
    margin-bottom: 6.5rem;
  }
}
@media (max-width: 640px) {
  .navMenu .toggleContainer .toggleContent .content li {
    display: block;
    margin: unset;
  }
}
.navMenu .toggleContainer .toggleContent .content li:nth-last-child(1) .labelContainer a::after {
  content: unset;
}
.navMenu .toggleContainer .toggleContent .content li:nth-last-child(1) .insideMenuContainer::before {
  content: unset;
}
.navMenu .toggleContainer .toggleContent .content .labelContainer {
  cursor: pointer;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 1px;
  position: relative;
  margin-bottom: 1.25rem;
}
.navMenu .toggleContainer .toggleContent .content .labelContainer a {
  display: block;
  position: relative;
  padding-left: 1rem;
  text-align: center;
  padding-right: 12.75rem;
}
.navMenu .toggleContainer .toggleContent .content .labelContainer a:hover {
  color: #ffd701;
}
.navMenu .toggleContainer .toggleContent .content .labelContainer a::after {
  content: "";
  position: absolute;
  width: 45%;
  height: 2px;
  background-color: #ffffff;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}
@media (max-width: 640px) {
  .navMenu .toggleContainer .toggleContent .content .labelContainer a {
    padding-right: unset;
  }
  .navMenu .toggleContainer .toggleContent .content .labelContainer a::after {
    content: unset;
  }
}
.navMenu .toggleContainer .toggleContent .content .labelContainer a::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #ffd701;
  border-radius: 50%;
  top: 50%;
  left: 0rem;
  transform: translateY(-50%);
}
@media (max-width: 640px) {
  .navMenu .toggleContainer .toggleContent .content .labelContainer a::before {
    left: 1rem;
  }
}
.navMenu .toggleContainer .toggleContent .toggleContentTxt {
  margin: 3.125rem 0 3.7rem 0;
}
.navMenu .toggleContainer .toggleContent .toggleContentTxt img {
  width: 9rem;
  height: 1.6rem;
}
.navMenu .toggleContainer .toggleContent .navHamIcon {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.navMenu .toggleContainer .toggleContent .navHamIcon .navHamIconLink {
  display: block;
  color: #dee2e6;
}
@media (max-width: 480px) {
  .navMenu .toggleContainer .toggleContent .navHamIcon .navHamIconLink {
    font-size: 2rem;
  }
}
.navMenu .toggleContainer .iconContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  background-color: #0054a7;
  display: flex;
  z-index: 100;
  width: 100%;
  justify-content: center;
  gap: 1rem;
}
.navMenu .toggleContainer .iconContainer li {
  transition: all 0.5s linear;
  padding-bottom: 0.1rem;
}
.navMenu .toggleContainer .iconContainer li .socialLink {
  position: relative;
  display: block;
  transition: all 1s linear;
}
.navMenu .toggleContainer .iconContainer li .socialLink:hover {
  transition: all 0.5s linear;
}
.navMenu .toggleContainer .iconContainer li .socialLink:hover .socialImg {
  opacity: 1;
}
.navMenu .toggleContainer .iconContainer li .socialLink .socialImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s linear;
}
.navMenu .toggleContainer .iconContainer li .socialIcon {
  position: absolute;
  z-index: 12;
  color: #0054a7;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
.navMenu .toggleBc {
  background-color: rgba(235, 6, 6, 0.2862745098);
  position: fixed;
  z-index: 100;
  width: 50vw;
  height: 100vh;
  top: 0;
  right: 0;
  left: 0;
  transform: translateX(0);
  transition: all 0.5s linear;
  animation: bcslideout 3 linear;
}
@keyframes bcslideout {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(0);
  }
}

/* 點擊後漢堡變叉 的動畫*/
.topChange {
  transform: rotate(-45deg);
  margin-top: 25px;
  background-color: white;
  z-index: 101;
}

.middleChange {
  opacity: 0;
  transform: rotate(45deg);
}

.bottomChange {
  transform: rotate(45deg);
  margin-top: -7px;
  background-color: white;
  z-index: 101;
}

#toggle:checked ~ .toggleContainer {
  visibility: visible;
}

#toggle:checked ~ .toggleContainer > div {
  transform: scale(1);
}

#toggle:checked ~ .toggleContainer > div > div {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.childrenConatiner {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.navHamellipsis {
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.insideMenuContainer {
  position: relative;
  margin-bottom: 1.25rem;
  padding: 0rem;
}
@media (max-width: 640px) {
  .insideMenuContainer {
    padding: 1.875rem 0;
  }
}
@media (max-width: 640px) {
  .insideMenuContainer::after {
    content: "";
    position: absolute;
    background-color: #ffffff;
    width: 80%;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }
}
@media (max-width: 640px) {
  .insideMenuContainer::before {
    content: "";
    position: absolute;
    background-color: #ffffff;
    width: 80%;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
  }
}
.insideMenuContainer .insideMenu {
  font-size: 1rem;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 0rem 0rem 2rem 0rem;
  text-align: left;
}
@media (max-width: 640px) {
  .insideMenuContainer .insideMenu {
    text-align: center;
    padding: 1rem 0;
  }
}
.insideMenuContainer .insideMenu a {
  display: block;
}
.insideMenuContainer .insideMenu a:hover {
  color: #ffd701;
}

.footer {
  color: #ffffff;
  padding: 1rem;
  background: radial-gradient(82.47% 5023.99% at 10.42% 56.3%, #2d4390 0%, #076baf 100%);
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  bottom: 0;
  z-index: 10;
}
@media (max-width: 480px) {
  .footer {
    padding: 2.25rem 1rem;
  }
}
@media (max-width: 375px) {
  .footer {
    padding: 2.25rem 1rem;
  }
}
.footer .footerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}
@media (max-width: 768px) {
  .footer .footerContainer {
    flex-wrap: wrap;
  }
}
@media (max-width: 480px) {
  .footer .footerContainer {
    flex-direction: column;
  }
}
.footer .footerContainer .footerLogoContainer {
  width: 31.5vw;
  padding: 3rem;
  margin-right: 3.5rem;
  background-image: url("/src/frontImage/footerLogo.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 1240px) {
  .footer .footerContainer .footerLogoContainer {
    flex-wrap: wrap;
  }
}
@media (max-width: 1024px) {
  .footer .footerContainer .footerLogoContainer {
    width: 8rem;
    padding: 1rem;
    margin-right: 1rem;
    background-image: url("/src/frontImage/footerlogo02.svg");
  }
}
@media (max-width: 480px) {
  .footer .footerContainer .footerLogoContainer {
    width: 80%;
    margin-bottom: 0.5rem;
    background-image: url("/src/frontImage/footerLogo.svg");
  }
}
@media (max-width: 375px) {
  .footer .footerContainer .footerLogoContainer {
    margin-right: unset;
  }
}
.footer .footerContainer .detailContainer {
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 1.5rem 0;
  word-break: break-all;
}
@media (max-width: 375px) {
  .footer .footerContainer .detailContainer {
    font-size: 0.75rem;
  }
}
.footer .footerContainer .detailContainer .container {
  display: flex;
  margin: 0.3rem 0;
}
.footer .footerContainer .detailContainer .container .mobileName {
  position: relative;
  padding-right: 0.5rem;
  display: none;
}
@media (max-width: 640px) {
  .footer .footerContainer .detailContainer .container .mobileName {
    display: block;
  }
}
.footer .footerContainer .detailContainer .container .mobileName::after {
  content: ":";
  position: absolute;
  right: 0;
  top: 0;
}
.footer .footerContainer .detailContainer .container .name {
  position: relative;
  padding-right: 0.5rem;
}
@media (max-width: 1024px) {
  .footer .footerContainer .detailContainer .container .name {
    display: none;
  }
}
.footer .footerContainer .detailContainer .container .name::after {
  content: ":";
  position: absolute;
  right: 0;
  top: 0;
}
.footer .footerContainer .detailContainer .container .detail {
  letter-spacing: unset;
  margin-left: 0.3rem;
}
@media (max-width: 480px) {
  .footer .footerContainer .detailContainer .container .detail {
    margin-left: unset;
  }
}
.footer .footerContainer .socialContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-end;
  gap: 1.5rem;
  font-weight: 500;
}
@media (max-width: 1240px) {
  .footer .footerContainer .socialContainer {
    flex-grow: unset;
  }
}
.footer .footerContainer .socialContainer .social {
  display: flex;
  gap: 1rem;
}
@media (max-width: 1024px) {
  .footer .footerContainer .socialContainer .social {
    display: none;
  }
}
.footer .footerContainer .socialContainer .social li {
  transition: all 0.5s linear;
}
.footer .footerContainer .socialContainer .social li .socialLink {
  position: relative;
  display: block;
  width: 2.8vw;
  height: 2.8vw;
  transition: all 1s linear;
}
.footer .footerContainer .socialContainer .social li .socialLink:hover {
  transition: all 0.5s linear;
}
.footer .footerContainer .socialContainer .social li .socialLink:hover .socialImg {
  opacity: 1;
}
.footer .footerContainer .socialContainer .social li .socialLink .socialImg {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s linear;
}
.footer .footerContainer .socialContainer .social li .socialIcon {
  position: absolute;
  z-index: 12;
  color: #0054a7;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.remarkContainer {
  font-size: 0.875rem;
  font-weight: 700;
}
@media (max-width: 1240px) {
  .remarkContainer div {
    align-self: center;
    margin-bottom: 1rem;
  }
}
@media (max-width: 1024px) {
  .remarkContainer div {
    align-self: flex-start;
  }
}
@media (max-width: 640px) {
  .remarkContainer div {
    align-self: center;
  }
}
.remarkContainer .copyRight {
  font-weight: 700;
  text-align: right;
  line-height: 200%;
  font-size: 0.875rem;
}
@media (max-width: 480px) {
  .remarkContainer .copyRight {
    text-align: center;
  }
}

.policyLink {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  display: block;
}
.policyLink:hover::after {
  content: "";
  position: absolute;
  width: 100%;
  background-color: #ffffff;
  height: 1px;
}

.asideNav {
  position: fixed;
  z-index: 1;
  height: 100%;
  padding: 0 1.5vw 0vw 6.1vw;
  z-index: 10;
  background-color: #ffffff;
  top: 0;
  bottom: 0;
}
@media (max-width: 1024px) {
  .asideNav {
    display: none;
  }
}
.asideNav .asideNavTitleContainer:hover .asideNavTitle .navEnLabel {
  transition: all 0.3s linear;
  color: #ffd701;
}
.asideNav .asideNavTitleContainer:hover .asideNavTitle .navZhLabel {
  transition: all 0.3s linear;
  color: #0054a7;
}
.asideNav .asideNavTitleContainer .asideNavTitle {
  font-size: 2.25rem;
  color: #0054a7;
  margin-top: 9rem;
  letter-spacing: 2px;
  display: block;
}
@media (max-width: 1024px) {
  .asideNav .asideNavTitleContainer .asideNavTitle {
    font-size: 2.25rem;
  }
}
@media (max-width: 1240px) {
  .asideNav .asideNavTitleContainer .asideNavTitle {
    margin-top: 8rem;
  }
}
.asideNav .asideNavTitleContainer .asideNavTitle .navEnLabel {
  font-family: "Inter", sans-serif;
  font-weight: 300;
}
.asideNav .asideNavTitleContainer .asideNavTitle .navZhLabel {
  color: #000000;
  margin-bottom: 2rem;
  display: block;
  letter-spacing: 2px;
  font-size: 1.25rem;
  font-weight: 700;
}
.asideNav .activeTitle .navEnLabel {
  color: #ffd701;
}
.asideNav .activeTitle .navZhLabel {
  color: #0054a7;
}
.asideNav .asideNavLabelContainer {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  position: relative;
}
.asideNav .asideNavLabelContainer .asideNavLabel.active .asideNavLabelArrow {
  opacity: 1;
}
.asideNav .asideNavLabelContainer .asideNavLabel {
  font-size: 1.25rem;
  letter-spacing: 1px;
  font-weight: 700;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 3rem;
  cursor: pointer;
}
@media (max-width: 1240px) {
  .asideNav .asideNavLabelContainer .asideNavLabel {
    font-size: 1rem;
  }
}
.asideNav .asideNavLabelContainer .asideNavLabel .asideNavLabelArrow {
  position: absolute;
  background-image: url("/src/frontImage/homeLink.png");
  width: 1.9vw;
  height: 1.9vw;
  background-size: contain;
  background-position: center;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  transform: rotate(0deg);
  opacity: 0;
}
.asideNav .asideNavLabelContainer .asideNavLabel::before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #0054a7;
  position: absolute;
  left: 0rem;
  top: 50%;
  transform: translateY(-50%);
}
.asideNav .asideNavLabelContainer .asideNavLabel::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/homeLink.png");
  width: 1.9vw;
  height: 1.9vw;
  background-size: contain;
  background-position: center;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  transform: rotate(0deg);
  opacity: 0;
}
.asideNav .asideNavLabelContainer .asideNavLabel:hover::after {
  opacity: 1;
  transition: all 0.3s linear;
}

.asideContactContainer {
  width: 11.5rem;
  margin-top: 5rem;
}
.asideContactContainer .asideContact {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border: 2px solid #0054a7;
  padding: 0.4rem 0rem;
  border-radius: 15px;
  width: 90%;
  cursor: pointer;
  background: #ffffff;
  transition: all 0.3s linear;
}
.asideContactContainer .asideContact:hover {
  background: rgb(45, 67, 144);
  background: linear-gradient(90deg, rgb(45, 67, 144) 0%, rgb(7, 107, 175) 100%);
}
.asideContactContainer .asideContact:hover .asideContactArrow {
  transition: all 0.3s linear;
  background-image: url("/src/frontImage/vectorWhite.svg");
}
.asideContactContainer .asideContact:hover a {
  transition: all 0.3s linear;
  color: #ffffff;
}
.asideContactContainer .asideContact:hover .asideContactImgContainer {
  transition: all 0.3s linear;
  background-image: url("/src/frontImage/asideContact03.svg");
}
.asideContactContainer .asideContact .asideContactImgContainer {
  width: 2.5rem;
  height: 2.5rem;
  background-image: url("/src/frontImage/asideContact01.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.asideContactContainer .asideContact a {
  font-size: 1rem;
  font-weight: 700;
  color: #0054a7;
  letter-spacing: 1px;
}
.asideContactContainer .asideContact .asideContactArrow {
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("/src/frontImage/asideContact02.svg");
  background-repeat: no-repeat;
  background-size: contain;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  justify-content: center;
  margin-top: 3rem;
}
.pagination-container .pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.pagination-container .pagination-item.dots:hover {
  background-color: transparent;
  cursor: default;
}
.pagination-container .pagination-item:hover {
  background-color: rgba(0, 0, 0, 0.04);
  cursor: pointer;
}
.pagination-container .pagination-item.selected {
  background-color: #0054a7;
  color: #ffffff;
}
.pagination-container .pagination-item .arrow::before {
  position: relative;
  /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
  content: "";
  /* By using an em scale, the arrows will size with the font */
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}
.pagination-container .pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.pagination-container .pagination-item .arrow.right {
  transform: rotate(45deg);
}
.pagination-container .pagination-item.disabled {
  pointer-events: none;
}
.pagination-container .pagination-item.disabled .arrow::before {
  border-right: 0.12em solid rgba(0, 0, 0, 0.43);
  border-top: 0.12em solid rgba(0, 0, 0, 0.43);
}
.pagination-container .pagination-item.disabled:hover {
  background-color: transparent;
  cursor: default;
}

.main {
  padding: 10.875vw 5.1vw 2vw 6.1vw;
  position: relative;
  margin-bottom: 6rem;
}
@media (max-width: 1024px) {
  .main {
    padding: 8rem 5.1vw 2vw 6.1vw;
  }
}
@media (max-width: 640px) {
  .main {
    padding: 8rem 0 1rem 0;
  }
}
.main .carouselContainer {
  left: 25.5%;
  width: 66vw;
  height: 32vw;
  position: relative;
}
@media (max-width: 1024px) {
  .main .carouselContainer {
    width: 100%;
    height: 23.75rem;
    left: 0;
  }
}
@media (max-width: 640px) {
  .main .carouselContainer {
    height: 16rem;
  }
}

.bannerTitleContainer {
  width: 33vw;
  height: 14vw;
  z-index: 10;
  top: 16.6vw;
  opacity: 1;
}
@media (max-width: 1024px) {
  .bannerTitleContainer {
    display: none;
  }
}
@keyframes fadeout {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  50% {
    transform: translateY(10px);
    opacity: 0.3;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
.bannerTitleContainer img {
  animation: fadeout 5s linear;
}

.caorouselLink {
  position: absolute;
  top: 18vw;
  opacity: 1;
  animation: fadeout 5s linear;
}

@media (max-width: 480px) {
  .carouselContainer {
    height: auto;
  }
}
.carouselContainer .homeCarouselSwiper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 100%;
  height: 100%;
}
@media (max-width: 480px) {
  .carouselContainer .homeCarouselSwiper {
    height: auto;
  }
}
.carouselContainer .homeCarouselSwiper div {
  width: 100%;
  height: 100%;
}
.carouselContainer .homeCarouselSwiper img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.carouselContainer .homeCarouselSwiper .slick-initialized .slick-slide {
  position: relative;
}
.carouselContainer .homeCarouselSwiper .slick-dots {
  bottom: -40px !important;
  left: 0 !important;
  display: flex !important;
  gap: 0.2rem;
}
@media (max-width: 1024px) {
  .carouselContainer .homeCarouselSwiper .slick-dots {
    justify-content: center;
  }
}
.carouselContainer .homeCarouselSwiper .slick-dots li {
  margin: 0px !important;
}
.carouselContainer .homeCarouselSwiper .slick-dots li button {
  width: 0.72vw !important;
  height: 0.72vw !important;
  border-radius: 50%;
  border: 1px solid #0054a7 !important;
  position: relative;
}
.carouselContainer .homeCarouselSwiper .slick-dots li button:hover::before {
  transition: all 0.3s linear;
  background-color: #0054a7;
}
.carouselContainer .homeCarouselSwiper .slick-dots li button::before {
  content: "";
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  font-size: 0 !important;
}
.carouselContainer .homeCarouselSwiper .slick-dots .slick-active button {
  background-color: #0054a7 !important;
}

.counterContainer {
  margin-top: 3.875rem;
  padding: 0 3rem;
  font-family: "Inter", sans-serif;
}
@media (max-width: 1240px) {
  .counterContainer {
    margin-top: 6.5rem;
  }
}
@media (max-width: 640px) {
  .counterContainer {
    margin-top: 4rem;
  }
}
@media (max-width: 480px) {
  .counterContainer {
    padding: 0 1rem;
  }
}
.counterContainer .counterDiv {
  display: flex;
  justify-content: center;
  gap: 5vw;
}
@media (max-width: 1024px) {
  .counterContainer .counterDiv {
    align-items: center;
    flex-direction: column;
  }
}

.counter {
  display: flex;
}
@media (max-width: 1024px) {
  .counter {
    width: 60%;
  }
}
@media (max-width: 640px) {
  .counter {
    width: 90%;
  }
}
@media (max-width: 375px) {
  .counter {
    width: 100%;
  }
}

.counterDetail {
  font-weight: 900;
  color: #ffffff;
  text-align: left;
  padding: 1vw;
}
.counterDetail .counterTitle {
  font-size: 1.5rem;
  letter-spacing: 2px;
  padding-bottom: 0.5rem;
}
@media (max-width: 1024px) {
  .counterDetail .counterTitle {
    padding-bottom: 0.2rem;
  }
}
@media (max-width: 480px) {
  .counterDetail .counterTitle {
    font-size: 1.25rem;
  }
}
.counterDetail .counterNum {
  font-size: 1.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  word-break: break-all;
}
.counterDetail span:nth-child(2) {
  transform: translateY(2px);
}

.counterBox {
  background: radial-gradient(82.47% 5023.99% at 10.42% 56.3%, #2d4390 0%, #076baf 100%);
  border-radius: 18px;
  display: flex;
  align-items: center;
  gap: 0.8vw;
  padding: 1.87vw;
  font-size: 1.25rem;
}
@media (max-width: 1024px) {
  .counterBox {
    justify-content: center;
    width: 100%;
    gap: 3rem;
    padding: 1.5rem;
  }
}
@media (max-width: 640px) {
  .counterBox {
    gap: 1rem;
  }
}
.counterBox .counterBoxImg {
  padding: 0.1vw;
  width: 4.25rem;
  height: 4.25rem;
}
@media (max-width: 1024px) {
  .counterBox .counterBoxImg {
    width: 3.1rem;
    height: 4.25rem;
  }
}
.counterBox .counterBoxImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.HomeAbout {
  margin: 10rem auto;
}
@media (max-width: 640px) {
  .HomeAbout {
    margin: unset;
  }
}
.HomeAbout .HomeAboutontainer {
  margin: 8rem 0;
  padding-left: 11vw;
  display: flex;
  justify-content: space-between;
  gap: 4vw;
}
@media (max-width: 1240px) {
  .HomeAbout .HomeAboutontainer {
    flex-direction: column-reverse;
    padding-left: 0;
    justify-content: center;
    gap: 5.3rem;
  }
}
@media (max-width: 640px) {
  .HomeAbout .HomeAboutontainer {
    margin: 5rem 0 1rem 0;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeAboutontainer {
    position: relative;
  }
  .HomeAbout .HomeAboutontainer::after {
    content: "";
    position: absolute;
    background-image: url("/src/frontImage/側邊裝飾.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 23px;
    height: 200px;
  }
}
@media (max-width: 1024px) and (max-width: 640px) {
  .HomeAbout .HomeAboutontainer::after {
    content: unset;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeAboutontainer::after {
    right: 1rem;
    bottom: 20%;
  }
}
.HomeAbout .HomeAboutontainer .HomeAboutSec {
  display: flex;
  flex-direction: column;
  width: 36%;
  padding: 0px 1vw 0 0;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec {
    width: 55%;
    padding: unset;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec {
    width: 60%;
  }
}
@media (max-width: 640px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec {
    width: 80%;
  }
}
.HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutTitleCon {
  display: flex;
  font-size: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 3rem;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutTitleCon {
    margin-bottom: 2.5rem;
  }
}
.HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutIcon {
  width: 4rem;
}
@media (max-width: 640px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutIcon {
    width: 3rem;
  }
}
.HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutIcon img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutTitle {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 2px;
}
@media (max-width: 480px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutTitle {
    font-size: 1.25rem;
  }
}
.HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutContent {
  color: #000000;
  font-size: 1.125rem;
  text-align: left;
  letter-spacing: 2px;
  margin-bottom: 5rem;
  width: 80%;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutContent {
    margin-bottom: 3rem;
    width: 100%;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutContent {
    margin-bottom: 3rem;
  }
}
@media (max-width: 480px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutContent {
    font-size: 1rem;
    width: 100%;
  }
}
.HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutContentImg {
  opacity: 1;
  animation: opacity 2s linear;
  position: relative;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutContentImg {
    width: unset;
    margin-bottom: 3rem;
  }
}
@media (max-width: 640px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutContentImg {
    width: 70%;
  }
}
@media (max-width: 480px) {
  .HomeAbout .HomeAboutontainer .HomeAboutSec .HomeAboutContentImg {
    width: 100%;
  }
}
.HomeAbout .HomeAboutontainer .HomeAboutImgContainer {
  position: relative;
  width: 50%;
  height: 50vw;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeAboutontainer .HomeAboutImgContainer {
    width: 100%;
    bottom: 0;
    background-size: contain;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeAboutontainer .HomeAboutImgContainer {
    height: 70vw;
  }
}
.HomeAbout .HomeAboutontainer .HomeAboutImgContainer .HomeAboutImg {
  position: absolute;
  width: 100%;
  height: 95%;
  background-image: url("/src/frontImage/homeaboutbc02.png");
  background-size: 170% auto;
  background-repeat: no-repeat;
  z-index: 10;
  bottom: 5rem;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeAboutontainer .HomeAboutImgContainer .HomeAboutImg {
    width: 95%;
    height: 95%;
    right: 0;
    background-size: cover;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeAboutontainer .HomeAboutImgContainer .HomeAboutImg {
    width: 80%;
    height: 100%;
    background-position-x: 5rem;
    background-size: cover;
    bottom: 0;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeAboutontainer .HomeAboutImgContainer .HomeAboutImg {
    height: 100%;
  }
}
.HomeAbout .HomeAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
  position: absolute;
  width: 110%;
  height: 105%;
  right: 0;
  z-index: 5;
  background-image: url("/src/frontImage/homeaboutbc01.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
    background-position: right bottom;
    background-size: 90% 100%;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
    bottom: -5%;
    background-position: left bottom;
    background-size: 100% 100%;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
    width: 100%;
    height: 100%;
    bottom: 0;
  }
}
.HomeAbout .HomeSecAboutontainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 5rem;
  gap: 3rem;
}
@media (max-width: 1024px) {
  .HomeAbout .HomeSecAboutontainer {
    position: relative;
  }
  .HomeAbout .HomeSecAboutontainer::after {
    content: "";
    position: absolute;
    background-image: url("/src/frontImage/側邊裝飾.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 23px;
    height: 200px;
  }
}
@media (max-width: 1024px) and (max-width: 640px) {
  .HomeAbout .HomeSecAboutontainer::after {
    content: unset;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeSecAboutontainer::after {
    right: 1rem;
    top: 30%;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeSecAboutontainer {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 640px) {
  .HomeAbout .HomeSecAboutontainer {
    margin-top: unset;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec {
  display: flex;
  flex-direction: column;
  width: 35%;
  padding: 0 3rem;
  margin: 0 7rem 0 0;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec {
    width: 50%;
    padding: 0;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec {
    margin: 5rem 0 0 0;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec {
    width: 58%;
    margin: 8rem auto;
  }
}
@media (max-width: 640px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec {
    width: 80%;
    margin: 2rem auto 0 auto;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutTitleCon {
  display: flex;
  font-size: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 3rem;
}
@media (max-width: 1240px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutTitleCon {
    margin-bottom: 2.5rem;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutTitleCon {
    margin: 0 0 2.5rem 0;
    gap: 0.5rem;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutIcon {
  width: 4rem;
}
@media (max-width: 640px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutIcon {
    width: 3rem;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutIcon img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutTitle {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 2px;
}
@media (max-width: 480px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutTitle {
    font-size: 1.25rem;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutContent {
  color: #000000;
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 2px;
  text-align: left;
  margin-bottom: 6rem;
  width: 95%;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutContent {
    margin-bottom: 3rem;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutContent {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 480px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutContent {
    font-size: 1rem;
    width: 100%;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 2.5rem;
  row-gap: 1.5rem;
  margin: auto;
  justify-content: space-around;
}
@media (max-width: 1024px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec {
    width: 55%;
  }
}
@media (max-width: 480px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec {
    grid-template-columns: auto auto;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec .selecItem {
  background: radial-gradient(82.47% 5023.99% at 10.42% 56.3%, #2d4390 0%, #076baf 100%);
  border-radius: 10px;
  width: 8rem;
  height: 3rem;
  padding: 0.8rem;
  transition: all 0.2s linear;
}
@media (max-width: 375px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec .selecItem {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 0.875rem;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec .selecItem span {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 2px;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  transition: all 0.2s linear;
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec .selecItem span:hover {
  color: #000000;
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec .selecItem:hover {
  background: #ffd701;
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec .selecItem:hover span {
  color: #000000;
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec .focusSelecItem {
  background: #ffd701;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutselec .focusSelecItem span {
  color: #000000;
}
.HomeAbout .HomeSecAboutontainer .HomeAboutSec .HomeAboutContentImg {
  margin-top: 2rem;
  position: relative;
  opacity: 1;
  animation: opacity 2s linear;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer {
  position: relative;
  width: 51%;
  height: 58vw;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer {
    width: 90%;
    bottom: 0;
    background-size: contain;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer {
    width: 100%;
    height: 70vw;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer .HomeAboutImg {
  position: absolute;
  width: 85%;
  height: 85%;
  background-image: url("/src/frontImage/homeabout06.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 10;
  right: 3rem;
  bottom: 10rem;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer .HomeAboutImg {
    height: 95%;
    width: 95%;
    bottom: 5rem;
    right: 0;
    background-size: contain;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer .HomeAboutImg {
    height: 100%;
    width: 65%;
    top: 50%;
    transform: translateY(-50%);
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer .HomeAboutImg {
    height: 100%;
    width: 75%;
  }
}
.HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
  position: absolute;
  width: 110%;
  height: 105%;
  right: 0;
  bottom: 0;
  z-index: 5;
  background-image: url("/src/frontImage/homeaboutbc03.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media (max-width: 1440px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
    height: 95%;
    width: 95%;
    left: 0;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
    bottom: -5%;
    width: 100%;
    height: 100%;
    background-size: 125% 100%;
    background-position: left bottom;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomeSecAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
    width: 100%;
    height: 100%;
    background-size: 105% 100%;
    background-position: left bottom;
  }
}
.HomeAbout .HomethirdAboutontainer {
  padding-left: 5rem;
  display: flex;
  justify-content: space-between;
  gap: 5.3rem;
  margin-top: 5rem;
  position: relative;
}
.HomeAbout .HomethirdAboutontainer::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/側邊裝飾.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 23px;
  height: 200px;
}
@media (max-width: 640px) {
  .HomeAbout .HomethirdAboutontainer::after {
    content: unset;
  }
}
.HomeAbout .HomethirdAboutontainer::after {
  right: 1rem;
  bottom: 10%;
}
@media (max-width: 1240px) {
  .HomeAbout .HomethirdAboutontainer {
    flex-direction: column-reverse;
    justify-content: center;
    padding-left: unset;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomethirdAboutontainer {
    padding-left: 0;
  }
}
@media (max-width: 640px) {
  .HomeAbout .HomethirdAboutontainer {
    gap: 2rem;
  }
}
.HomeAbout .HomethirdAboutontainer .HomeAboutSec {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 0 5rem;
}
@media (max-width: 1440px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec {
    width: 55%;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec {
    margin: auto;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec {
    width: 60%;
  }
}
@media (max-width: 640px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec {
    width: 80%;
    margin: 2rem auto 0 auto;
  }
}
.HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutTitleCon {
  display: flex;
  font-size: 1.25rem;
  gap: 1.25rem;
  margin-bottom: 3rem;
}
@media (max-width: 1440px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutTitleCon {
    margin-bottom: 2.5rem;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutTitleCon {
    margin-bottom: 2.5rem;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutTitleCon {
    margin: 0 0 2.5rem 0;
    gap: 0.5rem;
  }
}
.HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutIcon {
  width: 4rem;
}
@media (max-width: 640px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutIcon {
    width: 3rem;
  }
}
.HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutIcon img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutTitle {
  color: #000000;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 2px;
}
@media (max-width: 480px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutTitleCon .HomeAboutTitle {
    font-size: 1.25rem;
  }
}
.HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutContent {
  color: #000000;
  font-size: 1.125rem;
  letter-spacing: 2px;
  text-align: left;
  margin-bottom: 3rem;
  width: 90%;
}
@media (max-width: 480px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutContent {
    font-size: 1rem;
    width: 100%;
  }
}
.HomeAbout .HomethirdAboutontainer .HomeAboutSec .contentContainer {
  margin-bottom: 3rem;
}
.HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutContentImg {
  width: 70%;
  margin: 2rem auto;
  opacity: 1;
  animation: opacity 2s linear;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutContentImg {
    width: unset;
    margin: unset;
  }
}
@media (max-width: 640px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutContentImg {
    margin: unset;
    width: 70%;
  }
}
@media (max-width: 480px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutSec .HomeAboutContentImg {
    width: 100%;
  }
}
.HomeAbout .HomethirdAboutontainer .HomeAboutImgContainer {
  position: relative;
  width: 50%;
  height: 45vw;
}
@media (max-width: 1440px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutImgContainer {
    width: 100%;
    bottom: 0;
    background-size: contain;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutImgContainer {
    height: 70vw;
  }
}
.HomeAbout .HomethirdAboutontainer .HomeAboutImgContainer .HomeAboutImg {
  position: absolute;
  width: 85%;
  height: 85%;
  background-image: url("/src/frontImage/homeaboutbc04.png");
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 10;
  right: 3rem;
  bottom: 3rem;
}
@media (max-width: 1440px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutImgContainer .HomeAboutImg {
    height: 95%;
    width: 95%;
    bottom: 0;
    right: 0;
    background-size: contain;
  }
}
@media (max-width: 1240px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutImgContainer .HomeAboutImg {
    height: 100%;
    width: 85%;
  }
}
.HomeAbout .HomethirdAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
  position: absolute;
  width: 110%;
  height: 105%;
  right: 0;
  bottom: -5%;
  z-index: 5;
  background-image: url("/src/frontImage/homeaboutbc01.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: right bottom;
}
@media (max-width: 1240px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
    bottom: 0;
    background-size: 120% 100%;
    background-position: right bottom;
  }
}
@media (max-width: 1024px) {
  .HomeAbout .HomethirdAboutontainer .HomeAboutImgContainer .HomeAboutImgBc {
    bottom: 0;
    background-size: 105% 100%;
    background-position: left bottom;
  }
}

.homeAboutLinkCon {
  opacity: 1;
  animation: opacity 2s linear;
  margin-top: 4rem;
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 1440px) {
  .homeAboutLinkCon {
    margin-top: 2rem;
  }
}
@media (max-width: 1024px) {
  .homeAboutLinkCon {
    margin: 4rem auto;
  }
}

.slider {
  height: 100%;
  width: 1px;
  background: #ffffff;
  position: relative;
}
.slider::after {
  content: "";
  position: absolute;
  width: 1.9vw;
  height: 1.9vw;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff;
  border-radius: 50%;
  background-image: url("/src/frontImage/homeabouticonspec.svg");
  top: 50%;
  transform: translateY(-50%);
  left: -0.78vw;
  cursor: pointer;
}

.homeNewsSwiper {
  padding-right: 30px !important;
}
.homeNewsSwiper > .swiper-pagination {
  bottom: 0px !important;
  width: 30px !important;
  right: 0 !important;
  left: unset !important;
}

.homeNews {
  padding: 2rem 0 7.8vw 0;
  position: relative;
}
@media (max-width: 1024px) {
  .homeNews::after {
    content: "";
    position: absolute;
    background-color: #f5f5f5;
    width: 100%;
    height: 20%;
    top: 20%;
    left: 0;
    z-index: -1;
  }
}
@media (max-width: 640px) {
  .homeNews::after {
    content: unset;
  }
}
@media (max-width: 1240px) {
  .homeNews {
    padding: 0 0 7.8vw 0;
  }
}
@media (max-width: 640px) {
  .homeNews {
    padding: unset;
  }
}
@media (max-width: 480px) {
  .homeNews {
    margin-bottom: 5rem;
  }
}
.homeNews .homeNewsContainer {
  padding: 0 6.9vw 0 7.6vw;
  display: flex;
  align-items: center;
  position: relative;
}
@media (max-width: 1240px) {
  .homeNews .homeNewsContainer {
    width: 90%;
    margin: auto;
    padding: unset;
  }
}
@media (max-width: 1024px) {
  .homeNews .homeNewsContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2.5rem;
  }
}
@media (max-width: 640px) {
  .homeNews .homeNewsContainer {
    width: 100%;
    flex-direction: column-reverse;
  }
  .homeNews .homeNewsContainer::after {
    content: "";
    background-color: #f5f5f5;
    position: absolute;
    width: 100%;
    bottom: -3rem;
    height: 10rem;
    left: 0;
    z-index: -1;
  }
}
@media (max-width: 480px) {
  .homeNews .homeNewsContainer {
    padding: 0 1rem;
  }
}
.homeNews .homeNewsContainer .carouselContainer {
  width: 40%;
  cursor: pointer;
  position: relative;
}
@media (max-width: 1024px) {
  .homeNews .homeNewsContainer .carouselContainer {
    width: 80%;
  }
}
.homeNews .homeNewsContainer .carouselContainer img {
  width: 36vw;
  height: 30vw;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1024px) {
  .homeNews .homeNewsContainer .carouselContainer img {
    width: 100%;
    height: 25rem;
  }
}
@media (max-width: 640px) {
  .homeNews .homeNewsContainer .carouselContainer img {
    width: 100%;
    height: 20rem;
  }
}
@media (max-width: 640px) {
  .homeNews .homeNewsContainer .carouselContainer {
    width: 85%;
    max-width: 100%;
    margin-top: 3rem;
  }
}
.homeNews .homeNewsContainer .carouselContainer .homeNewImgTitle {
  position: absolute;
  z-index: 10;
  color: #ffffff;
  height: calc(23% - 5px);
  width: calc(100% - 5px);
  width: 100%;
  background-color: #0054a7;
  opacity: 0.8;
  bottom: 5px;
  overflow: hidden;
  transition: all 0.3s linear;
  margin: auto;
}
.homeNews .homeNewsContainer .carouselContainer .homeNewImgTitle span {
  position: absolute;
  text-align: left;
  width: 95%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  letter-spacing: 2px;
  font-weight: 700;
  font-size: 1.5rem;
}
@media (max-width: 1536px) {
  .homeNews .homeNewsContainer .carouselContainer .homeNewImgTitle span {
    font-size: 1.25rem;
  }
}
@media (max-width: 480px) {
  .homeNews .homeNewsContainer .carouselContainer .homeNewImgTitle span {
    font-size: 0.875rem;
  }
}
.homeNews .homeNewsContainer .carouselContainer .homeNewImgTitle::after {
  content: "";
  content: "";
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  transition: all 0.5s;
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  transform: scale(1, 0);
}
.homeNews .homeNewsContainer .carouselContainer .homeNewImgTitle::before {
  content: "";
  content: "";
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  transition: all 0.5s;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  transform: scale(0, 1);
}

.homeNewsDetail {
  margin-left: 5.5rem;
  width: 100%;
  position: relative;
}
@media (max-width: 1240px) {
  .homeNewsDetail {
    margin-left: 3.5rem;
  }
}
@media (max-width: 1024px) {
  .homeNewsDetail {
    margin-top: 4.875rem;
    padding-left: unset;
    margin-left: unset;
  }
}
.homeNewsDetail .homeNewsTitle {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2.5rem;
  position: relative;
}
.homeNewsDetail .homeNewsTitle::after {
  content: "";
  position: absolute;
  bottom: 0;
  background-color: #0054a7;
  height: 0.2vw;
  width: 100%;
}
@media (max-width: 480px) {
  .homeNewsDetail .homeNewsTitle::after {
    content: unset;
  }
}
.homeNewsDetail .homeNewsTitle div {
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
@media (max-width: 1024px) {
  .homeNewsDetail .homeNewsTitle div {
    justify-content: unset;
  }
}
@media (max-width: 640px) {
  .homeNewsDetail .homeNewsTitle div {
    justify-content: center;
    width: 100%;
  }
}
.homeNewsDetail .homeNewsTitle div .homeNewsTitleIcon {
  width: 7.9vw;
  height: 1.45vw;
}
@media (max-width: 1024px) {
  .homeNewsDetail .homeNewsTitle div .homeNewsTitleIcon {
    width: 7.75em;
    height: 1.6rem;
  }
}
.homeNewsDetail .homeNewsTitle div h3 {
  font-weight: 700;
  font-size: 1.25rem;
}
.homeNewsDetail .homeNewsTitle .homeNewsLinkCon {
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
}
.homeNewsDetail .homeNewsTitle .homeNewsLinkCon:hover::after {
  opacity: 1;
}
.homeNewsDetail .homeNewsTitle .homeNewsLinkCon::after {
  content: "";
  position: absolute;
  width: 70%;
  background-color: #ffd701;
  height: 2px;
  bottom: -5px;
  left: 0;
  transition: all 0.2s linear;
  opacity: 0;
}
@media (max-width: 640px) {
  .homeNewsDetail .homeNewsTitle .homeNewsLinkCon {
    left: 50%;
    transform: translateX(-50%);
  }
}
.homeNewsDetail .homeNewsTitle .homeNewsLinkCon .homeNewsLink {
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 2px;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 3.5rem;
}
@media (max-width: 640px) {
  .homeNewsDetail .homeNewsTitle .homeNewsLinkCon .homeNewsLink {
    display: none;
  }
}
.homeNewsDetail .homeNewsTitle .homeNewsLinkCon .homeNewsLink:hover::after {
  background-color: #076baf;
}
.homeNewsDetail .homeNewsTitle .homeNewsLinkCon .homeNewsLink:hover::before {
  background-image: url("/src/frontImage/vectorWhite.svg");
}
.homeNewsDetail .homeNewsTitle .homeNewsLinkCon .homeNewsLink::after {
  content: "";
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 2px solid #0054a7;
  transition: all 0.2s linear;
}
.homeNewsDetail .homeNewsTitle .homeNewsLinkCon .homeNewsLink::before {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  right: 0;
  z-index: 10;
  top: 50%;
  transform: translate(-50%, -50%);
  background-image: url("/src/frontImage/vector.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  transition: all 0.3s linear;
}
.homeNewsDetail .homeNewCardContainer {
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 3rem;
}
@media (max-width: 640px) {
  .homeNewsDetail .homeNewCardContainer {
    display: none;
  }
}
@media (max-width: 1024px) {
  .homeNewsDetail .homeNewCardContainer {
    margin-top: 2rem;
  }
}
@media (max-width: 480px) {
  .homeNewsDetail .homeNewCardContainer {
    display: none;
  }
}
.homeNewsDetail .homeNewCardContainer .homeNewsLinkCard {
  background-color: #0054a7;
  text-align: center;
  padding: 0.5rem 0;
  border-radius: 7px;
  color: #ffffff;
  border: 1px solid #0054a7;
}
.homeNewsDetail .homeNewCardContainer .homeNewsLinkCard:hover {
  background-color: #ffffff;
  color: #0054a7;
  transition: all 0.3s linear;
}
.homeNewsDetail .homeNewCardContainer .homeNewCard {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  transition: all 0.5s linear;
  width: 50%;
  margin: auto;
}
.homeNewsDetail .homeNewCardContainer .homeNewCard .homeNewTitle {
  font-size: 1.25rem;
  letter-spacing: 2px;
  font-weight: 700;
  margin-bottom: 1rem;
  height: 4rem;
}
.homeNewsDetail .homeNewCardContainer .homeNewCard:hover .homeNewImg img {
  transition: all 1s linear;
  transform: scale(1.1);
}
.homeNewsDetail .homeNewCardContainer .homeNewCard .homeNewContent {
  font-weight: 500;
  font-size: 1.125rem;
  letter-spacing: 1px;
  margin-top: 1rem;
  word-break: break-all;
  height: 5rem;
}
@media (max-width: 1440px) {
  .homeNewsDetail .homeNewCardContainer .homeNewCard .homeNewContent {
    font-size: 1rem;
  }
}
.homeNewsDetail .homeNewCardContainer .homeNewCard .homeNewImg {
  margin: auto;
  width: 20.5vw;
  height: 14vw;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
}
.homeNewsDetail .homeNewCardContainer .homeNewCard .homeNewImg img {
  border-radius: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1024px) {
  .homeNewsDetail .homeNewCardContainer .homeNewCard .homeNewImg {
    width: 100%;
    height: 15rem;
  }
}

.homeNews .homeNewsContainer .slick-initialized .slick-slide {
  position: relative;
}
.homeNews .homeNewsContainer .slick-list:hover .homeNewImgTitle {
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}
.homeNews .homeNewsContainer .slick-list:hover .homeNewImgTitle span {
  display: block;
  text-align: center;
  width: 80%;
}
.homeNews .homeNewsContainer .slick-list:hover .homeNewImgTitle::after {
  transform: scale(1, 1);
}
.homeNews .homeNewsContainer .slick-list:hover .homeNewImgTitle::before {
  transform: scale(1, 1);
}
.homeNews .homeNewsContainer .slick-dots {
  bottom: 0px !important;
  width: 0.5rem !important;
  right: -1.5rem !important;
  left: unset !important;
  display: grid !important;
  gap: 0.5vw;
}
@media (max-width: 1024px) {
  .homeNews .homeNewsContainer .slick-dots {
    bottom: unset !important;
    width: unset !important;
    right: unset !important;
    left: 50% !important;
    display: block !important;
    transform: translateX(-50%);
  }
}
.homeNews .homeNewsContainer .slick-dots li {
  margin: 0px !important;
}
.homeNews .homeNewsContainer .slick-dots li button {
  width: 0.72vw !important;
  height: 0.72vw !important;
  border-radius: 50%;
  border: 1px solid #0054a7 !important;
  position: relative;
}
.homeNews .homeNewsContainer .slick-dots li button:hover::before {
  background-color: #0054a7;
}
.homeNews .homeNewsContainer .slick-dots li button::before {
  content: "";
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  font-size: 0 !important;
}
.homeNews .homeNewsContainer .slick-dots .slick-active button {
  background-color: #0054a7 !important;
}
.homeNews .homeNewsContainer .slick-dots .slick-active button:hover::before {
  background-color: #0054a7;
}
.homeNews .homeNewsContainer .slick-dots .slick-active button:before {
  font-size: 20px;
  left: 0;
  right: 0;
  border-radius: 50%;
  opacity: 1 !important;
  color: #0054a7 !important;
}

.homeNewsLinkRwd {
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 2px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 3.5rem;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 5rem;
  display: none;
}
@media (max-width: 640px) {
  .homeNewsLinkRwd {
    display: block;
    margin: 6rem 0 10rem 0;
  }
}
.homeNewsLinkRwd::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/homeLink.svg");
  width: 2.3rem;
  height: 2.3rem;
  background-size: contain;
  background-position: center;
  right: 0%;
  background-repeat: no-repeat;
  top: 50%;
  transform: translateY(-50%);
}

.aboutMainVisual {
  display: flex;
  flex-direction: column;
  margin-bottom: 6rem;
  position: relative;
}
.aboutMainVisual .aboutMainVisualContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 9vw;
  margin-bottom: 5.8vw;
}
@media (max-width: 1440px) {
  .aboutMainVisual .aboutMainVisualContainer {
    gap: 6vw;
  }
}
@media (max-width: 1240px) {
  .aboutMainVisual .aboutMainVisualContainer {
    width: 70%;
    margin: auto;
    flex-direction: column-reverse;
    margin-bottom: 10rem;
  }
}
@media (max-width: 640px) {
  .aboutMainVisual .aboutMainVisualContainer {
    width: 100%;
    gap: 5rem;
  }
}
.aboutMainVisual .aboutMainVisualContainer .aboutMainVisualContent {
  display: flex;
  flex-direction: column;
  gap: 4.47vw;
  width: 32%;
}
@media (max-width: 1240px) {
  .aboutMainVisual .aboutMainVisualContainer .aboutMainVisualContent {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .aboutMainVisual .aboutMainVisualContainer .aboutMainVisualContent {
    width: 80%;
    gap: unset;
  }
}
@media (max-width: 640px) {
  .aboutMainVisual .aboutMainVisualContainer .aboutMainVisualContent {
    width: 95%;
  }
}
.aboutMainVisual .aboutMainVisualContainer .aboutMainVisualContent .aboutMainVisualTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: #0054a7;
  letter-spacing: 2px;
}
@media (max-width: 1240px) {
  .aboutMainVisual .aboutMainVisualContainer .aboutMainVisualContent .aboutMainVisualTitle {
    text-align: center;
    margin-bottom: 3.125rem;
  }
}
.aboutMainVisual .aboutMainVisualContainer .aboutMainVisualContent p {
  font-size: 1.125rem;
  font-weight: 500;
  color: #000000;
  letter-spacing: 1px;
}
@media (max-width: 1440px) {
  .aboutMainVisual {
    margin-bottom: 5rem;
  }
}
@media (max-width: 640px) {
  .aboutMainVisual {
    margin-bottom: unset;
  }
}
.aboutMainVisual .aboutMainVisualContainer::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/側邊裝飾.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 23px;
  height: 200px;
}
@media (max-width: 640px) {
  .aboutMainVisual .aboutMainVisualContainer::after {
    content: unset;
  }
}
.aboutMainVisual .aboutMainVisualContainer::after {
  right: 0;
  bottom: -5rem;
}

.breadcrumbs {
  margin-bottom: 1.5rem;
}
.breadcrumbs ol {
  display: flex;
  position: relative;
  margin-left: 0.5rem;
  align-items: center;
}
.breadcrumbs ol:after {
  content: "";
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  background-color: #000000;
  position: absolute;
  left: -0.5vw;
  top: 50%;
  transform: translateY(-50%);
}
.breadcrumbs ol li {
  position: relative;
}
.breadcrumbs ol li::after {
  content: ">";
  position: absolute;
  font-size: 0.875rem;
  top: 50%;
  right: -3px;
  transform: translateY(-50%);
}
.breadcrumbs ol li:nth-last-child(1):after {
  content: unset;
}
.breadcrumbs ol li span {
  display: block;
  font-size: 0.875rem;
  padding: 0 1rem;
}
@media (max-width: 480px) {
  .breadcrumbs ol li span {
    display: block;
    padding: 0 0.8rem;
  }
}

.careervisual::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/側邊裝飾.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 23px;
  height: 200px;
}
@media (max-width: 640px) {
  .careervisual::after {
    content: unset;
  }
}
.careervisual::after {
  right: 0rem;
  bottom: -5rem;
}

.visualContainer {
  margin-top: 2rem;
}
@media (max-width: 1440px) {
  .visualContainer {
    gap: 2.5rem;
  }
}
@media (max-width: 1240px) {
  .visualContainer {
    gap: 1rem;
    flex-direction: column-reverse;
    margin-bottom: 5rem;
  }
}
.visualContainer .visualContent {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 35%;
  padding-top: 3rem;
}
@media (max-width: 1440px) {
  .visualContainer .visualContent {
    width: 45%;
  }
}
@media (max-width: 1240px) {
  .visualContainer .visualContent {
    gap: 2rem;
    width: 100%;
    margin-bottom: 6rem;
    padding-top: 3rem;
  }
}
.visualContainer .visualContent .visualTitleContainer {
  display: flex;
  position: relative;
  gap: 1rem;
  align-items: flex-end;
  flex-wrap: wrap;
}
@media (max-width: 1240px) {
  .visualContainer .visualContent .visualTitleContainer {
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 640px) {
  .visualContainer .visualContent .visualTitleContainer {
    flex-direction: column;
  }
}
.visualContainer .visualContent .visualTitleContainer .enTitle {
  font-size: 2.25rem;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  color: #0054a7;
  letter-spacing: 1px;
}
@media (max-width: 1024px) {
  .visualContainer .visualContent .visualTitleContainer .enTitle {
    font-size: 2.25rem;
  }
}
.visualContainer .visualContent .visualTitleContainer .visualTitle {
  font-size: 1.125rem;
  color: #000000;
  font-weight: 700;
  padding-bottom: 0.5rem;
}
@media (max-width: 1240px) {
  .visualContainer .visualContent .visualTitleContainer .visualTitle {
    transform: translateY(5px);
  }
}
.visualContainer .visualContent .subTitle {
  display: block;
  color: #0054a7;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 6px;
}
@media (max-width: 1240px) {
  .visualContainer .visualContent .subTitle {
    text-align: center;
  }
}
@media (max-width: 375px) {
  .visualContainer .visualContent .subTitle {
    letter-spacing: 2px;
  }
}
.visualContainer .visualContent p {
  font-size: 1.125rem;
  font-weight: 500;
  color: #000000;
  letter-spacing: 1px;
  margin: auto;
  word-break: break-all;
}
@media (max-width: 1240px) {
  .visualContainer .visualContent p {
    width: 80%;
  }
}
@media (max-width: 1024px) {
  .visualContainer .visualContent p {
    width: 55%;
  }
}
@media (max-width: 640px) {
  .visualContainer .visualContent p {
    width: 100%;
  }
}

.visualImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.aboutBrandMainVisual {
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
}
@media (max-width: 640px) {
  .aboutBrandMainVisual {
    margin-bottom: 3rem;
  }
}
@media (max-width: 1240px) {
  .aboutBrandMainVisual {
    align-items: center;
  }
}
@media (max-width: 480px) {
  .aboutBrandMainVisual {
    margin-bottom: 5rem;
  }
}
.aboutBrandMainVisual:nth-child(odd) {
  position: relative;
}
.aboutBrandMainVisual:nth-child(odd) .aboutMainVisualContainer::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/側邊裝飾.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 23px;
  height: 200px;
}
@media (max-width: 640px) {
  .aboutBrandMainVisual:nth-child(odd) .aboutMainVisualContainer::after {
    content: unset;
  }
}
.aboutBrandMainVisual:nth-child(odd) .aboutMainVisualContainer::after {
  right: 0;
  bottom: -8rem;
}
.aboutBrandMainVisual .aboutMainVisualContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 9.5vw;
  margin-bottom: 7rem;
}
@media (max-width: 1240px) {
  .aboutBrandMainVisual .aboutMainVisualContainer {
    flex-direction: column;
    width: 70%;
    gap: 5rem;
  }
}
@media (max-width: 640px) {
  .aboutBrandMainVisual .aboutMainVisualContainer {
    margin-bottom: 8rem;
    width: 100%;
  }
}
@media (max-width: 375px) {
  .aboutBrandMainVisual .aboutMainVisualContainer {
    gap: 3rem;
  }
}
.aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualContent {
  display: flex;
  flex-direction: column;
  gap: 4.47vw;
  width: 40%;
  font-weight: 500;
}
@media (max-width: 1240px) {
  .aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualContent {
    width: 100%;
    align-items: center;
    gap: unset;
  }
}
.aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualContent .aboutMainVisualTitle {
  font-size: 1.5rem;
  color: #0054a7;
}
@media (max-width: 1240px) {
  .aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualContent .aboutMainVisualTitle {
    text-align: center;
    margin-bottom: 3.125rem;
  }
}
@media (max-width: 1240px) {
  .aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualContent .logo {
    margin-bottom: 3.8rem;
    width: 52%;
  }
}
@media (max-width: 640px) {
  .aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualContent .logo {
    width: 70%;
  }
}
.aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualContent p {
  font-size: 1.125rem;
  letter-spacing: 2px;
  color: #000000;
  letter-spacing: 1px;
}
@media (max-width: 1240px) {
  .aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualContent p {
    width: 80%;
  }
}
@media (max-width: 640px) {
  .aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualContent p {
    width: 100%;
  }
}
.aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualImg {
  width: 70%;
}
@media (max-width: 1240px) {
  .aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualImg {
    width: 90%;
  }
}
@media (max-width: 640px) {
  .aboutBrandMainVisual .aboutMainVisualContainer .aboutMainVisualImg {
    width: 100%;
  }
}
.aboutBrandMainVisual .aboutBrandLink {
  font-size: 1rem;
  color: #0054a7;
  letter-spacing: 1px;
  padding-right: 3.33vw;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 8rem;
}
.aboutBrandMainVisual .aboutBrandLink::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/homeLink.svg");
  width: 1.9vw;
  height: 1.9vw;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: 50%;
  margin-left: 1rem;
  transform: translateY(-50%);
}

.aboutCopo {
  margin-top: 3.33vw;
  margin-bottom: 6.9vw;
}
@media (max-width: 1024px) {
  .aboutCopo {
    margin-top: 7.5rem;
    margin-bottom: 9.7rem;
  }
}
@media (max-width: 480px) {
  .aboutCopo {
    margin-top: 3rem;
  }
}
.aboutCopo .titleContainer {
  display: flex;
  align-items: center;
  gap: 1.5vw;
  padding-right: 1.66vw;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin-bottom: 3.125vw;
}
@media (max-width: 1024px) {
  .aboutCopo .titleContainer {
    margin: auto;
    margin-bottom: 4.8rem;
    gap: 1.625rem;
    padding-right: 1.625rem;
    flex-wrap: wrap;
  }
}
@media (max-width: 640px) {
  .aboutCopo .titleContainer {
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.aboutCopo .titleContainer::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 3px;
  height: 70%;
  background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
}
@media (max-width: 640px) {
  .aboutCopo .titleContainer::after {
    display: none;
  }
}
.aboutCopo .titleContainer span {
  color: #0054a7;
  letter-spacing: 2px;
  font-size: 1.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}
.aboutCopo .titleContainer h3 {
  color: #000000;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 1.5rem;
  position: relative;
}
@media (max-width: 480px) {
  .aboutCopo .titleContainer h3 {
    padding-right: 1rem;
  }
  .aboutCopo .titleContainer h3::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 3px;
    height: 70%;
    background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
  }
}
.aboutCopo .contentContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 3.43vw;
  align-items: center;
  justify-items: center;
}
@media (max-width: 992px) {
  .aboutCopo .contentContainer {
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
    gap: 2rem;
  }
}
@media (max-width: 1024px) {
  .aboutCopo .contentContainer {
    gap: 3.5rem;
  }
}
@media (max-width: 480px) {
  .aboutCopo .contentContainer {
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
  }
}
.aboutCopo .contentContainer .imgContainer {
  max-width: 18rem;
  max-height: 7rem;
  height: 7rem;
  margin: auto;
  display: block;
  position: relative;
}
.aboutCopo .contentContainer .imgContainer::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: #ffffff;
  z-index: -1;
}
.aboutCopo .contentContainer .imgContainer:hover::after {
  transition: all 1s linear;
  opacity: 0.5;
  z-index: 5;
}
.aboutCopo .contentContainer .imgContainer img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: 100%;
  margin: auto;
}

.aboutEsq .container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  margin-bottom: 7.8vw;
}
.aboutEsq .container:nth-child(odd) {
  flex-direction: row-reverse;
  position: relative;
}
.aboutEsq .container:nth-child(odd)::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/側邊裝飾.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 23px;
  height: 200px;
}
@media (max-width: 640px) {
  .aboutEsq .container:nth-child(odd)::after {
    content: unset;
  }
}
.aboutEsq .container:nth-child(odd)::after {
  right: 0;
  bottom: -8rem;
}
@media (max-width: 1024px) {
  .aboutEsq .container:nth-child(odd) {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1024px) {
  .aboutEsq .container {
    flex-direction: column-reverse;
    margin-bottom: 8rem;
  }
}
@media (max-width: 640px) {
  .aboutEsq .container {
    gap: unset;
  }
}
.aboutEsq .container .content {
  display: flex;
  flex-direction: column;
  width: 40%;
  gap: 2.08vw;
}
@media (max-width: 1024px) {
  .aboutEsq .container .content {
    align-items: center;
    justify-content: center;
    width: 90%;
    gap: unset;
  }
}
@media (max-width: 480px) {
  .aboutEsq .container .content {
    width: 100%;
  }
}
.aboutEsq .container .content .aboutMainVisualTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: #0054a7;
  letter-spacing: 6px;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 1rem;
  position: relative;
}
.aboutEsq .container .content .aboutMainVisualTitle::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 3px;
  height: 70%;
  background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
}
@media (max-width: 1024px) {
  .aboutEsq .container .content .aboutMainVisualTitle {
    margin: 4.5rem 0 3rem 0;
  }
}
@media (max-width: 375px) {
  .aboutEsq .container .content .aboutMainVisualTitle {
    font-size: 1.125rem;
    padding-right: unset;
  }
  .aboutEsq .container .content .aboutMainVisualTitle::after {
    content: unset;
  }
}
.aboutEsq .container .content .contentContainer {
  margin-left: 1rem;
}
@media (max-width: 1024px) {
  .aboutEsq .container .content .contentContainer {
    margin-left: unset;
  }
}
.aboutEsq .container .content .contentContainer p {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000000;
  letter-spacing: 6px;
  padding-left: 0.5rem;
  position: relative;
  margin-bottom: 0.5vw;
}
.aboutEsq .container .content .contentContainer p::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #000000;
  top: 50%;
  left: -0.8rem;
  transform: translateY(-50%);
}
@media (max-width: 1024px) {
  .aboutEsq .container .content .contentContainer p {
    font-size: 1.25rem;
    margin-bottom: 0.8rem;
  }
}
@media (max-width: 375px) {
  .aboutEsq .container .content .contentContainer p {
    font-size: 1.125rem;
  }
}
.aboutEsq .container .mainVisualImg {
  width: 60%;
}
@media (max-width: 1024px) {
  .aboutEsq .container .mainVisualImg {
    width: 80%;
  }
}
@media (max-width: 480px) {
  .aboutEsq .container .mainVisualImg {
    width: 100%;
  }
}
.aboutEsq .container .mainVisualImg img {
  margin: auto;
}

.aboutTeam {
  display: flex;
  flex-direction: column;
  margin-top: 2vw;
  align-items: center;
  width: 95%;
  margin: auto;
}
@media (max-width: 640px) {
  .aboutTeam {
    width: 100%;
  }
}
.aboutTeam .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
  gap: 1rem;
  position: relative;
  padding-bottom: 2rem;
  border-radius: 10px;
}
@media (max-width: 1536px) {
  .aboutTeam .container {
    margin-bottom: 3rem;
  }
}
@media (max-width: 1240px) {
  .aboutTeam .container {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1024px) {
  .aboutTeam .container::after {
    content: "";
    position: absolute;
    background-image: url("/src/frontImage/側邊裝飾.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 23px;
    height: 200px;
  }
}
@media (max-width: 1024px) and (max-width: 640px) {
  .aboutTeam .container::after {
    content: unset;
  }
}
@media (max-width: 1024px) {
  .aboutTeam .container::after {
    bottom: -12rem;
    right: 0;
  }
}
.aboutTeam .container:nth-child(2n+1) {
  flex-direction: row-reverse;
}
@media (max-width: 1240px) {
  .aboutTeam .container:nth-child(2n+1) {
    flex-direction: column-reverse;
  }
}
.aboutTeam .container .content {
  display: flex;
  flex-direction: column;
  gap: 2.08vw;
}
@media (max-width: 1240px) {
  .aboutTeam .container .content {
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2rem;
  }
}
.aboutTeam .container .content .aboutMainVisualTitle {
  font-size: 1.5rem;
  font-weight: 700;
  color: #0054a7;
  letter-spacing: 6px;
  position: relative;
  align-self: center;
}
@media (max-width: 1240px) {
  .aboutTeam .container .content .aboutMainVisualTitle {
    margin: auto;
    margin-top: 3rem;
  }
}
@media (max-width: 480px) {
  .aboutTeam .container .content .aboutMainVisualTitle::after {
    content: unset;
  }
}
@media (max-width: 1024px) {
  .aboutTeam .container .content .aboutMainVisualTitle {
    padding-right: unset;
  }
}
@media (max-width: 640px) {
  .aboutTeam .container .content .aboutMainVisualTitle {
    letter-spacing: 2px;
    font-size: 1.25rem;
  }
}
@media (max-width: 1240px) {
  .aboutTeam .container .content .contentContainer {
    width: 70%;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 1024px) {
  .aboutTeam .container .content .contentContainer {
    width: 100%;
  }
}
.aboutTeam .container .content .contentContainer .jobListContainer {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.aboutTeam .container .content .contentContainer .jobListContainer .jobList {
  font-size: 1.25rem;
  font-weight: 700;
  color: #000000;
  letter-spacing: 6px;
  padding-left: 1rem;
  position: relative;
  margin-bottom: 1rem;
}
@media (max-width: 640px) {
  .aboutTeam .container .content .contentContainer .jobListContainer .jobList {
    letter-spacing: 2px;
    font-size: 1.125rem;
  }
}
.aboutTeam .container .content .contentContainer .jobListContainer .jobList::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #000000;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.aboutTeam .container .mainVisualImg {
  width: 50%;
  max-width: 35rem;
  max-height: 45rem;
}
.aboutTeam .container .mainVisualImg img {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
@media (max-width: 1240px) {
  .aboutTeam .container .mainVisualImg {
    width: 70%;
  }
}
@media (max-width: 640px) {
  .aboutTeam .container .mainVisualImg {
    width: 100%;
  }
}

.person {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  text-align: right;
}
@media (max-width: 1240px) {
  .person {
    justify-content: center;
    width: 100%;
  }
}
.person:hover .personContainer {
  transition: all 0.3s linear;
  background-color: #0054a7;
  color: #ffffff;
}
.person:hover .personContainer .personTitleContainer .personTitle .name {
  transition: all 0.3s linear;
  color: #ffffff;
}
@media (max-width: 1240px) {
  .person {
    text-align: unset;
    padding-right: unset;
  }
}
.person .ImgVisual {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 480px) {
  .person .ImgVisual {
    width: 8rem;
    height: 8rem;
  }
}
.person .personContainer {
  background-color: #f0f0f0;
  border-radius: 15px;
  position: relative;
  left: -2rem;
  padding: 1.25rem 1.25rem 1.25rem 3rem;
  z-index: -1;
  height: 50%;
}
@media (max-width: 375px) {
  .person .personContainer {
    width: 100%;
    left: -2rem;
    padding-left: 3rem;
  }
}
.person .personContainer .personTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.person .personContainer .personTitleContainer .personTitle {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
  margin-bottom: 0.5vw;
}
@media (max-width: 1024px) {
  .person .personContainer .personTitleContainer .personTitle {
    flex-wrap: wrap;
  }
}
.person .personContainer .personTitleContainer .personTitle .name {
  font-size: 1.5rem;
  font-weight: 700;
  color: #000000;
  letter-spacing: 6px;
  position: relative;
}
.person .personContainer .personTitleContainer .personTitle span {
  font-size: 1.25rem;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-weight: 700;
  display: block;
  padding-bottom: 2px;
}
.person .personContainer .position {
  letter-spacing: 1px;
  font-size: 1.25rem;
  font-weight: 900;
}

.serviceInter {
  display: flex;
}
.serviceInter .MainVisual {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.7vw;
  margin-bottom: 5.8vw;
  position: relative;
}
.serviceInter .MainVisual::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/側邊裝飾.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 23px;
  height: 200px;
}
@media (max-width: 640px) {
  .serviceInter .MainVisual::after {
    content: unset;
  }
}
.serviceInter .MainVisual::after {
  right: 0;
  bottom: -5rem;
}
@media (max-width: 1024px) {
  .serviceInter .MainVisual {
    margin-bottom: unset;
  }
}
.serviceInter .MainVisual .MainVisualContent {
  display: flex;
  flex-direction: column;
  gap: 2.08vw;
  font-size: 1.5rem;
  padding: 8vw 0;
}
@media (max-width: 1240px) {
  .serviceInter .MainVisual .MainVisualContent {
    width: 100%;
    padding: 3rem 0;
  }
}
.serviceInter .MainVisual .MainVisualContent .mainVisualTitleContainer {
  display: flex;
  position: relative;
  gap: 2vw;
  align-items: flex-end;
}
.serviceInter .MainVisual .MainVisualContent .mainVisualTitleContainer .enTitle {
  display: block;
  font-size: 2.25rem;
  font-weight: 500;
  color: #0054a7;
  letter-spacing: 1px;
}
@media (max-width: 1024px) {
  .serviceInter .MainVisual .MainVisualContent .mainVisualTitleContainer .enTitle {
    font-size: 2.25rem;
  }
}
.serviceInter .MainVisual .MainVisualContent .mainVisualTitleContainer .MainVisualTitle {
  font-size: 1.5rem;
  color: #000000;
  font-weight: 700;
  padding-bottom: 0.5vw;
}
.serviceInter .MainVisual .MainVisualContent .subTitle {
  display: block;
  color: #0054a7;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 2px;
}
.serviceInter .MainVisual .MainVisualContent p {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000000;
  letter-spacing: 1px;
}
@media (max-width: 640px) {
  .serviceInter .MainVisual .MainVisualContent p {
    font-size: 1.125rem;
  }
}

.serviceInterSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2vw;
}
.serviceInterSection .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 8vw;
  gap: 5rem;
  margin-bottom: 8rem;
  position: relative;
}
.serviceInterSection .container:nth-child(odd) {
  flex-direction: row-reverse;
}
@media (max-width: 1024px) {
  .serviceInterSection .container:nth-child(odd) {
    flex-direction: column-reverse;
  }
}
@media (max-width: 1024px) {
  .serviceInterSection .container {
    flex-direction: column-reverse;
    margin-bottom: 10rem;
  }
  .serviceInterSection .container::after {
    content: "";
    position: absolute;
    background-image: url("/src/frontImage/側邊裝飾.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 23px;
    height: 200px;
  }
}
@media (max-width: 1024px) and (max-width: 640px) {
  .serviceInterSection .container::after {
    content: unset;
  }
}
@media (max-width: 1024px) {
  .serviceInterSection .container::after {
    right: 0;
    bottom: -8rem;
  }
}
@media (max-width: 1024px) {
  .serviceInterSection .container:nth-last-child(2)::after {
    content: unset;
  }
}
@media (max-width: 375px) {
  .serviceInterSection .container {
    gap: 3rem;
  }
}
.serviceInterSection .container .content {
  display: flex;
  flex-direction: column;
  gap: 2.08vw;
  font-size: 1.5rem;
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
}
@media (max-width: 1240px) {
  .serviceInterSection .container .content {
    width: 70%;
  }
}
@media (max-width: 1024px) {
  .serviceInterSection .container .content {
    align-items: center;
  }
}
@media (max-width: 640px) {
  .serviceInterSection .container .content {
    width: 90%;
  }
}
@media (max-width: 375px) {
  .serviceInterSection .container .content {
    gap: 3rem;
  }
}
.serviceInterSection .container .content .titleContainer {
  display: flex;
  color: #0054a7;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 1rem;
  color: #0054a7;
  font-size: 1.5rem;
}
@media (max-width: 1440px) {
  .serviceInterSection .container .content .titleContainer {
    flex-direction: column;
  }
}
@media (max-width: 1240px) {
  .serviceInterSection .container .content .titleContainer {
    flex-direction: row;
  }
}
@media (max-width: 375px) {
  .serviceInterSection .container .content .titleContainer {
    flex-direction: column;
  }
  .serviceInterSection .container .content .titleContainer::after {
    display: none;
  }
}
@media (max-width: 1536px) {
  .serviceInterSection .container .content .titleContainer {
    flex-direction: column;
  }
}
@media (max-width: 375px) {
  .serviceInterSection .container .content .titleContainer {
    align-items: center;
    padding-right: unset;
  }
}
.serviceInterSection .container .content .titleContainer .title {
  display: block;
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 6px;
}
.serviceInterSection .container .content .titleContainer .entitle {
  display: block;
  font-size: 1.125rem;
  font-weight: 900;
  letter-spacing: 2px;
  transform: translateY(0.25rem);
}
@media (max-width: 640px) {
  .serviceInterSection .container .content .titleContainer .entitle {
    font-size: 1rem;
  }
}
.serviceInterSection .container .content ul {
  margin-left: 1rem;
}
.serviceInterSection .container .content ul li {
  font-size: 1.25rem;
  font-weight: 700;
  color: #000000;
  letter-spacing: 6px;
  position: relative;
  margin-bottom: 0.5rem;
}
@media (max-width: 480px) {
  .serviceInterSection .container .content ul li {
    letter-spacing: 2px;
  }
}
.serviceInterSection .container .content ul li::before {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #000000;
  top: 50%;
  left: -0.8rem;
  transform: translateY(-50%);
}
@media (max-width: 375px) {
  .serviceInterSection .container .content ul li {
    padding-left: unset;
    letter-spacing: 2px;
    font-size: 1.125rem;
  }
}
.serviceInterSection .container .content .detail {
  font-size: 1.125rem;
  color: #000000;
  letter-spacing: 2px;
}
@media (max-width: 1024px) {
  .serviceInterSection .container .content .detail {
    width: 85%;
    margin: auto;
  }
}
@media (max-width: 640px) {
  .serviceInterSection .container .content .detail {
    width: 100%;
  }
}
.serviceInterSection .container .mainVisualImg {
  width: 60%;
  align-self: center;
}
@media (max-width: 1024px) {
  .serviceInterSection .container .mainVisualImg {
    width: 65%;
  }
}
@media (max-width: 640px) {
  .serviceInterSection .container .mainVisualImg {
    width: 100%;
  }
}

.hireMainSection {
  position: relative;
}
.hireMainSection::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/側邊裝飾.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 23px;
  height: 200px;
}
@media (max-width: 640px) {
  .hireMainSection::after {
    content: unset;
  }
}
.hireMainSection::after {
  right: 0;
  bottom: 20%;
}
.hireMainSection .container {
  max-width: 100%;
}
@media (max-width: 1024px) {
  .hireMainSection .container {
    width: 90%;
    margin: auto;
    margin-bottom: 7.75rem;
  }
}
@media (max-width: 480px) {
  .hireMainSection .container {
    width: 100%;
  }
}
.hireMainSection .container .titleContainer {
  display: flex;
  position: relative;
  font-size: 1.25rem;
  letter-spacing: 2px;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 1rem;
  gap: 1vw;
  margin-bottom: 2rem;
}
@media (max-width: 480px) {
  .hireMainSection .container .titleContainer {
    margin-bottom: 2.75rem;
    flex-wrap: wrap;
  }
}
.hireMainSection .container .titleContainer span {
  color: #0054a7;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}
.hireMainSection .container .titleContainer h4 {
  font-weight: 700;
}
.hireMainSection .container .titleContainer::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 3px;
  height: 70%;
  background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
}
@media (max-width: 1024px) {
  .hireMainSection .container .titleContainer {
    margin: auto;
    margin-bottom: 2rem;
  }
}
.hireMainSection .container .content {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 1.09vw 1.56vw;
  align-items: center;
}
@media (max-width: 1240px) {
  .hireMainSection .container .content {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.35rem;
  }
}
@media (max-width: 480px) {
  .hireMainSection .container .content {
    grid-template-columns: repeat(1, 1fr);
  }
}
.hireMainSection .container .content .contentContainer {
  position: relative;
  cursor: pointer;
  text-align: center;
}
.hireMainSection .container .content .contentContainer .contenFirst {
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #f5f5f5;
  padding: 2.6vw 3.5vw 1.5vw 3.5vw;
}
@media (max-width: 640px) {
  .hireMainSection .container .content .contentContainer .contenFirst {
    border-radius: 10px;
    padding: 1.25rem;
  }
}
.hireMainSection .container .content .contentContainer .contenFirst .imgcontainer {
  width: 14vw;
  height: 8vw;
  margin: auto;
}
@media (max-width: 1024px) {
  .hireMainSection .container .content .contentContainer .contenFirst .imgcontainer {
    width: 17rem;
    height: 9.5rem;
  }
}
@media (max-width: 480px) {
  .hireMainSection .container .content .contentContainer .contenFirst .imgcontainer {
    width: 10rem;
  }
}
.hireMainSection .container .content .contentContainer .contenFirst .imgcontainer img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.hireMainSection .container .content .contentContainer .contenFirst h4 {
  margin-top: 1.5vw;
  font-weight: 700;
}
@media (max-width: 640px) {
  .hireMainSection .container .content .contentContainer .contenFirst h4 {
    margin-top: 0.5rem;
  }
}
.hireMainSection .container .content .contentContainer .contenFirst span {
  color: #076baf;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}
.hireMainSection .container .content .contentContainer .contenFirst p {
  display: none;
}
.hireMainSection .container .content .contentContainer .contentSec {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 238, 0);
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 640px) {
  .hireMainSection .container .content .contentContainer .contentSec {
    border-radius: 10px;
    padding: 1.25rem;
  }
}
.hireMainSection .container .content .contentContainer .contentSec:hover {
  opacity: 1;
}
.hireMainSection .container .content .contentContainer .contentSec h4 {
  font-weight: 700;
}
@media (max-width: 480px) {
  .hireMainSection .container .content .contentContainer .contentSec h4 {
    font-size: 1.125rem;
  }
}
.hireMainSection .container .content .contentContainer .contentSec span {
  color: #076baf;
  margin-bottom: 1.5vw;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}
@media (max-width: 640px) {
  .hireMainSection .container .content .contentContainer .contentSec span {
    margin-bottom: 1rem;
  }
}
@media (max-width: 480px) {
  .hireMainSection .container .content .contentContainer .contentSec span {
    font-size: 1.125rem;
  }
}
.hireMainSection .container .content .contentContainer .contentSec p {
  margin-bottom: 0.7vw;
  font-weight: 700;
}
@media (max-width: 640px) {
  .hireMainSection .container .content .contentContainer .contentSec p {
    margin-bottom: 0.8rem;
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.secContainer {
  margin-top: 14rem;
  margin-bottom: 26rem;
  display: flex;
  padding: 0;
}
@media (max-width: 1240px) {
  .secContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin: auto;
    margin-bottom: 12.5rem;
    margin-top: 7.75rem;
  }
}
@media (max-width: 480px) {
  .secContainer {
    width: 100%;
  }
}
.secContainer .content {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 1240px) {
  .secContainer .content {
    width: 65%;
    margin-bottom: 5rem;
    align-items: center;
  }
}
@media (max-width: 768px) {
  .secContainer .content {
    width: 85%;
  }
}
@media (max-width: 480px) {
  .secContainer .content {
    width: 100%;
  }
}
.secContainer .content h2 {
  color: #076baf;
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 2rem;
}
@media (max-width: 1440px) {
  .secContainer .content h2 {
    font-size: 1.25rem;
  }
}
.secContainer .content .contentContainer {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  font-size: 1.125rem;
  font-weight: 700;
  letter-spacing: 1px;
  padding-right: 2vw;
}
@media (max-width: 1440px) {
  .secContainer .content .contentContainer {
    font-size: 1rem;
  }
}
@media (max-width: 1240px) {
  .secContainer .content .contentContainer {
    padding-right: unset;
  }
}
.secContainer .content .contentContainer p {
  font-weight: 500;
}
.secContainer .content .linkContainer {
  display: flex;
  gap: 1vw;
  flex-grow: 2;
  align-items: flex-end;
  margin-top: 1.5rem;
}
@media (max-width: 1240px) {
  .secContainer .content .linkContainer {
    gap: 2rem;
    margin-top: 5rem;
  }
}
.secContainer .content .linkContainer a {
  width: 4rem;
}
.secContainer .mainVisual {
  width: 60%;
  display: flex;
  align-items: center;
  opacity: 1;
  animation: opacity 2s linear;
}
@media (max-width: 1240px) {
  .secContainer .mainVisual {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .secContainer .mainVisual {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .secContainer .mainVisual {
    width: 100%;
  }
}
@keyframes opacity {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

/* 產生游標閃爍的效果 */
.hirePolicyMain .mainVisual {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6vw;
  margin-bottom: 8rem;
}
@media (max-width: 1440px) {
  .hirePolicyMain .mainVisual {
    gap: unset;
  }
}
@media (max-width: 1240px) {
  .hirePolicyMain .mainVisual {
    flex-wrap: wrap;
    width: 70%;
    margin: auto;
    flex-direction: column-reverse;
  }
}
@media (max-width: 480px) {
  .hirePolicyMain .mainVisual {
    width: 90%;
    margin-bottom: 2rem;
  }
}
.hirePolicyMain .mainVisual .mainVisualContent {
  display: flex;
  flex-direction: column;
  gap: 2.08vw;
  width: 40%;
  padding: 8vw 0;
}
@media (max-width: 1240px) {
  .hirePolicyMain .mainVisual .mainVisualContent {
    align-items: center;
    width: 90%;
    gap: 1rem;
  }
}
@media (max-width: 480px) {
  .hirePolicyMain .mainVisual .mainVisualContent {
    width: 100%;
  }
}
.hirePolicyMain .mainVisual .mainVisualContent h2 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #076baf;
  letter-spacing: 2px;
}
@media (max-width: 480px) {
  .hirePolicyMain .mainVisual .mainVisualContent h2 {
    font-size: 1.25rem;
  }
}
.hirePolicyMain .mainVisual .mainVisualContent p {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000000;
  letter-spacing: 1px;
}
@media (max-width: 640px) {
  .hirePolicyMain .mainVisual .mainVisualContent p {
    font-size: 1rem;
  }
}
.hirePolicyMain .mainVisual .mainVisualImg {
  width: 60%;
}
@media (max-width: 1240px) {
  .hirePolicyMain .mainVisual .mainVisualImg {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .hirePolicyMain .mainVisual .mainVisualImg {
    width: 100%;
  }
}
.hirePolicyMain .container {
  margin-bottom: 12vw;
  position: relative;
}
.hirePolicyMain .container::after {
  content: "";
  position: absolute;
  background-image: url("/src/frontImage/側邊裝飾.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 23px;
  height: 200px;
}
@media (max-width: 640px) {
  .hirePolicyMain .container::after {
    content: unset;
  }
}
.hirePolicyMain .container::after {
  right: -2rem;
  bottom: 12vw;
}
@media (max-width: 1240px) {
  .hirePolicyMain .container {
    width: 90%;
    margin: 8rem auto;
  }
}
@media (max-width: 480px) {
  .hirePolicyMain .container {
    width: 100%;
    margin: 4rem auto;
  }
}
.hirePolicyMain .container .titleContainer {
  display: flex;
  position: relative;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 2px;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 1rem;
  gap: 1vw;
  margin-bottom: 3vw;
}
@media (max-width: 1240px) {
  .hirePolicyMain .container .titleContainer {
    margin: auto;
    margin-bottom: 2rem;
    flex-wrap: wrap;
  }
}
@media (max-width: 480px) {
  .hirePolicyMain .container .titleContainer {
    justify-content: space-around;
    font-size: 1.25rem;
  }
}
.hirePolicyMain .container .titleContainer span {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  color: #0054a7;
}
.hirePolicyMain .container .titleContainer h4 {
  font-weight: 700;
  position: relative;
}
@media (max-width: 480px) {
  .hirePolicyMain .container .titleContainer h4 {
    padding-right: 1rem;
  }
  .hirePolicyMain .container .titleContainer h4::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 3px;
    height: 70%;
    background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
  }
}
.hirePolicyMain .container .content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.09vw 1.56vw;
  align-items: center;
}
@media (max-width: 1240px) {
  .hirePolicyMain .container .content {
    width: 90%;
    margin: auto;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    justify-items: center;
  }
}
@media (max-width: 375px) {
  .hirePolicyMain .container .content {
    display: block;
    width: 100%;
  }
}
.hirePolicyMain .container .content .contentContainer {
  position: relative;
  cursor: pointer;
  text-align: center;
  width: 100%;
}
.hirePolicyMain .container .content .contentContainer .contenFirst {
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #f5f5f5;
  padding: 2.6vw 0 1.5vw 0;
}
@media (max-width: 640px) {
  .hirePolicyMain .container .content .contentContainer .contenFirst {
    border-radius: 10px;
    padding: 1.25rem;
  }
}
@media (max-width: 1240px) {
  .hirePolicyMain .container .content .contentContainer .contenFirst {
    padding: 2rem;
  }
}
@media (max-width: 375px) {
  .hirePolicyMain .container .content .contentContainer .contenFirst {
    margin-bottom: 2rem;
    padding: 1.5rem 2rem;
  }
}
.hirePolicyMain .container .content .contentContainer .contenFirst .imgcontainer {
  width: 14vw;
  height: 8vw;
  margin: auto;
}
@media (max-width: 1240px) {
  .hirePolicyMain .container .content .contentContainer .contenFirst .imgcontainer {
    width: 8rem;
    height: 7rem;
  }
}
.hirePolicyMain .container .content .contentContainer .contenFirst .imgcontainer img {
  height: 100%;
  width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.hirePolicyMain .container .content .contentContainer .contenFirst h4 {
  margin-top: 1.5vw;
  font-weight: 700;
}
.hirePolicyMain .container .content .contentContainer .contenFirst span {
  color: #076baf;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}
.hirePolicyMain .container .content .contentContainer .contenFirst p {
  display: none;
  font-weight: 700;
}
.hirePolicyMain .container .content .contentContainer .contentSec {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 238, 0);
  top: 0;
  left: 0;
  opacity: 0;
  transition: all 0.5s;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 1px;
  background-color: #f5f5f5;
  padding: 2.6vw 0 1.5vw 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (max-width: 640px) {
  .hirePolicyMain .container .content .contentContainer .contentSec {
    border-radius: 10px;
    padding: 1.25rem;
  }
}
.hirePolicyMain .container .content .contentContainer .contentSec:hover {
  opacity: 1;
}
.hirePolicyMain .container .content .contentContainer .contentSec h4 {
  font-size: 1rem;
  font-weight: 700;
}
.hirePolicyMain .container .content .contentContainer .contentSec span {
  color: #076baf;
  margin-bottom: 1vw;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}
.hirePolicyMain .container .content .contentContainer .contentSec ul {
  display: flex;
  flex-direction: column;
  gap: 0.2vw;
  text-align: left;
  margin-top: 0.5rem;
}
.hirePolicyMain .container .content .contentContainer .contentSec ul li {
  position: relative;
  font-weight: 700;
}
.hirePolicyMain .container .content .contentContainer .contentSec ul li::after {
  content: "";
  position: absolute;
  width: 0.25rem;
  height: 0.25rem;
  background-color: #000000;
  border-radius: 50%;
  top: 50%;
  left: -0.85rem;
  transform: translateY(-50%);
}
.hirePolicyMain .secContainer {
  display: flex;
  flex-direction: column;
  gap: 3vw;
  margin-bottom: 15vw;
}
@media (max-width: 1240px) {
  .hirePolicyMain .secContainer {
    margin-top: 8rem;
  }
}
.hirePolicyMain .secContainer .secContentContainer {
  display: flex;
  justify-content: space-between;
  gap: 2.6vw;
}
@media (max-width: 1024px) {
  .hirePolicyMain .secContainer .secContentContainer {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.hirePolicyMain .secContainer .secContentContainer:nth-child(2n) {
  flex-direction: row-reverse;
}
@media (max-width: 1024px) {
  .hirePolicyMain .secContainer .secContentContainer:nth-child(2n) {
    flex-direction: column-reverse;
  }
}
.hirePolicyMain .secContainer .secContentContainer:nth-child(2n) .content {
  width: 50%;
}
@media (max-width: 1024px) {
  .hirePolicyMain .secContainer .secContentContainer:nth-child(2n) .content {
    width: 100%;
  }
}
.hirePolicyMain .secContainer .secContentContainer:nth-child(2n) .content h2 {
  padding-left: 2vw;
}
.hirePolicyMain .secContainer .secContentContainer:nth-child(2n) .content .contentContainer {
  padding-left: 3.1vw;
}
.hirePolicyMain .secContainer .secContentContainer:nth-child(2n) .mainVisual {
  width: 55%;
}
@media (max-width: 1024px) {
  .hirePolicyMain .secContainer .secContentContainer:nth-child(2n) .mainVisual {
    width: 70%;
  }
}
@media (max-width: 480px) {
  .hirePolicyMain .secContainer .secContentContainer:nth-child(2n) .mainVisual {
    width: 100%;
  }
}
.hirePolicyMain .secContainer .secContentContainer .content {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1024px) {
  .hirePolicyMain .secContainer .secContentContainer .content {
    width: 100%;
  }
}
.hirePolicyMain .secContainer .secContentContainer .content h2 {
  color: #076baf;
  font-size: 1.5rem;
  letter-spacing: 6px;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  padding-right: 1rem;
  margin-bottom: 2rem;
  font-weight: 700;
}
.hirePolicyMain .secContainer .secContentContainer .content h2::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 3px;
  height: 70%;
  background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
}
@media (max-width: 640px) {
  .hirePolicyMain .secContainer .secContentContainer .content h2::after {
    display: none;
  }
}
@media (max-width: 1024px) {
  .hirePolicyMain .secContainer .secContentContainer .content h2 {
    margin: 3rem 0 2rem 0;
  }
}
@media (max-width: 640px) {
  .hirePolicyMain .secContainer .secContentContainer .content h2 {
    font-size: 1.25rem;
  }
}
.hirePolicyMain .secContainer .secContentContainer .content .contentContainer {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding-left: 0.8vw;
  letter-spacing: 2px;
}
@media (max-width: 1024px) {
  .hirePolicyMain .secContainer .secContentContainer .content .contentContainer {
    margin: auto;
  }
}
.hirePolicyMain .secContainer .secContentContainer .content .contentContainer li {
  padding-left: 1rem;
  position: relative;
  font-size: 1.25rem;
  font-weight: 700;
}
@media (max-width: 480px) {
  .hirePolicyMain .secContainer .secContentContainer .content .contentContainer li {
    font-size: 1rem;
  }
}
.hirePolicyMain .secContainer .secContentContainer .content .contentContainer li::after {
  content: "";
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #000000;
  border-radius: 50%;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.hirePolicyMain .secContainer .secContentContainer .mainVisual {
  width: 45%;
}
@media (max-width: 1024px) {
  .hirePolicyMain .secContainer .secContentContainer .mainVisual {
    width: 100%;
  }
}

.awardMain {
  margin-bottom: 8rem;
  display: flex;
  justify-content: flex-end;
  padding-right: 3rem;
}
@media (max-width: 1440px) {
  .awardMain {
    padding-right: 1rem;
  }
}
@media (max-width: 480px) {
  .awardMain {
    padding-right: unset;
  }
}
.awardMain .container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 90%;
}
@media (max-width: 1440px) {
  .awardMain .container {
    width: 95%;
  }
}
@media (max-width: 1240px) {
  .awardMain .container {
    gap: 8rem;
    width: 80%;
    margin: auto;
  }
}
@media (max-width: 640px) {
  .awardMain .container {
    gap: 4em;
    width: 100%;
  }
}
@media (max-width: 375px) {
  .awardMain .container {
    gap: unset;
  }
}
.awardMain .container .contentContainer {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 3rem;
}
.awardMain .container .contentContainer:hover .content {
  transition: all 0.3s linear;
  background-color: #0054a7;
  color: #ffffff;
}
.awardMain .container .contentContainer .title {
  display: flex;
  gap: 2.5rem;
  position: relative;
}
@media (max-width: 1240px) {
  .awardMain .container .contentContainer .title {
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 480px) {
  .awardMain .container .contentContainer .title {
    max-width: 100%;
    justify-content: center;
  }
}
@media (max-width: 375px) {
  .awardMain .container .contentContainer .title {
    flex-wrap: wrap;
  }
}
.awardMain .container .contentContainer .title .trophy {
  width: 11.5rem;
  height: 14rem;
}
@media (max-width: 1024px) {
  .awardMain .container .contentContainer .title .trophy {
    width: unset;
    height: unset;
  }
}
.awardMain .container .contentContainer .title .trophy img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.awardMain .container .contentContainer .title .titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  padding-right: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
  width: 100%;
}
@media (max-width: 1440px) {
  .awardMain .container .contentContainer .title .titleContainer {
    padding-right: 0;
  }
}
@media (max-width: 1240px) {
  .awardMain .container .contentContainer .title .titleContainer {
    padding-right: 3rem;
  }
}
@media (max-width: 480px) {
  .awardMain .container .contentContainer .title .titleContainer {
    padding-right: 0;
    align-self: center;
  }
}
.awardMain .container .contentContainer .title .titleContainer h4 {
  font-size: 1.125rem;
  position: relative;
}
@media (max-width: 1024px) {
  .awardMain .container .contentContainer .title .titleContainer h4 {
    text-align: center;
  }
}
@media (max-width: 480px) {
  .awardMain .container .contentContainer .title .titleContainer h4::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url("/src/frontImage/vector_yellow.svg");
    height: 1.25rem;
    width: 1.25rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.awardMain .container .contentContainer .title .titleContainer .imgContainer {
  width: 9rem;
  height: 3.5rem;
}
@media (max-width: 1024px) {
  .awardMain .container .contentContainer .title .titleContainer .imgContainer {
    margin: auto;
  }
}
.awardMain .container .contentContainer .title .titleContainer .imgContainer img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.awardMain .container .contentContainer .title::after {
  content: "";
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-image: url("/src/frontImage/vector_yellow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  top: 50%;
  right: 2.5rem;
  transform: translateY(-50%);
}
@media (max-width: 1440px) {
  .awardMain .container .contentContainer .title::after {
    display: none;
  }
}
@media (max-width: 1240px) {
  .awardMain .container .contentContainer .title::after {
    display: block;
    right: 0rem;
  }
}
@media (max-width: 480px) {
  .awardMain .container .contentContainer .title::after {
    display: none;
  }
}
.awardMain .container .contentContainer .content {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 15px 0 0 15px;
  padding: 2.5rem 3.75rem;
  width: 60%;
  word-break: break-all;
  border-radius: 15px;
}
@media (max-width: 1440px) {
  .awardMain .container .contentContainer .content {
    padding: 2rem 3rem;
  }
}
@media (max-width: 1240px) {
  .awardMain .container .contentContainer .content {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .awardMain .container .contentContainer .content {
    margin-top: 3rem;
  }
}
@media (max-width: 640px) {
  .awardMain .container .contentContainer .content {
    padding: 1rem;
  }
}
.awardMain .container .contentContainer .content p {
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 2px;
}
@media (max-width: 1240px) {
  .awardMain .container .contentContainer {
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  .awardMain .container .contentContainer {
    flex-direction: column;
    margin-bottom: 5rem;
    gap: 0.5rem;
  }
}

.newsMainCard .container {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}
@media (max-width: 1024px) {
  .newsMainCard .container {
    max-width: 100%;
  }
}
@media (max-width: 640px) {
  .newsMainCard .container {
    gap: unset;
  }
}
.newsMainCard .container .bookmarkContainer {
  display: none;
}
@media (max-width: 1024px) {
  .newsMainCard .container .bookmarkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d9d9d9;
    border-radius: 15px;
    cursor: pointer;
    position: relative;
  }
  .newsMainCard .container .bookmarkContainer .focustyle {
    background-color: #0054a7;
    color: #ffffff;
  }
  .newsMainCard .container .bookmarkContainer .first {
    border-radius: 15px 0 0 15px;
    position: relative;
    border-right: 2px solid #f0f0f0;
  }
  .newsMainCard .container .bookmarkContainer .first:hover {
    transition: all 0.5s linear;
    background-color: #0054a7;
    color: #ffffff;
    border-color: #0054a7;
  }
  .newsMainCard .container .bookmarkContainer .second {
    border-radius: 0 15px 15px 0;
    border-left: 2px solid #f0f0f0;
  }
  .newsMainCard .container .bookmarkContainer .second:hover {
    transition: all 0.5s linear;
    background-color: #0054a7;
    color: #ffffff;
  }
}
.newsMainCard .container .bookmarkContainer .bookmark {
  font-size: 1.5rem;
  font-weight: 700;
  padding: 1rem;
  width: 50%;
  text-align: center;
}
@media (max-width: 480px) {
  .newsMainCard .container .bookmarkContainer .bookmark {
    font-size: 1.25rem;
  }
}
.newsMainCard .container .bookmarkContainer .bookMarklabel {
  background-color: #000000;
  width: 3px;
  height: 2.5rem;
  position: relative;
  display: block;
}
.newsMainCard .container .contentContainer {
  width: 95%;
}
@media (max-width: 1024px) {
  .newsMainCard .container .contentContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin: 0 auto;
  }
}
@media (max-width: 768px) {
  .newsMainCard .container .contentContainer {
    grid-template-columns: 1fr;
    margin-top: 1.5rem;
    gap: unset;
  }
}
.newsMainCard .container .contentContainer .containerCard {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  margin-bottom: 3rem;
  color: #000000;
  margin-top: 2rem;
  border-radius: 15px;
}
.newsMainCard .container .contentContainer .containerCard:hover img {
  transition: all 1s linear;
  transform: scale(1.1);
}
.newsMainCard .container .contentContainer .containerCard:hover .pinImage {
  transition: all 0.5s linear;
  background-image: url("/src/frontImage/pinafter.png");
}
.newsMainCard .container .contentContainer .containerCard .pinImage {
  position: absolute;
  top: 0;
  right: 1.5rem;
  width: 2vw;
  height: 3vw;
  background-image: url("/src/frontImage/pinbefore.png");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 5;
}
@media (max-width: 1024px) {
  .newsMainCard .container .contentContainer .containerCard .pinImage {
    width: 3rem;
    height: 4rem;
  }
}
@media (max-width: 480px) {
  .newsMainCard .container .contentContainer .containerCard .pinImage {
    width: 1.5rem;
    height: 2.5rem;
  }
}
.newsMainCard .container .contentContainer .containerCard::after {
  content: "";
  background-color: #f5f5f5;
  width: 70%;
  height: 100%;
  position: absolute;
  z-index: -1;
  right: 0;
  border-radius: 15px;
}
@media (max-width: 1024px) {
  .newsMainCard .container .contentContainer .containerCard::after {
    content: "";
    background-color: #f5f5f5;
    width: 100%;
    height: 95%;
    position: absolute;
    z-index: -1;
    right: 0;
    bottom: 0;
    border-radius: 15px;
  }
}
@media (max-width: 1024px) {
  .newsMainCard .container .contentContainer .containerCard {
    display: block;
    margin-bottom: unset;
    flex-direction: column;
  }
}
.newsMainCard .container .contentContainer .containerCard:hover {
  transition: all 0.5s linear;
  color: #ffffff;
}
.newsMainCard .container .contentContainer .containerCard:hover::after {
  transition: all 0.5s linear;
  background-color: #0054a7;
}
@media (max-width: 375px) {
  .newsMainCard .container .contentContainer .containerCard {
    padding: unset;
  }
}
.newsMainCard .container .contentContainer .containerCard .imgContainer {
  position: relative;
  overflow: hidden;
  padding-left: 1rem;
  width: 25vw;
  height: 15vw;
  border-radius: 15px;
}
@media (max-width: 1440px) {
  .newsMainCard .container .contentContainer .containerCard .imgContainer {
    height: 12rem;
  }
}
@media (max-width: 1024px) {
  .newsMainCard .container .contentContainer .containerCard .imgContainer {
    width: 100%;
    height: 15rem;
    padding: unset;
  }
}
.newsMainCard .container .contentContainer .containerCard .imgContainer img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 15px;
  position: absolute;
}
.newsMainCard .container .contentContainer .containerCard .content {
  width: 70%;
  padding: 3rem 1rem 3rem 1rem;
  position: relative;
  letter-spacing: 1px;
  border-radius: 15px;
}
@media (max-width: 1440px) {
  .newsMainCard .container .contentContainer .containerCard .content {
    width: 60%;
  }
}
@media (max-width: 1024px) {
  .newsMainCard .container .contentContainer .containerCard .content {
    width: 100%;
  }
}
.newsMainCard .container .contentContainer .containerCard .content h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5vw;
}
@media (max-width: 1440px) {
  .newsMainCard .container .contentContainer .containerCard .content h3 {
    font-size: 1.125rem;
  }
}
@media (max-width: 1024px) {
  .newsMainCard .container .contentContainer .containerCard .content h3 {
    font-size: 1.25rem;
  }
}
@media (max-width: 640px) {
  .newsMainCard .container .contentContainer .containerCard .content h3 {
    margin-bottom: 1rem;
  }
}
@media (max-width: 768px) {
  .newsMainCard .container .contentContainer .containerCard .content h3 {
    font-size: 1.125rem;
  }
}
.newsMainCard .container .contentContainer .containerCard .content span {
  display: block;
  font-size: 1.125rem;
}
.newsMainCard .container .contentContainer .containerCard .content p {
  margin-top: 0.5vw;
  font-size: 1.125rem;
}
@media (max-width: 640px) {
  .newsMainCard .container .contentContainer .containerCard .content p {
    margin-bottom: 1.5rem;
  }
}
@media (max-width: 768px) {
  .newsMainCard .container .contentContainer .containerCard .content p {
    font-size: 1rem;
  }
}
@media (max-width: 375px) {
  .newsMainCard .container .contentContainer .containerCard .content p {
    display: none;
  }
}

.inputField {
  background-color: #f0f0f0;
  border-radius: 10px;
  font-size: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  border-radius: 6px;
}
.inputField .formLabel {
  color: #000000;
  font-weight: 700;
  letter-spacing: 1px;
}
.inputField .field {
  letter-spacing: 1px;
  background-color: #f0f0f0;
  width: 100%;
}

.textareaField {
  background-color: #f0f0f0;
  border-radius: 6px;
  font-size: 1rem;
  padding: 0.5rem 1rem 0 1rem;
  display: flex;
  align-items: center;
}
@media (max-width: 640px) {
  .textareaField {
    margin-bottom: 2rem;
  }
}
.textareaField .formcontentspan {
  display: flex;
  align-self: flex-start;
  padding-top: 0.8rem;
}
.textareaField .formLabel {
  color: #000000;
  font-weight: 700;
  letter-spacing: 1px;
  display: flex;
  align-self: flex-start;
  padding-top: 0.8rem;
}
.textareaField .field {
  letter-spacing: 1px;
  background-color: #f0f0f0;
  width: 100%;
  padding-top: 0.8rem;
  height: 300px;
}

.submitBtn {
  color: #ffffff;
  border: 1px solid transparent;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.submitBtn:hover {
  transition: all 0.5s linear;
  background-color: #ffffff;
  color: #000000;
  border-color: #000000;
}

.cancleSubmit {
  background-color: #0054a7;
  letter-spacing: 1px;
  padding: 0.5rem 1rem;
  border-radius: 10px;
}

.formcontentspan {
  color: rgb(238, 53, 53);
  letter-spacing: 6px;
}

.error {
  color: red;
  display: block;
  text-align: left;
  font-size: 0.875rem;
  padding: 5px 0 5px 5px;
  text-align: right;
}

.red {
  color: red;
}

.contactMainForm {
  max-width: 95%;
  margin: auto auto 5rem auto;
}
@media (max-width: 1440px) {
  .contactMainForm {
    max-width: 100%;
  }
}
.contactMainForm .contactSection {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contactMainForm .contactSection .container {
  display: flex;
  align-items: center;
  gap: 2.5rem;
  justify-content: flex-end;
  font-weight: 500;
  max-width: 100%;
}
@media (max-width: 1024px) {
  .contactMainForm .contactSection .container {
    flex-direction: column;
  }
}
.contactMainForm .contactSection .container .titleContainer {
  display: none;
}
@media (max-width: 1024px) {
  .contactMainForm .contactSection .container .titleContainer {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 480px) {
  .contactMainForm .contactSection .container .titleContainer {
    flex-direction: column;
  }
}
.contactMainForm .contactSection .container .titleContainer h4 {
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 1.5rem;
  font-weight: 900;
  color: #0054a7;
  letter-spacing: 1px;
}
.contactMainForm .contactSection .container .titleContainer span {
  display: block;
  color: #000000;
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-weight: 700;
}
.contactMainForm .contactSection .container .mainvIusalContainer {
  width: 45%;
}
@media (max-width: 1024px) {
  .contactMainForm .contactSection .container .mainvIusalContainer {
    width: 80%;
  }
}
.contactMainForm .contactSection .container .mainvIusalContainer .mainvIusal {
  padding: 45%;
  background-image: url("/src/frontImage/contact01.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 1024px) {
  .contactMainForm .contactSection .container .mainvIusalContainer .mainvIusal {
    background-image: url("/src/frontImage/contact02.png");
  }
}
.contactMainForm .contactSection .container .contactContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 55%;
}
@media (max-width: 1024px) {
  .contactMainForm .contactSection .container .contactContainer {
    width: 100%;
    align-items: center;
  }
}
.contactMainForm .contactSection .container .contactContainer .title {
  display: block;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 1px;
  position: relative;
  align-self: flex-start;
  text-align: center;
  margin-bottom: 1.5rem;
}
@media (max-width: 1024px) {
  .contactMainForm .contactSection .container .contactContainer .title {
    text-align: center;
    margin: 1.5rem auto;
  }
}
@media (max-width: 375px) {
  .contactMainForm .contactSection .container .contactContainer .title {
    font-size: 1.125rem;
  }
}

.form {
  max-width: 100%;
  width: 100%;
  margin: auto;
}
@media (max-width: 1440px) {
  .form {
    width: 100%;
  }
}
@media (max-width: 1240px) {
  .form {
    width: 100%;
  }
}
@media (max-width: 480px) {
  .form {
    width: 100%;
  }
}

.aboutHistory {
  margin-top: 2.5rem;
}
.aboutHistory .title {
  display: flex;
  align-items: flex-end;
  gap: 2vw;
  padding-right: 1rem;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  letter-spacing: 2px;
}
@media (max-width: 768px) {
  .aboutHistory .title {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 3rem;
  }
}
@media (max-width: 640px) {
  .aboutHistory .title {
    margin: auto;
  }
}
@media (max-width: 480px) {
  .aboutHistory .title::after {
    display: none;
  }
}
.aboutHistory .title::after {
  content: "";
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 3px;
  height: 70%;
  background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
}
.aboutHistory .title .titleEn {
  font-size: 1.25rem;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  color: #0054a7;
  position: relative;
}
.aboutHistory .title .titlech {
  font-size: 1.25rem;
  font-weight: 700;
  color: #000000;
}
@media (max-width: 480px) {
  .aboutHistory .title .titlech {
    position: relative;
    padding-right: 1rem;
  }
  .aboutHistory .title .titlech::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 3px;
    height: 70%;
    background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
  }
}
.aboutHistory .timeLineContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5vw;
  margin-bottom: 10vw;
}
.aboutHistory .timeLineContainer .container {
  display: flex;
  justify-content: space-between;
  padding-bottom: 2.25rem;
  cursor: pointer;
}
@media (max-width: 768px) {
  .aboutHistory .timeLineContainer .container {
    margin-top: 1rem;
    flex-direction: column;
    align-items: center;
  }
}
.aboutHistory .timeLineContainer .container:nth-last-child(1) .titleContainer::after {
  content: unset;
}
.aboutHistory .timeLineContainer .container .titleContainer {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 20%;
}
@media (max-width: 1240px) {
  .aboutHistory .timeLineContainer .container .titleContainer {
    width: 25%;
  }
}
@media (max-width: 768px) {
  .aboutHistory .timeLineContainer .container .titleContainer {
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    border-radius: 15px;
    width: unset;
    padding: 0.5rem 0;
  }
}
.aboutHistory .timeLineContainer .container .titleContainer::after {
  content: "";
  position: absolute;
  top: 2.25rem;
  height: 100%;
  width: 2px;
  background-color: #0054a7;
  border-radius: 15px;
  right: 1.05rem;
  z-index: -1;
}
@media (max-width: 768px) {
  .aboutHistory .timeLineContainer .container .titleContainer::after {
    content: unset;
  }
}
.aboutHistory .timeLineContainer .container .titleContainer .year {
  font-size: 2.25rem;
  letter-spacing: 2px;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  color: #0054a7;
  width: 7rem;
  transform: translateY(-0.65rem);
}
@media (max-width: 1024px) {
  .aboutHistory .timeLineContainer .container .titleContainer .year {
    font-size: 2.25rem;
  }
}
@media (max-width: 1024px) {
  .aboutHistory .timeLineContainer .container .titleContainer .year {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .aboutHistory .timeLineContainer .container .titleContainer .year {
    letter-spacing: 1px;
    width: unset;
    transform: unset;
  }
}
.aboutHistory .timeLineContainer .container .titleContainer .circle {
  display: block;
  width: 2.25rem;
  height: 2.25rem;
  border: 2px solid #076baf;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  background-image: url("/src/frontImage/vector.svg");
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(90deg);
}
@media (max-width: 480px) {
  .aboutHistory .timeLineContainer .container .titleContainer .circle {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.aboutHistory .timeLineContainer .container span {
  width: 80%;
}
@media (max-width: 1024px) {
  .aboutHistory .timeLineContainer .container span {
    width: 75%;
  }
}
@media (max-width: 480px) {
  .aboutHistory .timeLineContainer .container span {
    width: 100%;
  }
}
.aboutHistory .timeLineContainer .container span .content {
  display: flex;
  align-items: center;
  padding-left: 3.5vw;
}
@media (max-width: 1024px) {
  .aboutHistory .timeLineContainer .container span .content {
    flex-direction: column-reverse;
  }
}
@media (max-width: 768px) {
  .aboutHistory .timeLineContainer .container span .content {
    padding-left: unset;
    padding: 3rem 0;
  }
}
.aboutHistory .timeLineContainer .container span .content .contentContainer {
  align-self: flex-start;
  padding-right: 3vw;
  width: 60%;
  letter-spacing: 1px;
}
@media (max-width: 1024px) {
  .aboutHistory .timeLineContainer .container span .content .contentContainer {
    width: 100%;
  }
}
.aboutHistory .timeLineContainer .container span .content .contentContainer ul li {
  position: relative;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.aboutHistory .timeLineContainer .container span .content .contentContainer ul li::before {
  content: "";
  position: absolute;
  width: 0.5vw;
  height: 0.5vw;
  background-color: #000000;
  border-radius: 50%;
  left: 0;
  top: 0.5vw;
}
@media (max-width: 768px) {
  .aboutHistory .timeLineContainer .container span .content .contentContainer ul li::before {
    top: 0.3rem;
    width: 0.3rem;
    height: 0.3rem;
  }
}
.aboutHistory .timeLineContainer .container span .content .imgContainer {
  width: 40%;
  align-self: flex-start;
  border-radius: 15px;
}
@media (max-width: 1024px) {
  .aboutHistory .timeLineContainer .container span .content .imgContainer {
    margin-bottom: 2rem;
    width: 100%;
  }
}
.aboutHistory .timeLineContainer .container span .content .imgContainer .img {
  width: 18.2vw;
  height: 10.7vw;
  margin: auto;
}
@media (max-width: 1024px) {
  .aboutHistory .timeLineContainer .container span .content .imgContainer .img {
    width: 100%;
    height: 12.5rem;
  }
}
.aboutHistory .timeLineContainer .container span .content .imgContainer .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 15px;
}

.serviceSpaceeMain {
  margin-top: 3rem;
  margin-bottom: 10rem;
}
@media (max-width: 1240px) {
  .serviceSpaceeMain {
    margin-top: unset;
    margin-bottom: 8rem;
    align-items: center;
  }
}
@media (max-width: 640px) {
  .serviceSpaceeMain {
    margin-bottom: 5rem;
  }
}
.serviceSpaceeMain .container {
  display: flex;
  gap: 4rem;
  position: relative;
  align-items: center;
}
@media (max-width: 1536px) {
  .serviceSpaceeMain .container {
    gap: 2rem;
  }
}
@media (max-width: 1240px) {
  .serviceSpaceeMain .container {
    width: 70%;
    flex-direction: column-reverse;
    margin: auto;
    align-items: center;
    justify-content: center;
    gap: unset;
  }
}
@media (max-width: 768px) {
  .serviceSpaceeMain .container {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .serviceSpaceeMain .container {
    width: 100%;
    gap: 1.5rem;
  }
}
.serviceSpaceeMain .container .arrow {
  position: absolute;
  width: 3vw;
  background-repeat: no-repeat;
  bottom: -6.4vw;
  right: 3vw;
  z-index: 100;
  background-image: url("/src/frontImage/serviceSpacee04.svg");
}
.serviceSpaceeMain .container .content {
  width: 50%;
}
@media (max-width: 1240px) {
  .serviceSpaceeMain .container .content {
    width: 100%;
  }
}
.serviceSpaceeMain .container .content .contentContainer {
  display: flex;
  flex-direction: column;
}
.serviceSpaceeMain .container .content .contentContainer:nth-child(1) .contentList {
  position: relative;
  z-index: 10;
}
.serviceSpaceeMain .container .content .contentContainer:nth-child(1) .contentList::before {
  content: "";
  position: absolute;
  width: 72px;
  height: 132px;
  z-index: 5;
}
.serviceSpaceeMain .container .content .contentContainer:nth-last-child(1) {
  padding-top: 2rem;
  position: relative;
}
.serviceSpaceeMain .container .content .contentContainer:nth-last-child(1)::after {
  content: "";
  position: absolute;
  right: 0.15rem;
  top: -0.15rem;
  z-index: 5;
  background-image: url("/src/frontImage/grayVector.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top right;
  width: 4rem;
  height: 6rem;
  z-index: 10;
  animation: fadedown 2s linear 0s 1 normal both;
}
@keyframes fadedown {
  0% {
    top: 0;
  }
  100% {
    top: -0.15rem;
  }
}
@media (max-width: 375px) {
  .serviceSpaceeMain .container .content .contentContainer:nth-last-child(1)::after {
    content: unset;
  }
}
.serviceSpaceeMain .container .content .contentContainer:nth-last-child(1) .contentList {
  background-color: #076baf;
}
.serviceSpaceeMain .container .content .contentContainer:nth-last-child(1) .contentList li {
  color: #ffffff;
}
.serviceSpaceeMain .container .content .contentContainer:nth-last-child(1) .contentList li::before {
  content: "";
  background-image: url("/src/frontImage/servicespace02.svg");
}
.serviceSpaceeMain .container .content .contentContainer .title {
  margin-bottom: 1vw;
  padding-right: 1rem;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 2px;
}
.serviceSpaceeMain .container .content .contentContainer .title::after {
  content: "";
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 3px;
  height: 70%;
  background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
}
@media (max-width: 640px) {
  .serviceSpaceeMain .container .content .contentContainer .title {
    left: 1.5rem;
    margin-bottom: 1rem;
    max-width: 100%;
    font-size: 1.25rem;
  }
}
.serviceSpaceeMain .container .content .contentContainer .contentList {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  background-color: #f5f5f5;
  padding: 2vw 0.5vw 2vw 1vw;
  position: relative;
  border-radius: 30px;
}
@media (max-width: 1240px) {
  .serviceSpaceeMain .container .content .contentContainer .contentList {
    padding: 2rem 1.6rem 2.75rem 1.6rem;
  }
}
@media (max-width: 640px) {
  .serviceSpaceeMain .container .content .contentContainer .contentList {
    padding: 1.5rem;
    gap: 0.5rem;
  }
}
.serviceSpaceeMain .container .content .contentContainer .contentList li {
  font-size: 1.25rem;
  letter-spacing: 2px;
  font-weight: 700;
  color: #000000;
  position: relative;
  padding-left: 2.5rem;
}
@media (max-width: 640px) {
  .serviceSpaceeMain .container .content .contentContainer .contentList li {
    padding-left: 1.5rem;
    font-size: 1rem;
  }
}
.serviceSpaceeMain .container .content .contentContainer .contentList li::before {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 100%;
  top: 0;
  left: 0;
  background-image: url("/src/frontImage/servicespace01.svg");
  background-repeat: no-repeat;
  background-position: center;
}
@media (max-width: 640px) {
  .serviceSpaceeMain .container .content .contentContainer .contentList li::before {
    width: 1rem;
  }
}
.serviceSpaceeMain .container .content .contentContainer .contentList li span {
  display: block;
  width: 1.6vw;
  height: 1.6vw;
}
.serviceSpaceeMain .container .content .contentContainer .contentList li span img {
  width: 2vw;
  height: 2vw;
}
.serviceSpaceeMain .container .mainVisualImg {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 80%;
}
.serviceSpaceeMain .container .mainVisualImg img {
  -o-object-position: right center;
     object-position: right center;
}
@media (max-width: 1536px) {
  .serviceSpaceeMain .container .mainVisualImg {
    width: 55%;
  }
}
@media (max-width: 1024px) {
  .serviceSpaceeMain .container .mainVisualImg {
    width: 100%;
  }
}

.serviceSpaceSection {
  margin-top: 3vw;
}
@media (max-width: 1240px) {
  .serviceSpaceSection {
    margin-top: unset;
  }
}
.serviceSpaceSection .container {
  display: flex;
  gap: 7vw;
  margin-bottom: 11.97vw;
}
.serviceSpaceSection .container::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -7.5rem;
  width: 23px;
  height: 200px;
  z-index: -1;
  background-image: url("/src/frontImage/側邊裝飾.png");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 480px) {
  .serviceSpaceSection .container::after {
    content: unset;
  }
}
.serviceSpaceSection .container:nth-child(even)::after {
  bottom: -8rem;
  left: 0.25rem;
  right: unset;
}
.serviceSpaceSection .container:nth-last-child(1)::after {
  content: unset;
}
@media (max-width: 1240px) {
  .serviceSpaceSection .container {
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    margin-bottom: 10rem;
    gap: 5rem;
  }
}
@media (max-width: 640px) {
  .serviceSpaceSection .container {
    margin-bottom: 4rem;
  }
}
@media (max-width: 1240px) {
  .serviceSpaceSection .container:nth-child(odd) {
    flex-direction: column-reverse;
  }
}
.serviceSpaceSection .container:nth-child(odd) .slick-dots {
  right: unset !important;
  left: -30px !important;
}
@media (max-width: 640px) {
  .serviceSpaceSection .container:nth-child(odd) .slick-dots {
    right: 0;
    left: 0 !important;
  }
}
.serviceSpaceSection .container:nth-child(even) {
  flex-direction: row-reverse;
}
@media (max-width: 1240px) {
  .serviceSpaceSection .container:nth-child(even) {
    flex-direction: column-reverse;
  }
}
.serviceSpaceSection .container .content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}
@media (max-width: 1240px) {
  .serviceSpaceSection .container .content {
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.serviceSpaceSection .container .content .titleContainer {
  margin-bottom: 2.5rem;
}
.serviceSpaceSection .container .content .titleContainer .iconContainer {
  width: 4.4vw;
  height: 4.7vw;
}
@media (max-width: 1240px) {
  .serviceSpaceSection .container .content .titleContainer .iconContainer {
    width: 5.5rem;
    height: 5.5rem;
    margin: auto;
    padding-bottom: 1rem;
  }
}
.serviceSpaceSection .container .content .titleContainer .iconContainer img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
@media (max-width: 480px) {
  .serviceSpaceSection .container .content .titleContainer .iconContainer {
    width: 5.3rem;
    height: 5.7rem;
  }
}
.serviceSpaceSection .container .content .titleContainer h4 {
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 2px;
  color: #076baf;
}
.serviceSpaceSection .container .content .contentContainer {
  font-size: 1.125rem;
  letter-spacing: 1px;
  margin-bottom: 4rem;
  color: #000000;
}
@media (max-width: 1240px) {
  .serviceSpaceSection .container .content .contentContainer {
    width: 65%;
  }
}
@media (max-width: 640px) {
  .serviceSpaceSection .container .content .contentContainer {
    width: 80%;
  }
}
@media (max-width: 480px) {
  .serviceSpaceSection .container .content .contentContainer {
    width: 100%;
  }
}
.serviceSpaceSection .container .content .contentContainer .content {
  margin-bottom: 2.5rem;
}
.serviceSpaceSection .container .content .contentContainer .detail {
  word-break: break-all;
}
.serviceSpaceSection .container .content .linkContainer {
  justify-self: flex-end;
}
.serviceSpaceSection .container .carouselContainer {
  width: 35rem;
  height: 25rem;
  cursor: pointer;
  position: relative;
}
.serviceSpaceSection .container .carouselContainer img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.serviceSpaceSection .container .carouselContainer:hover > .homeNewImgTitle {
  height: 100%;
  width: 100%;
}
@media (max-width: 1440px) {
  .serviceSpaceSection .container .carouselContainer {
    align-self: center;
  }
}
@media (max-width: 1240px) {
  .serviceSpaceSection .container .carouselContainer {
    width: 468px;
    height: 340px;
  }
}
@media (max-width: 640px) {
  .serviceSpaceSection .container .carouselContainer {
    width: 100%;
    height: auto;
  }
}
.serviceSpaceSection .container .carouselContainer > .slick-list {
  border-radius: 15px;
}
.serviceSpaceSection .container .slick-initialized .slick-slide {
  position: relative;
}
.serviceSpaceSection .container .slick-dots {
  bottom: 0px !important;
  width: 0.72vw !important;
  right: -25px !important;
  left: unset !important;
  display: grid !important;
  gap: 0.5vw;
}
@media (max-width: 640px) {
  .serviceSpaceSection .container .slick-dots {
    bottom: -35px !important;
    width: 100% !important;
    right: 0 !important;
    left: 0 !important;
    display: block !important;
    gap: 0.5vw;
  }
}
.serviceSpaceSection .container .slick-dots li {
  margin: 0px !important;
}
.serviceSpaceSection .container .slick-dots li button {
  width: 0.72vw !important;
  height: 0.72vw !important;
  border-radius: 50%;
  border: 1px solid #076baf !important;
  position: relative;
}
.serviceSpaceSection .container .slick-dots li button:hover::before {
  background-color: #076baf;
}
.serviceSpaceSection .container .slick-dots li button::before {
  content: "";
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  font-size: 0 !important;
}
.serviceSpaceSection .container .slick-dots .slick-active button {
  background-color: #076baf !important;
}
.serviceSpaceSection .container .slick-dots .slick-active button:hover::before {
  background-color: #076baf;
}
.serviceSpaceSection .container .slick-dots .slick-active button:before {
  font-size: 20px;
  left: 0;
  right: 0;
  border-radius: 50%;
  opacity: 1 !important;
  color: #076baf !important;
}

.newsSection {
  margin-top: 5vw;
  max-width: 70%;
}
@media (max-width: 1024px) {
  .newsSection {
    max-width: 760px;
    margin: auto;
  }
}
.newsSection .titleContainer {
  gap: 1rem;
  margin-bottom: 2rem;
}
@media (max-width: 1024px) {
  .newsSection .titleContainer {
    flex-direction: column;
  }
}
.newsSection .titleContainer .title {
  font-size: 1.5rem;
  letter-spacing: 1px;
  font-weight: 500;
}
.newsSection .titleContainer .shareContainer {
  display: flex;
  justify-content: space-between;
}
.newsSection .titleContainer .shareContainer .social {
  margin: 0.5rem 0rem 0 0;
  display: flex;
  gap: 0.51rem;
}
@media (max-width: 1024px) {
  .newsSection .titleContainer .shareContainer .social {
    display: none;
  }
}
@media (max-width: 480px) {
  .newsSection .titleContainer .shareContainer .social {
    gap: 1rem;
  }
}
.newsSection .titleContainer .shareContainer .social li {
  width: 3.25em;
  height: 3.25rem;
  border-radius: 50%;
}
.newsSection .titleContainer .shareContainer .social li .socialLink {
  position: relative;
  display: block;
  width: 2.8rem;
  height: 2.8rem;
}
.newsSection .titleContainer .shareContainer .social li .socialLink img {
  width: 1000%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.newsSection .titleContainer .shareContainer .social li .socialLink::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 1px solid #0054a7;
  top: 0;
  width: 100%;
  height: 100%;
}
.newsSection .titleContainer .shareContainer .social {
  display: flex;
}
.newsSection .titleContainer .shareContainer .date {
  font-size: 1.25rem;
  letter-spacing: 1px;
  margin: 1rem 0;
  display: block;
}
.newsSection .newsInsiderContent {
  width: 100%;
  margin: auto;
}
.newsSection .newsInsiderContent div img {
  width: 100%;
  height: auto;
  margin: auto;
}
.newsSection .newsInsiderContent div p {
  line-height: 200%;
  word-break: break-word;
}
.newsSection .newsInsiderContent div figure {
  width: -moz-fit-content !important;
  width: fit-content !important;
  max-width: 760px;
  margin: 0 auto;
  clear: both;
}
.newsSection .newsInsiderContent div figure table {
  margin: auto;
}
.newsSection .newsInsiderContent div figure img {
  max-width: 760px;
  width: 100%;
  height: auto;
}
.newsSection .newsInsiderContent div a {
  width: 100%;
  max-width: 760px;
  margin: 0 auto;
}
.newsSection .newsInsiderContent div a img {
  max-width: 760px;
  width: 100%;
  height: auto;
}
.newsSection .newsInsiderContent div table,
.newsSection .newsInsiderContent div thead,
.newsSection .newsInsiderContent div tbody,
.newsSection .newsInsiderContent div td,
.newsSection .newsInsiderContent div tr {
  margin: 0 auto;
}

.newsInsidernav {
  height: auto;
  position: relative;
  margin: 40px auto 0;
  padding-bottom: 80px;
}
.newsInsidernav .newsInsidernavArrowleft {
  position: absolute;
  left: 0;
  cursor: pointer;
}
.newsInsidernav .newsInsidernavArrowleft::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
  background-image: url("/src/frontImage/newsbtn.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
@media (max-width: 480px) {
  .newsInsidernav .newsInsidernavArrowleft::before {
    width: 1rem;
    height: 1rem;
  }
}
.newsInsidernav .newsInsidernavArrowleft .newsInsidernavPrev {
  padding: 0 0 0 3rem;
  position: relative;
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
}
.newsInsidernav .newsInsidernavArrowleft .newsInsidernavPrev .newsInsidernavlabel {
  font-size: 1.125rem;
  color: #0054a7;
  display: block;
  font-weight: 700;
}
.newsInsidernav .newsInsidernavArrowRight {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.newsInsidernav .newsInsidernavArrowRight:hover {
  animation: hoverblink 0.3s linear 0s 1 normal both;
}
.newsInsidernav .newsInsidernavArrowRight::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  background-image: url("/src/frontImage/newsbtnright.svg");
  background-repeat: no-repeat;
  background-size: contain;
}
.newsInsidernav .newsInsidernavArrowRight .newsInsidernavNext {
  padding: 0 3rem 0 0;
  position: relative;
  display: inline-block;
  width: -moz-fit-content;
  width: fit-content;
}
.newsInsidernav .newsInsidernavArrowRight .newsInsidernavNext .newsInsidernavlabel {
  font-size: 1.125rem;
  color: #0054a7;
  display: block;
  font-weight: 700;
  text-align: right;
}
.newsInsidernav .newsInsidernavArrowRight .newsInsidernavNext .newsInsidernavDate {
  line-height: 20px;
  color: #b2b2b2;
  margin-top: 2px;
}

a[title]:hover::after {
  content: attr(title);
  color: #0054a7;
  font-size: 1rem;
  padding-top: 0.5rem;
  line-height: 200%;
}

.newsNav {
  position: absolute;
  right: 3.4rem;
  top: 30rem;
  max-width: 20%;
}
@media (max-width: 1024px) {
  .newsNav {
    display: none;
  }
}
.newsNav .titleContainer {
  border-radius: 10px;
  border: 1px solid #0054a7;
  text-align: center;
  padding: 0.5rem;
  margin-bottom: 3rem;
  font-size: 1.25rem;
}
.newsNav .titleContainer .title {
  color: #0054a7;
}
.newsNav .container .containerCard {
  position: relative;
}
.newsNav .container .containerCard:hover .pinImage {
  transition: all 0.5s linear;
  background-image: url("/src/frontImage/pinafter.png");
}
.newsNav .container .containerCard .pinImage {
  position: absolute;
  top: 0;
  right: 0.5rem;
  width: 1vw;
  height: 2vw;
  background-image: url("/src/frontImage/pinbefore.png");
  background-size: contain;
  background-repeat: no-repeat;
}
@media (max-width: 1024px) {
  .newsNav .container .containerCard .pinImage {
    width: 3rem;
    height: 4rem;
  }
}
.newsNav .container .containerCard .contentContainer {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin: 1.25rem 0;
  border-radius: 10px;
}
.newsNav .container .containerCard .contentContainer:hover {
  transition: all 0.5s linear;
  background-color: #0054a7;
  color: #ffffff;
}
.newsNav .container .containerCard .contentContainer:hover .content .title {
  color: #ffffff;
}
@media (max-width: 1240px) {
  .newsNav .container .containerCard .contentContainer {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.newsNav .container .containerCard .contentContainer .imgContainer {
  width: 5.875rem;
  height: 5.875rem;
  border-radius: 10px;
}
.newsNav .container .containerCard .contentContainer .imgContainer img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.newsNav .container .containerCard .contentContainer .content {
  max-width: 45%;
}
.newsNav .container .containerCard .contentContainer .content .title {
  display: flex;
  color: #0054a7;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  letter-spacing: 1px;
}
@media (max-width: 1240px) {
  .newsNav .container .containerCard .contentContainer .content .title {
    justify-content: center;
  }
}
.newsNav .container .containerCard .contentContainer .content .descruption {
  font-size: 0.875rem;
}

.awardSection {
  margin: 7rem 0;
  display: flex;
  justify-content: center;
}
@media (max-width: 640px) {
  .awardSection {
    margin-top: 3rem;
  }
}
.awardSection .container {
  display: flex;
  flex-direction: column;
}
.awardSection .container .card:nth-last-child(1) .lineDeca {
  display: none;
}
.awardSection .container .card .contentContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 95%;
  align-self: flex-end;
  margin: auto;
  position: relative;
}
.awardSection .container .card .contentContainer::after {
  content: "";
  background-color: #f5f5f5;
  width: 90%;
  height: 80%;
  position: absolute;
  z-index: -1;
  border-radius: 15px 0 0 15px;
}
@media (max-width: 1024px) {
  .awardSection .container .card .contentContainer::after {
    display: none;
  }
}
@media (max-width: 1024px) {
  .awardSection .container .card .contentContainer {
    width: 70%;
    align-self: center;
  }
}
@media (max-width: 480px) {
  .awardSection .container .card .contentContainer {
    width: 100%;
  }
}
.awardSection .container .card .contentContainer:nth-last-child(1)::after {
  content: unset;
}
.awardSection .container .card .contentContainer:hover::after {
  transition: all 0.3s linear;
  background-color: #0054a7;
  color: #ffffff;
}
.awardSection .container .card .contentContainer:hover .content {
  transition: all 0.3s linear;
  color: #ffffff;
}
.awardSection .container .card .contentContainer:hover .content .year {
  transition: all 0.3s linear;
  color: #ffffff;
}
@media (max-width: 1024px) {
  .awardSection .container .card .contentContainer:hover .content {
    transition: all 0.3s linear;
    background-color: #0054a7;
    color: #ffffff;
  }
  .awardSection .container .card .contentContainer:hover .content .year {
    transition: all 0.3s linear;
    color: #ffffff;
  }
}
.awardSection .container .card .contentContainer .content {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding-right: 3rem;
  padding: 2rem;
  width: 95%;
  align-items: center;
}
@media (max-width: 1024px) {
  .awardSection .container .card .contentContainer .content {
    flex-direction: column;
    padding: 2rem 0;
    width: 90%;
    gap: unset;
    background-color: #f5f5f5;
    margin-bottom: 0.5rem;
    border-radius: 15px;
  }
}
@media (max-width: 640px) {
  .awardSection .container .card .contentContainer .content {
    padding-right: 0;
    width: 100%;
  }
}
.awardSection .container .card .contentContainer .content .year {
  padding-right: 3rem;
  color: #0054a7;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 2.25rem;
  position: relative;
}
@media (max-width: 1024px) {
  .awardSection .container .card .contentContainer .content .year {
    font-size: 2.25rem;
  }
}
@media (max-width: 640px) {
  .awardSection .container .card .contentContainer .content .year {
    padding: unset;
  }
}
.awardSection .container .card .contentContainer .content .year::after {
  content: "";
  position: absolute;
  width: 1.5rem;
  height: 2rem;
  background-image: url("/src/frontImage/vector_yellow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
@media (max-width: 640px) {
  .awardSection .container .card .contentContainer .content .year::after {
    display: none;
  }
}
.awardSection .container .card .contentContainer .content .detail {
  width: 90%;
}
.awardSection .container .card .contentContainer .content .detail p {
  width: 90%;
  font-size: 1.125rem;
  font-weight: 500;
  padding-right: 3rem;
  letter-spacing: 2px;
}
@media (max-width: 1024px) {
  .awardSection .container .card .contentContainer .content .detail p {
    padding-right: unset;
    padding: 1.5rem;
  }
}
@media (max-width: 1240px) {
  .awardSection .container .card .contentContainer .content .detail p {
    padding-right: 0;
  }
}
@media (max-width: 640px) {
  .awardSection .container .card .contentContainer .content .detail p {
    width: 100%;
    padding: 1rem;
  }
}
.awardSection .container .card .contentContainer .imgContainer {
  width: 25rem;
  height: 12rem;
}
@media (max-width: 1024px) {
  .awardSection .container .card .contentContainer .imgContainer {
    width: 90%;
    height: 15.5rem;
  }
}
@media (max-width: 640px) {
  .awardSection .container .card .contentContainer .imgContainer {
    width: 100%;
  }
}
.awardSection .container .card .contentContainer .imgContainer img {
  border-radius: 15px;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1024px) {
  .awardSection .container .card .contentContainer {
    flex-direction: column;
  }
}
@media (max-width: 640px) {
  .awardSection .container .card .contentContainer {
    flex-direction: column;
    gap: 1rem;
  }
}
.awardSection .container .card .lineDeca {
  display: block;
  height: 5rem;
  margin: 2rem;
  background-repeat: no-repeat;
  background-image: url("/src/frontImage/awardSectionLine.png");
}
@media (max-width: 1024px) {
  .awardSection .container .card .lineDeca {
    background-position: center;
  }
}

.notFound {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 8rem auto auto auto;
  width: 100%;
}
.notFound .imgContainer {
  background-image: url("/src/frontImage/notfound.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 90%;
  height: 70vh;
  background-size: contain;
  margin: 3% auto;
}
@media (max-width: 640px) {
  .notFound .imgContainer {
    height: 50%;
  }
}
.notFound .titleContainer {
  align-items: center;
  justify-content: center;
  gap: 5rem;
  width: 90%;
  margin: auto;
}
@media (max-width: 1024px) {
  .notFound .titleContainer {
    flex-direction: column;
    gap: 3rem;
  }
}
.notFound .titleContainer h4 {
  letter-spacing: 1px;
  font-size: 1.5rem;
  font-weight: 700;
  font-style: italic;
}
.notFound .titleContainer .linkContainer:hover a {
  transition: all 0.3s linear;
  background-color: #ffffff;
  border: 2px solid #076baf;
}
.notFound .titleContainer .linkContainer:hover a span {
  color: #076baf;
}
.notFound .titleContainer .linkContainer:hover a span::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("/src/frontImage/vector.svg");
  background-repeat: no-repeat;
  background-position: right;
}
.notFound .titleContainer .linkContainer a {
  display: block;
  padding: 1rem 1rem 1rem 3rem;
  background-color: #076baf;
  border: 2px solid #076baf;
  border-radius: 15px;
}
.notFound .titleContainer .linkContainer a span {
  color: #ffffff;
  font-weight: 700;
  position: relative;
  left: -0.75rem;
  padding-right: 2rem;
}
.notFound .titleContainer .linkContainer a span::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 1.25rem;
  height: 1.25rem;
  background-image: url("/src/frontImage/vectorWhite.svg");
  background-repeat: no-repeat;
  background-position: right;
}

.policy {
  padding-bottom: 8rem;
  width: 80%;
  margin: auto;
  padding-top: 10rem;
}
.policy .titleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  margin-bottom: 6rem;
  gap: 1.5rem;
}
@media (max-width: 1024px) {
  .policy .titleContainer {
    flex-direction: column;
  }
}
.policy .titleContainer h4 {
  color: #0054a7;
  font-family: "Inter", sans-serif;
  font-weight: 300;
  font-size: 2.25rem;
}
@media (max-width: 1024px) {
  .policy .titleContainer h4 {
    font-size: 2.25rem;
  }
}
.policy .titleContainer span {
  align-self: flex-end;
  font-size: 1.25rem;
  transform: translateY(-0.5rem);
  font-weight: 700;
}
@media (max-width: 1024px) {
  .policy .titleContainer span {
    transform: unset;
    align-self: center;
  }
}
.policy .contentContainer .container {
  letter-spacing: 2px;
  margin-bottom: 5rem;
}
.policy .contentContainer .container:nth-child(1) li::after {
  content: "";
  position: absolute;
  left: 0;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #000000;
  border-radius: 30%;
  top: 0;
  transform: translateY(90%);
}
.policy .contentContainer .container:nth-child(2) li::after {
  content: "";
  position: absolute;
  left: 0;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #000000;
  border-radius: 30%;
  top: 0;
  transform: translateY(90%);
}
.policy .contentContainer .container h5 {
  color: #0054a7;
  margin-bottom: 2rem;
  padding-right: 1.5rem;
  position: relative;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.5rem;
  font-weight: 700;
}
.policy .contentContainer .container h5::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 3px;
  height: 70%;
  background: linear-gradient(180deg, #ffd600 26.57%, #ff8a00 100%);
}
.policy .contentContainer .container p {
  margin-bottom: 1.5rem;
  font-weight: 500;
}
.policy .contentContainer .container li {
  padding-left: 1rem;
  margin-bottom: 1rem;
  position: relative;
}/*# sourceMappingURL=all.css.map */