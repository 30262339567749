.careervisual{
    @include arrowguide;
    &::after {
        right:0rem;
        bottom: -5rem;
    }
}
.visualContainer {
    margin-top: 2rem;
    @include largeWeb {
        gap: 2.5rem;
    }
    @include maxWeb {
        gap: 1rem;
        flex-direction: column-reverse;
        margin-bottom: 5rem;
    }
  
    .visualContent {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 35%;
        padding-top: 3rem;
        @include largeWeb {
            width: 45%;
        }
        @include maxWeb {
            gap: 2rem;
            width: 100%;
            margin-bottom: 6rem;
            padding-top: 3rem;
        }

        .visualTitleContainer {
            display: flex;
            position: relative;
            gap: 1rem;
            align-items: flex-end;
            flex-wrap: wrap;
            @include maxWeb {
                text-align: center;
                align-items: center;
                justify-content: center;
            }
            @include biggerMobile{
               flex-direction: column;
            }
            .enTitle {
                @include fontBiggestTitle;
                @include enFont;
                color: $colorA;
                @include letterSpacingB;
            }
            .visualTitle {
                @include fontrdtitle;
                color: $colorC;
                font-weight: $fontWeightB;
                padding-bottom: 0.5rem;
                @include maxWeb {
                    transform: translateY(5px);
                }
            }
        }
        .subTitle {
            display: block;
            color: $colorA;
            @include fonSectitle;
            font-weight: $fontWeightB;
            @include letterSpacingC;
            @include maxWeb {
                text-align: center;
            }
            @include smallerMobile {
                @include letterSpacingA;
            }
        }
        p {
            @include fontrdtitle;
            font-weight: $fontWeightA;
            color: $colorC;
            @include letterSpacingB;
            margin: auto;
            word-break: break-all;
            @include maxWeb {
                width: 80%;
            }
            @include pad {
                width: 55%;
            }

            @include biggerMobile {
                width: 100%;
            }
        }
    }
}
.visualImg {
  
    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}