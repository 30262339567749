@mixin itemDecor {
    &::after {
        content: ">";
        position: absolute;
        @include fontSecContent;
        top: 50%;
        right:-3px;
        transform: translateY(-50%);
       
    }
    &:nth-last-child(1):after {
       content: unset;
    }
}
.breadcrumbs {
    margin-bottom: 1.5rem;
    ol {
        display: flex;
        position: relative;
        margin-left: 0.5rem;
        align-items: center;
        
        &:after {
            content: "";
            width: .3rem;
            height: .3rem;
            border-radius: 50%;
            background-color: $colorC;
            position: absolute;
            left: -0.5vw;
            top: 50%;
            transform: translateY(-50%);
           
        }
        li {
            position: relative;
            @include itemDecor;
                span {
                    display: block;
                    @include fontSecContent;
                    padding: 0 1rem ;
                    @include mobile{
                        display: block;
                        padding:0 .8rem;
                    }
                }
        }
    }
}
