@mixin cardsecStyle {
    font-weight: $fontWeightA;
    @include letterSpacingB;
    background-color: $colorG;
    @include biggerMobile {
        border-radius: 10px;
        padding: 1.25rem;
    }
}
@mixin cardStyle {
    font-weight: $fontWeightA;
    @include letterSpacingB;
    background-color: $colorG;
    padding: 2.6vw 3.5vw 1.5vw 3.5vw;
    @include biggerMobile {
        border-radius: 10px;
        padding: 1.25rem;
    }
}
.hireMainSection {
    position: relative;
        @include arrowguide;
        &::after {
            right: 0;
            bottom: 20%;
        }
    .container {
        max-width: 100%;
        
        @include pad {
            width: 90%;
            margin: auto;
            margin-bottom: 7.75rem;
        }
        @include mobile {
            width: 100%;
        }

        .titleContainer {
            display: flex;
            position: relative;
            @include fonSectitle;

            @include letterSpacingA;

            width: fit-content;
            padding-right: 1rem;
            gap: 1vw;
            margin-bottom: 2rem;
            @include mobile {
                margin-bottom: 2.75rem;
                flex-wrap: wrap;
            }

            span {
                color: $colorA;
                @include enFont;
            }
            h4 {
                font-weight: $fontWeightB;
            }
            &::after {
                @include yellowDirection;
            }
            @include pad {
                margin: auto;
                margin-bottom: 2rem;
            }
        }
        .content {
            display: grid;
            grid-template-columns: auto auto auto;
            gap: 1.09vw 1.56vw;

            align-items: center;
            @include maxWeb {
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
                gap: 1.35rem;
            }
            @include mobile {
                grid-template-columns: repeat(1, 1fr);
            }
            .contentContainer {
                position: relative;
                cursor: pointer;
                text-align: center;
                .contenFirst {
                    @include cardStyle;

                    .imgcontainer {
                        width: 14vw;
                        height: 8vw;
                        margin: auto;

                        @include pad {
                            width: 17rem;
                            height: 9.5rem;
                        }
                        @include mobile {
                            width: 10rem;
                        }
                        img {
                            height: 100%;
                            width: 100%;
                            object-fit: contain;
                        }
                    }

                    h4 {
                        margin-top: 1.5vw;
                        font-weight: $fontWeightB;
                        @include biggerMobile {
                            margin-top: 0.5rem;
                        }
                    }
                    span {
                        color: $colorH;
                        @include enFont;
                    }
                    p {
                        display: none;
                    }
                }

                //都是字的，滑入進去的才是絕對定位，滑入opacity=1，預設為0
                .contentSec {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-color: rgb(255, 238, 0);
                    top: 0;
                    left: 0;
                    opacity: 0;
                    transition: all 0.5s;
                    @include cardsecStyle;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    &:hover {
                        opacity: 1;
                    }
                    h4 {
                        font-weight: $fontWeightB;
                        @include mobile {
                            @include fontrdtitle;
                        }
                    }
                    span {
                        color: $colorH;
                        margin-bottom: 1.5vw;
                        @include enFont;
                        @include biggerMobile {
                            margin-bottom: 1rem;
                        }
                        @include mobile {
                            @include fontrdtitle;
                        }
                    }
                    p {
                        margin-bottom: 0.7vw;
                        font-weight: $fontWeightB;
                        @include biggerMobile {
                            margin-bottom: 0.8rem;
                        }
                    }
                }
            }
        }
    }
}
// 打字
@keyframes blink {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
//
.secContainer {
    margin-top: 14rem;
    margin-bottom: 26rem;
    display: flex;
    padding: 0;
    
    @include maxWeb {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: auto;
        margin-bottom: 12.5rem;
        margin-top: 7.75rem;
    }
    @include mobile {
        width: 100%;
    }
    .content {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include maxWeb {
            width: 65%;
            margin-bottom: 5rem;
            align-items: center;
        }
        @include smallerPad {
            width: 85%;
        }
        @include mobile {
            width: 100%;
        }
        h2 {
            color: $colorH;
            @include fontitle;
            @include largeWeb {
                @include fonSectitle;
            }
            @include letterSpacingB;
            font-weight: $fontWeightB;
            // flex-grow: 1;
            margin-bottom: 2rem;
        }
        .contentContainer {
            display: flex;
            flex-direction: column;
            gap: 1.25rem;
            @include fontrdtitle;
            @include largeWeb {
                @include fontContent;
            }
            font-weight: $fontWeightB;
            @include letterSpacingB;
            padding-right: 2vw;
            @include maxWeb {
                padding-right: unset;
            }
            p {
                font-weight: $fontWeightA;
            }
        }

        .linkContainer {
            display: flex;
            gap: 1vw;
            flex-grow: 2;
            align-items: flex-end;
            margin-top: 1.5rem;

            @include maxWeb {
                gap: 2rem;
                margin-top: 5rem;
            }
            a {
                width: 4rem;
            }
        }
    }
    .mainVisual {
        width: 60%;
        display: flex;
        align-items: center;
        @include maxWeb {
            width: 80%;
        }
        @include smallerPad {
            width: 90%;
        }
        @include mobile {
            width: 100%;
        }
        @include opacityAnimate;
    }
}
/* 產生游標閃爍的效果 */
// .typing {
//     .typingelm {
//         height: 400px;
//         padding: 10px;
//         font-size: 28px;
//         border-radius: 20px;
//         background-color: antiquewhite;
//         &::after {
//             content: "|";
//             color: rgb(17, 16, 16);
//             animation: blink 1s infinite;
//         }
//     }
// }
