.contactMainForm {
    max-width: 95%;
    margin: auto auto 5rem auto;
    @include largeWeb{
        max-width: 100%;
    }
    .contactSection {
        display: flex;
        justify-content: center;
        align-items: center;
        .container {
            display: flex;
            align-items: center;
            gap: 2.5rem;
            justify-content: flex-end;
            font-weight: $fontWeightA;
            max-width: 100%;
            @include pad {
                flex-direction: column;
            }
            .titleContainer {
                display: none;
                @include pad {
                    display: flex;
                    gap: 1rem;
                    justify-content: center;
                    align-items: center;
                }
                @include mobile {
                    flex-direction: column;
                }
                h4 {
                    @include enFont;
                    @include fontitle;
                    font-weight: $fontWeightC;
                    color: $colorA;
                    @include letterSpacingB;
                }
                span {
                    display: block;
                    color: $colorC;
                    @include fontitle;
                    @include letterSpacingB;
                    font-weight: $fontWeightB;
                }
            }
            $mainvIusalWidth:45%;
            .mainvIusalContainer{
                width: $mainvIusalWidth;
                @include pad{
                    width: 80%;
                }
                .mainvIusal {
                    padding: 45%;
                    background-image: url("/src/frontImage/contact01.png");
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    @include pad {
                        background-image: url("/src/frontImage/contact02.png");
                    }
                }
            }
            .contactContainer {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: calc(100% - $mainvIusalWidth);
                @include pad {
                    width: 100%;
                    align-items: center;
                }
                .title {
                    display: block;
                    font-weight: $fontWeightB;
                    @include fontitle;
                    @include letterSpacingB;
                    position: relative;
                    align-self: flex-start;
                    text-align: center;
                    margin-bottom: 1.5rem;
                    @include pad {
                        text-align: center;
                        margin: 1.5rem auto;
                    }
                    @include smallerMobile {
                        @include fontrdtitle;
                    }
                }
            }
        }
    }
}
.form {
    max-width: 100%;
    width: 100%;
    margin: auto;
    @include largeWeb {
        width: 100%;
    }
    @include maxWeb {
        width: 100%;
    }
    @include mobile {
        width: 100%;
    }
}
