.aboutCopo {
    margin-top: 3.33vw;
    margin-bottom: 6.9vw;
    @include pad {
        margin-top: 7.5rem;
        margin-bottom: 9.7rem;
    }
    @include mobile {
        margin-top: 3rem;
    }
    .titleContainer {
        display: flex;
        align-items: center;
        gap: 1.5vw;
        padding-right: 1.66vw;
        width: fit-content;
        position: relative;
        margin-bottom: 3.125vw;
        @include pad {
            margin: auto;
            margin-bottom: 4.8rem;
            gap: 1.625rem;
            padding-right: 1.625rem;
            flex-wrap: wrap;
        }
        @include biggerMobile {
            flex-wrap: wrap;
            justify-content: space-around;
        }
        &::after {
            @include yellowDirection;
            @include biggerMobile {
                display: none;
            }
        }
        span {
            color: $colorA;
            @include letterSpacingA;
            @include fontitle;
            @include enFont;
        }
        h3 {
            color: $colorC;
            font-weight: $fontWeightB;
            @include letterSpacingA;
            @include fontitle;
            position: relative;
            @include mobile {
                padding-right: 1rem;
                &::after {
                    @include yellowDirection;
                }
            }
        }
    }
    .contentContainer {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 3.43vw;
        align-items: center;
        justify-items: center;
        @include web {
            grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
            gap: 2rem;
        }
        @include pad {
            gap: 3.5rem;
        }
        @include mobile {
            grid-template-columns: repeat(1, 1fr);
            gap: 2rem;
        }
        .imgContainer {
            max-width: 18rem;
            max-height: 7rem;
            height: 7rem;
            margin: auto;
            display: block;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 10;
                background-color: $colorB;
                z-index: -1;
            }
            &:hover {
                &::after {
                    transition: all 1s linear;
                    opacity: 0.5;
                    z-index: 5;
                }
            }
            img {
                object-fit: contain;
                height: 100%;
                width: 100%;
                margin: auto;
            }
        }
    }
}
