.newsMainCard {
    .container {
        display: flex;
        flex-direction: column;
        gap: 2vw;
        @include pad {
            max-width: 100%;
        }

        @include biggerMobile {
            gap: unset;
        }
        .bookmarkContainer {
            display: none;
            @include pad {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $colorJ;
                @include borderRadiusA;
                cursor: pointer;
                position: relative;
                .focustyle {
                    background-color: $colorA;
                    color: $colorB;
                }
                .first {
                    border-radius: $borderRadiusA 0 0 $borderRadiusA;
                    position: relative;
                    border-right: 2px solid $colorF;
                    &:hover {
                        transition: all 0.5s linear;
                        background-color: $colorA;
                        color: $colorB;
                        border-color: $colorA;
                    }
                }
                .second {
                    border-radius: 0 $borderRadiusA $borderRadiusA 0;
                    border-left: 2px solid $colorF;
                    &:hover {
                        transition: all 0.5s linear;
                        background-color: $colorA;
                        color: $colorB;
                    }
                }
            }
            .bookmark {
                @include fontitle;
                font-weight: $fontWeightB;
                padding: 1rem;
                width: 50%;
                text-align: center;
                @include mobile {
                    @include fonSectitle;
                }
            }
            .bookMarklabel {
                background-color: $colorC;
                width: 3px;
                height: 2.5rem;
                position: relative;
                display: block;
            }
        }
        .contentContainer {
            width: 95%;
            @include pad {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 2rem;
                margin: 0 auto;
            }
            @include smallerPad {
                grid-template-columns: 1fr;
                margin-top: 1.5rem;
                gap: unset;
            }
            .containerCard {
                display: flex;
                align-items: center;
                position: relative;
                justify-content: center;
                margin-bottom: 3rem;
                color: $colorC;
                margin-top: 2rem;
                border-radius: $borderRadiusA;
                &:hover {
                    img {
                        transition: all 1s linear;
                        transform: scale(1.1);
                    }
                }
                &:hover {
                    .pinImage {
                        transition: all 0.5s linear;
                        background-image: url("/src/frontImage/pinafter.png");
                    }
                }
                .pinImage {
                    position: absolute;
                    top: 0;
                    right: 1.5rem;
                    width: 2vw;
                    height: 3vw;
                    background-image: url("/src/frontImage/pinbefore.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                    z-index: 5;
                    @include pad {
                        width: 3rem;
                        height: 4rem;
                    }
                    @include mobile {
                        width: 1.5rem;
                        height: 2.5rem;
                    }
                }
                &::after {
                    content: "";
                    background-color: $colorG;
                    width: 70%;
                    height: 100%;
                    position: absolute;
                    z-index: -1;
                    right: 0;
                    border-radius: $borderRadiusA;
                    @include pad {
                        content: "";
                        background-color: $colorG;
                        width: 100%;
                        height: 95%;
                        position: absolute;
                        z-index: -1;
                        right: 0;
                        bottom: 0;
                        border-radius: $borderRadiusA;
                    }
                }
                @include pad {
                    display: block;
                    margin-bottom: unset;
                    flex-direction: column;
                }
                &:hover {
                    transition: all 0.5s linear;
                    color: $colorB;
                    &::after {
                        transition: all 0.5s linear;
                        background-color: $colorA;
                    }
                }
                @include smallerMobile {
                    padding: unset;
                }
                .imgContainer {
                    position: relative;
                    overflow: hidden;
                    padding-left: 1rem;
                    width: 25vw;
                    height: 15vw;
                    border-radius: $borderRadiusA;
                    @include largeWeb {
                        height: 12rem;
                    }
                    @include pad {
                        width: 100%;
                        height: 15rem;
                        padding: unset;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: $borderRadiusA;
                        position: absolute;
                    }
                }
                .content {
                    width: 70%;
                    padding: 3rem 1rem 3rem 1rem;
                    position: relative;
                    @include letterSpacingB;
                    @include borderRadiusA;
                    @include largeWeb {
                        width: 60%;
                    }
                    @include pad {
                        width: 100%;
                    }
                    h3 {
                        @include fontitle;
                        font-weight: $fontWeightB;
                        margin-bottom: 0.5vw;

                        @include largeWeb {
                            @include fontrdtitle;
                        }
                        @include pad {
                            @include fonSectitle;
                        }
                        @include biggerMobile {
                            margin-bottom: 1rem;
                        }
                        @include smallerPad {
                            @include fontrdtitle;
                        }
                    }
                    span {
                        display: block;
                        @include fontrdtitle;
                    }
                    p {
                        margin-top: 0.5vw;
                        @include fontrdtitle;
                        @include biggerMobile {
                            margin-bottom: 1.5rem;
                        }
                        // special font
                        @include smallerPad {
                            font-size: 1rem;
                        }
                        @include smallerMobile {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
