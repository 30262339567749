@mixin HomeAboutIcon {
    width: 4.5rem;
}
.HomeAbout {
    margin: 10rem auto;
    @include biggerMobile {
        margin: unset;
    }
    //第一層區塊
    .HomeAboutontainer {
        margin: 8rem 0;
        padding-left: 11vw;
        display: flex;
        justify-content: space-between;
        gap: 4vw;
        @include maxWeb {
            flex-direction: column-reverse;
            padding-left: 0;
            justify-content: center;
            gap: 5.3rem;
        }
        @include biggerMobile {
            margin: 5rem 0 1rem 0;
        }
        @include pad {
            position: relative;
            @include arrowguide;
            &::after {
                right: 1rem;
                bottom: 20%;
            }
        }
        .HomeAboutSec {
            display: flex;
            flex-direction: column;
            width: 36%;
            padding: 0px 1vw 0 0;
            @include largeWeb {
                width: 55%;
                padding: unset;
            }
            @include maxWeb {
                margin: 0 auto;
                align-items: center;
                justify-content: center;
            }
            @include pad {
                width: 60%;
            }
            @include biggerMobile {
                width: 80%;
            }

            //標題
            .HomeAboutTitleCon {
                display: flex;
                @include fonSectitle;
                gap: 1.25rem;
                margin-bottom: 3rem;
                @include largeWeb {
                    margin-bottom: 2.5rem;
                }
                //icon
                .HomeAboutIcon {
                    width: 4rem;
                    @include biggerMobile {
                        width: 3rem;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .HomeAboutTitle {
                    color: $colorC;
                    @include fontitle;
                    font-weight: $fontWeightB;
                    @include letterSpacingA;
                    @include mobile {
                        @include fonSectitle;
                    }
                }
            }
            //內容（文字）
            .HomeAboutContent {
                color: $colorC;
                @include fontrdtitle;
                text-align: left;
                @include letterSpacingA;
                margin-bottom: 5rem;
                width: 80%;
                @include largeWeb {
                margin-bottom: 3rem;

                    width: 100%;
                }

                @include maxWeb {
                    margin-bottom: 3rem;
                }
                @include mobile {
                    @include fontContent;
                    width: 100%;
                }
            }
            //介紹圖
            .HomeAboutContentImg {
                @include opacityAnimate;
                @include maxWeb {
                    width: unset;
                    margin-bottom: 3rem;
                }

                @include biggerMobile {
                    width: 70%;
                }
                @include mobile {
                    width: 100%;
                }
                position: relative;
            }
        }
        //視覺圖
        .HomeAboutImgContainer {
            position: relative;
            width: 50%;
            height: 50vw;
            @include largeWeb {
                width: 100%;
                bottom: 0;
                background-size: contain;
            }
            @include pad {
                height: 70vw;
            }
            .HomeAboutImg {
                position: absolute;
                width: 100%;
                height: 95%;
                background-image: url("/src/frontImage/homeaboutbc02.png");
                background-size: 170% auto;
                background-repeat: no-repeat;
                z-index: 10;
                bottom: 5rem;
                @include largeWeb {
                    width: 95%;
                    height: 95%;
                    right: 0;
                    background-size: cover;
                }

                @include maxWeb {
                    width: 80%;
                    height: 100%;
                    background-position-x: 5rem;
                    background-size: cover;
                    bottom: 0;
                }
                @include pad {
                    height: 100%;
                }
            }
            .HomeAboutImgBc {
                position: absolute;
                width: 110%;
                height: 105%;
                right: 0;
                z-index: 5;
                background-image: url("/src/frontImage/homeaboutbc01.svg");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: right bottom;
                @include largeWeb {
                    background-position: right bottom;
                    background-size: 90% 100%;
                }
                @include maxWeb {
                    bottom: -5%;
                    background-position: left bottom;
                    background-size: 100% 100%;
                }
                @include pad {
                    width: 100%;
                    height: 100%;
                    bottom: 0;
                }
            }
        }
    }
    //第二層區塊
    .HomeSecAboutontainer {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin-top: 5rem;
        gap: 3rem;
        @include pad {
            position: relative;
            @include arrowguide;
            &::after {
                right: 1rem;
                top: 30%;
            }
        }
        @include maxWeb {
            flex-direction: column-reverse;
            justify-content: center;
            align-items: center;
        }
        @include biggerMobile {
            margin-top: unset;
        }
        //內容部分
        .HomeAboutSec {
            display: flex;
            flex-direction: column;
            width: 35%;
            padding: 0 3rem;
            margin: 0 7rem 0 0;
            @include largeWeb {
                width: 50%;
                padding: 0;
            }
            @include maxWeb {
                margin: 5rem 0 0 0;
            }
            @include pad {
                width: 58%;
                margin: 8rem auto;
            }
            @include biggerMobile {
                width: 80%;
                margin: 2rem auto 0 auto;
            }
            //標題
            .HomeAboutTitleCon {
                display: flex;
                @include fonSectitle;
                gap: 1.25rem;
                margin-bottom: 3rem;
                @include maxWeb {
                    margin-bottom: 2.5rem;

                    align-items: center;
                    justify-content: center;
                }
                @include mobile {
                    margin: 0 0 2.5rem 0;
                    gap: 0.5rem;
                }
                //icon
                .HomeAboutIcon {
                    width: 4rem;
                    @include biggerMobile {
                        width: 3rem;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .HomeAboutTitle {
                    color: $colorC;
                    @include fontitle;
                    font-weight: $fontWeightB;
                    @include letterSpacingA;
                    @include mobile {
                        @include fonSectitle;
                    }
                }
            }
            //內容（文字
            .HomeAboutContent {
                color: $colorC;
                @include fontrdtitle;
                font-weight: $fontWeightA;
                @include letterSpacingA;
                text-align: left;
                margin-bottom: 6rem;
                width: 95%;
                @include largeWeb {
                    margin-bottom: 3rem;
                }
                @include maxWeb {
                    margin-bottom: 2.5rem;
                }
                @include mobile {
                    @include fontContent;
                    width: 100%;
                }
            }
            // 選擇選項
            .HomeAboutselec {
                display: grid;
                grid-template-columns: auto auto auto;
                gap: 2.5rem;
                row-gap: 1.5rem;
                margin: auto;
                justify-content: space-around;
                @include pad {
                    width: 55%;
                }
                @include mobile{
                    grid-template-columns: auto auto ;
                }
                .selecItem {
                    background: $backgroundA;
                    @include borderRadiusC;
                    // width: 4.5rem;
                    // height: 4.5rem;
                    width: 8rem;
                    height: 3rem;
                    padding: 0.8rem;
                    transition: all 0.2s linear;
                    @include smallerMobile {
                        width: 3.5rem;
                        height: 3.5rem;
                        @include fontSecContent;
                    }
                    span {
                        color: $colorB;
                        @include fontContent;
                        font-weight: $fontWeightB;
                        @include letterSpacingA;
                        display: block;
                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        text-align: center;
                        cursor: pointer;
                        transition: all 0.2s linear;
                        &:hover {
                            color: $colorC;
                        }
                    }
                    &:hover {
                        span {
                            color: $colorC;
                        }
                        background: $colorD;
                    }
                }
                .focusSelecItem {
                    background: $colorD;
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
                    span {
                        color: $colorC;
                    }
                }
            }
            //選擇圖片
            .HomeAboutContentImg {
                margin-top: 2rem;
                position: relative;
                @include opacityAnimate;
            }
        }
        //視覺圖
        .HomeAboutImgContainer {
            position: relative;
            width: 51%;
            height: 58vw;
            @include largeWeb {
                width: 90%;
                bottom: 0;
                background-size: contain;
            }
            @include maxWeb {
                width: 100%;
            }
            @include pad {
                width: 100%;
                height: 70vw;
            }
            .HomeAboutImg {
                position: absolute;
                width: 85%;
                height: 85%;
                background-image: url("/src/frontImage/homeabout06.png");
                background-size: cover;
                background-repeat: no-repeat;
                z-index: 10;
                right: 3rem;
                bottom: 10rem;
                @include largeWeb {
                    height: 95%;
                    width: 95%;
                    bottom: 5rem;
                    right: 0;
                    background-size: contain;
                }
                @include maxWeb {
                    height: 100%;
                    width: 65%;
                    top: 50%;
                    transform: translateY(-50%);
                }
                @include pad {
                    height: 100%;
                    width: 75%;
                }
            }
            .HomeAboutImgBc {
                position: absolute;
                width: 110%;
                height: 105%;
                right: 0;
                bottom: 0;
                z-index: 5;
                background-image: url("/src/frontImage/homeaboutbc03.svg");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                @include largeWeb {
                    height: 95%;
                    width: 95%;
                    left: 0;
                }
                @include maxWeb {
                    bottom: -5%;
                    width: 100%;
                    height: 100%;
                    background-size: 125% 100%;
                    background-position: left bottom;
                }
                @include pad {
                    width: 100%;
                    height: 100%;
                    background-size: 105% 100%;
                    background-position: left bottom;
                }
            }
        }
    }
    //第三層區塊
    .HomethirdAboutontainer {
        padding-left: 5rem;
        display: flex;
        justify-content: space-between;
        gap: 5.3rem;
        margin-top: 5rem ;
        position: relative;
        @include arrowguide;
        &::after {
            right: 1rem;
            bottom: 10%;
        }
        @include maxWeb {
            flex-direction: column-reverse;
            justify-content: center;
            padding-left: unset;
        }
        @include pad {
            padding-left: 0;
        }
        @include biggerMobile {
            gap: 2rem;
        }
        //內容
        .HomeAboutSec {
            display: flex;
            flex-direction: column;
            width: 30%;
            margin: 0 5rem;
            @include largeWeb {
                width: 55%;
            }
            @include maxWeb {
                margin: auto;
                align-items: center;
                justify-content: center;
            }
            @include pad {
                width: 60%;
                // margin: 8rem auto;
            }
            @include biggerMobile {
                width: 80%;
                margin: 2rem auto 0 auto;
            }
            //標題
            .HomeAboutTitleCon {
                display: flex;
                @include fonSectitle;
                gap: 1.25rem;
                margin-bottom: 3rem;
                @include largeWeb {
                    margin-bottom: 2.5rem;
                }
                @include maxWeb {
                    margin-bottom: 2.5rem;
                    align-items: center;
                    justify-content: center;
                }
                @include mobile {
                    margin: 0 0 2.5rem 0;
                    gap: 0.5rem;
                }
                //icon
                .HomeAboutIcon {
                    width: 4rem;
                    @include biggerMobile {
                        width: 3rem;
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
                .HomeAboutTitle {
                    color: $colorC;
                    @include fontitle;
                    font-weight: $fontWeightB;
                    @include letterSpacingA;
                    @include mobile {
                        @include fonSectitle;
                    }
                }
            }
            //內容
            .HomeAboutContent {
                color: $colorC;
                @include fontrdtitle;
                @include letterSpacingA;
                text-align: left;
                margin-bottom: 3rem;
                width: 90%;
                @include mobile {
                    @include fontContent;
                    width: 100%;
                }
            }
            .contentContainer {
                margin-bottom: 3rem;
            }

            .HomeAboutContentImg {
                width: 70%;
                margin: 2rem auto;
                @include opacityAnimate;
                @include maxWeb {
                    width: unset;
                    margin: unset;
                }
                @include biggerMobile {
                    margin: unset;
                    width: 70%;
                }
                @include mobile {
                    width: 100%;
                }
            }
        }
        //視覺圖
        .HomeAboutImgContainer {
            position: relative;
            width: 50%;
            height: 45vw;
            @include largeWeb {
                width: 100%;
                bottom: 0;
                background-size: contain;
            }
            @include pad {
                height: 70vw;
            }
            .HomeAboutImg {
                position: absolute;
                width: 85%;
                height: 85%;
                background-image: url("/src/frontImage/homeaboutbc04.png");
                background-size: cover;
                background-repeat: no-repeat;
                z-index: 10;
                right: 3rem;
                bottom: 3rem;
                @include largeWeb {
                    height: 95%;
                    width: 95%;
                    bottom: 0;
                    right: 0;
                    background-size: contain;
                }
                @include maxWeb {
                    height: 100%;
                    width: 85%;
                }
            }
            .HomeAboutImgBc {
                position: absolute;
                width: 110%;
                height: 105%;
                right: 0;
                bottom: -5%;
                z-index: 5;
                background-image: url("/src/frontImage/homeaboutbc01.svg");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                background-position: right bottom;
                @include maxWeb {
                    bottom: 0;
                    background-size: 120% 100%;
                    background-position: right bottom;
                }
                @include pad {
                    bottom: 0;
                    background-size: 105% 100%;
                    background-position: left bottom;
                }
            }
        }
    }
}
.homeAboutLinkCon {
    @include opacityAnimate;
    margin-top: 4rem;
    @include largeWeb{
        margin-top: 2rem;
    }
    @include pad {
        margin: 4rem auto;
    }
}
.slider {
    height: 100%;
    width: 1px;
    background: $colorB;
    position: relative;
    &::after {
        content: "";
        position: absolute;
        width: 1.9vw;
        height: 1.9vw;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: $colorB;
        border-radius: 50%;
        background-image: url("/src/frontImage/homeabouticonspec.svg");
        top: 50%;
        transform: translateY(-50%);
        left: -0.78vw;
        cursor: pointer;
    }
}
