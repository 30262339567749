$hamburBackground: $colorA;
$lineWidth: 1.625rem;
$lineHeight: 2px;
$lineradius: 3px;
$timesBackground: white;
$ContainerWidth: 350px;

.navMenu {
  position: fixed;
  z-index: 101;
  top: 3rem;
  right: 2%;
  display: none;
  @include pad {
    display: block;
  }

  .hamburger {
    z-index: 101;
    cursor: pointer;
    position: relative;
    .ham {
      .hamLine {
        width: $lineWidth;
        height: $lineHeight;
        border-radius: $lineradius;
        background-color: $hamburBackground;
        margin: 5.5px;
        transition: all 0.3s ease-in-out;
      }
    }
    .times {
      .hamLine {
        width: $lineWidth;
        height: $lineHeight;
        border-radius: $lineradius;
        margin: 5.5px;
        transition: all 0.3s linear;
      }
      .topChange {
        transform: rotate(-45deg);
        margin-top: 25px;
        background-color: $timesBackground;
        z-index: 101;
      }
      .bottomChange {
        transform: rotate(45deg);
        margin-top: -7px;
        background-color: $timesBackground;
        z-index: 101;
      }
    }
  }
  $ContainerBc: #343a40;

  .toggleContainer {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    max-height: 100%;
    z-index: 100;
    overflow-y: auto;
    @include pad {
      width: 100%;
    }
    .toggleContainerBc {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #00081496;
    }
    .toggleContent {
      background-color: $colorA;
      color: $colorB;
      height: 100%;
      display: flex;
      flex-direction: column;
      transition: all 0.1s linear;
      align-items: center;
      overflow-y: scroll;
      width: 100%;
      z-index: 10;
      position: relative;
      .content {
        padding-bottom: 1rem;
        li {
          @include pad {
            display: flex;
            margin-bottom: 6.5rem;
          }
          @include biggerMobile{
            display: block;
            margin: unset;
          }
          &:nth-last-child(1) {
            .labelContainer{
              a{
                &::after {
                  content: unset;
                }
              }
            }
            .insideMenuContainer {
              &::before {
                content: unset;
              }
            }
          }
        }
        .labelContainer {
          @include labelstyle;
        }
      }
      .toggleContentTxt {
        margin: 3.125rem 0 3.7rem 0;
        img {
          width: 9rem;
          height: 1.6rem;
        }
      }
      .navHamIcon {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        .navHamIconLink {
          display: block;
          color: #dee2e6;
          @include mobile {
            font-size: 2rem;
          }
        }
      }
    }
    .iconContainer {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 1.25rem;
      background-color: $colorA;
      display: flex;
      z-index: 100;
      width: 100%;
      justify-content: center;
      gap: 1rem;
      li {
        transition: all 0.5s linear;
        padding-bottom: 0.1rem;

        .socialLink {
          position: relative;
          display: block;
          transition: all 1s linear;
          &:hover {
            transition: all 0.5s linear;

            .socialImg {
              opacity: 1;
            }
          }
          .socialImg {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            transition: all 0.5s linear;
          }
        }
        .socialIcon {
          position: absolute;
          z-index: 12;
          color: $colorA;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
        }
      }
    }
  }
  .toggleBc {
    background-color: #eb060649;
    position: fixed;
    z-index: 100;
    width: 50vw;
    height: 100vh;
    top: 0;
    right: 0;
    left: 0;
    transform: translateX(0);
    transition: all 0.5s linear;
    animation: bcslideout 3 linear;
    @keyframes bcslideout {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(0);
      }
    }
  }
}

/* 點擊後漢堡變叉 的動畫*/
.topChange {
  transform: rotate(-45deg);
  margin-top: 25px;
  background-color: white;
  z-index: 101;
}
.middleChange {
  opacity: 0;
  transform: rotate(45deg);
}
.bottomChange {
  transform: rotate(45deg);
  margin-top: -7px;
  background-color: white;
  z-index: 101;
}

#toggle:checked ~ .toggleContainer {
  visibility: visible;
}
#toggle:checked ~ .toggleContainer > div {
  transform: scale(1);
}
#toggle:checked ~ .toggleContainer > div > div {
  opacity: 1;
  transition: opacity 0.4s ease;
}

.childrenConatiner {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}

.navHamellipsis {
  overflow: hidden;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.insideMenuContainer {
  position: relative;
  margin-bottom: 1.25rem;
  padding: 0rem;
  @include biggerMobile{
  padding: 1.875rem 0;

  }

  &::after {
   
    @include biggerMobile{
      @include navline;
      bottom: 0;
    }
  }
  &::before {
    @include biggerMobile{
      @include navline;
      top: 0;
    }
  }
  .insideMenu {
    @include insideMenustyle;
  }
}
