@import url("https://fonts.googleapis.com/css2?family=Inter:wght@800&display=swap");
$normailvw: 1.09vw;
.counterContainer {
    margin-top: 3.875rem;
    padding: 0 3rem ;
    font-family: "Inter", sans-serif;
    @include maxWeb {
        margin-top: 6.5rem;
    }
    @include biggerMobile{
        margin-top: 4rem;

    }
    @include mobile{
        padding: 0 1rem;
    }
    .counterDiv {
        display: flex;
        justify-content: center;
        gap: 5vw;
        @include pad {
            align-items: center;
            flex-direction: column;
        }
    }
}
.counter {
    display: flex;
    @include pad {
        width: 60%;
    }
    @include biggerMobile {
        width: 90%;
    }
    @include smallerMobile{
        width: 100%;
    }
}
.counterDetail {
    font-weight: $fontWeightC;
    color: $colorB;
    text-align: left;
    padding: 1vw;
    .counterTitle {
        @include fontitle;
        @include letterSpacingA;
        padding-bottom: 0.5rem;
        @include pad {
            padding-bottom: 0.2rem;
        }
        @include mobile{
            @include fonSectitle;
        }
    }
    .counterNum {
        @include fontitle;
        @include enFont;
        @include letterSpacingA;
        word-break: break-all;
        
    }
    span{
        &:nth-child(2) {
            transform: translateY(2px);
        }
    }
   
}
.counterBox {
    background: $backgroundA;
    border-radius: 18px;
    display: flex;
    align-items: center;
    gap: 0.8vw;
    padding: 1.87vw;
    @include fonSectitle;

    @include pad {
        justify-content: center;
        width: 100%;
        gap: 3rem;
        padding: 1.5rem;
    }
    @include biggerMobile{
        gap: 1rem;
    }
    .counterBoxImg {
        padding: 0.1vw;
        width: 4.25rem;
        height: 4.25rem;
        @include pad {
            width: 3.1rem;
            height: 4.25rem;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
