.inputField {
    background-color: $colorF;
    @include borderRadiusC;
    @include fontContent;
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 6px;
    .formLabel {
        color: $colorC;
        font-weight: $fontWeightB;
        @include letterSpacingB;
    }
    .field {
        @include letterSpacingB;
        background-color: $colorF;
        width: 100%;
    }
}
.textareaField {
    background-color: $colorF;
    border-radius: 6px;
    @include fontContent;

    padding: 0.5rem 1rem 0 1rem;
    display: flex;
    align-items: center;
    @include biggerMobile {
        margin-bottom: 2rem;
    }
    $textareaField_padding_top:.8rem;
    .formcontentspan {
        display: flex;
        align-self: flex-start;
        padding-top:$textareaField_padding_top;
    }
    .formLabel {
        color: $colorC;
        font-weight: $fontWeightB;
        @include letterSpacingB;
        display: flex;
        align-self: flex-start;
        padding-top:$textareaField_padding_top;

    }
    .field {
        @include letterSpacingB;
        background-color: $colorF;
        width: 100%;
        padding-top: $textareaField_padding_top ;
        height: 300px;
    }
}
@mixin formBtn {
    @include letterSpacingB;
    padding: 0.5rem 1rem;
    @include borderRadiusC;
}
.submitBtn {
    color: $colorB;
    border: 1px solid transparent;
    @include formBtn;
    @include borderRadiusD;
    cursor: pointer;
    &:hover {
        transition: all 0.5s linear;
        background-color: $colorB;
        color: $colorC;
        border-color: $colorC;
    }
}
.cancleSubmit {
    background-color: $colorA;
    @include formBtn;
}
.formcontentspan {
    color: rgb(238, 53, 53);
    letter-spacing: 6px;
}
.error {
    color: red;
    display: block;
    text-align: left;
    font-size: 0.875rem;
    padding: 5px 0 5px 5px;
    text-align: right;
}
.red {
    color: red;
}
