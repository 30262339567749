.aboutHistory {
    margin-top: 2.5rem;
    .title {
        display: flex;
        align-items: flex-end;
        gap: 2vw;
        padding-right: 1rem;
        position: relative;
        width: fit-content;
        @include letterSpacingA;
        @include smallerPad {
            flex-wrap: wrap;
            justify-content: center;
            margin-bottom: 3rem;
        }
        @include biggerMobile{
            margin: auto;
        }
        @include mobile{
          
            &::after{
               display: none;
               
            }
        }
        &::after{
            content: '';
            @include yellowDirection;
        }
        .titleEn {
            @include fonSectitle;
            @include enFont;
            color: $colorA;
            position: relative;
           
        }
        .titlech {
            @include fonSectitle;
            font-weight: $fontWeightB;
            color: $colorC;
            @include mobile{
                position: relative;
                padding-right: 1rem;
                  &::after {
                    @include yellowDirection;
                }
            }
        }
    }
    .timeLineContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 5vw;
        margin-bottom: 10vw;

        .container {
            display: flex;
            justify-content: space-between;
            padding-bottom: 2.25rem;
            cursor: pointer;
            @include smallerPad {
                margin-top: 1rem;
                flex-direction: column;
                align-items: center;
            }
            &:nth-last-child(1) {
                .titleContainer {
                    &::after {
                        content: unset;
                    }
                }
            }
            .titleContainer {
                display: flex;
                justify-content: space-between;
                position: relative;
                width: 20%;
                @include maxWeb{
                    width: 25%;
                }
                @include smallerPad {
                    align-items: center;
                    justify-content: center;
                    gap: 1.5rem;
                    @include borderRadiusA;
                    width: unset;
                    padding: 0.5rem 0;
                }

                &::after {
                    content: "";
                    position: absolute;
                    top: 2.25rem;
                    height: 100%;
                    width: 2px;
                    background-color: $colorA;
                    @include borderRadiusA;
                    right: 1.05rem;
                    z-index: -1;
                    @include smallerPad {
                        content: unset;
                    }
                }
                .year {
                    @include fontBiggestTitle;
                    @include letterSpacingA;
                    @include enFont;
                    color: $colorA;
                    width: 7rem;
                    transform: translateY(-0.65rem);
                    @include pad {
                        width: 80%;
                    }
                    @include smallerPad {
                        @include letterSpacingB;
                        width: unset;
                        transform: unset;
                    }
                    @include smallerMobile {
                    }
                }
                .circle {
                    display: block;
                    width: 2.25rem;
                    height: 2.25rem;
                    border: 2px solid $colorH;
                    border-radius: 50%;
                    position: relative;
                    cursor: pointer;
                    $iconWidth: 5px;
                    background-image: url("/src/frontImage/Vector.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    transform: rotate(90deg);
                    @include mobile {
                        width: 2.5rem;
                        height: 2.5rem;
                    }
                    @include smallerMobile {
                    }
                }
            }
            span {
                width: 80%;
                @include pad {
                    width: 75%;
                }
                @include mobile{
                    width: 100%;
                }
                .content {
                    display: flex;
                    align-items: center;
                    padding-left: 3.5vw;
                    @include pad {
                        flex-direction: column-reverse;
                    }
                    @include smallerPad {
                        padding-left: unset;
                        padding: 3rem 0;
                    }
                    .contentContainer {
                        align-self: flex-start;
                        padding-right: 3vw;
                        width: 60%;
                        @include letterSpacingB;
                        @include pad {
                            width: 100%;
                        }
                        ul {
                            li {
                                position: relative;
                                padding-left: 1rem;
                                margin-bottom: 1rem;
                                &::before {
                                    content: "";
                                    position: absolute;
                                    width: 0.5vw;
                                    height: 0.5vw;
                                    background-color: $colorC;
                                    border-radius: 50%;
                                    left: 0;
                                    top: 0.5vw;
                                    @include smallerPad {
                                        top: 0.3rem;
                                        width: 0.3rem;
                                        height: 0.3rem;
                                    }
                                }
                            }
                        }
                    }
                    .imgContainer {
                        width: 40%;
                        align-self: flex-start;
                        @include borderRadiusA;
                        @include pad {
                            margin-bottom: 2rem;
                            width: 100%;
                        }
                        .img {
                            width: 18.2vw;
                            height: 10.7vw;
                            @include pad {
                                width: 100%;
                                height: 12.5rem;
                            }
                            margin: auto;
                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                @include borderRadiusA;
                            }
                        }
                    }
                }
            }
        }
    }
}
