// 資訊整合主視覺
@mixin contentInner {
    display: flex;
    flex-direction: column;
    gap: 2.08vw;
    @include fontitle;
}
.serviceInter {
    display: flex;
    .MainVisual {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2.7vw;
        margin-bottom: 5.8vw;
        position: relative;
        @include arrowguide;
        &::after {
            right: 0;
            bottom: -5rem;
        }
        @include pad {
            margin-bottom: unset;
        }
        .MainVisualContent {
            @include contentInner;
            // width: 40%;
            padding: 8vw 0;
            @include maxWeb {
                width: 100%;
                padding: 3rem 0;
            }
            .mainVisualTitleContainer {
                display: flex;
                position: relative;
                gap: 2vw;
                align-items: flex-end;

                .enTitle {
                    display: block;
                    @include fontBiggestTitle;
                    font-weight: $fontWeightA;
                    color: $colorA;
                    @include letterSpacingB;
                }
                .MainVisualTitle {
                    @include fontitle;
                    color: $colorC;
                    font-weight: $fontWeightB;
                    padding-bottom: 0.5vw;
                }
            }
            .subTitle {
                display: block;
                color: $colorA;
                @include fonSectitle;
                font-weight: $fontWeightB;
                @include letterSpacingA;
            }
            p {
                @include fonSectitle;
                font-weight: $fontWeightA;
                color: $colorC;
                @include letterSpacingB;
                @include biggerMobile {
                    @include fontrdtitle;
                }
            }
        }
    }
}
// 資訊整合非主視覺
@mixin titleinner {
    display: flex;
    color: $colorA;
    position: relative;
    width: fit-content;
    padding-right: 1rem;
    color: $colorA;
    @include fontitle;
    @include largeWeb {
        flex-direction: column;
    }
    @include maxWeb {
        flex-direction: row;
    }
    @include smallerMobile {
        flex-direction: column;
        &::after {
            display: none;
        }
    }
}

.serviceInterSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vw;
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        &:nth-child(odd) {
            flex-direction: row-reverse;
            @include pad {
                flex-direction: column-reverse;
            }
        }
        margin-bottom: 8vw;
        gap: 5rem;
        margin-bottom: 8rem;
        position: relative;

        @include pad {
            flex-direction: column-reverse;
            margin-bottom: 10rem;
            @include arrowguide;
            &::after {
                right: 0;
                bottom: -8rem;
            }
        }
        &:nth-last-child(2) {
            @include pad {
                &::after {
                    content: unset;
                }
            }
        }
        @include smallerMobile {
            gap: 3rem;
        }
        .content {
            @include contentInner;
            width: 40%;
            display: flex;
            flex-direction: column;
            @include maxWeb {
                width: 70%;
            }
            @include pad {
                align-items: center;
            }
            @include biggerMobile {
                width: 90%;
            }
            @include smallerMobile {
                gap: 3rem;
            }
            gap: 3.75rem;
            .titleContainer {
                @include titleinner;
                @include maxSizeWeb {
                    flex-direction: column;
                }
                @include smallerMobile {
                    align-items: center;
                    padding-right: unset;
                }
                .title {
                    display: block;
                    @include fonSectitle;
                    font-weight: $fontWeightB;
                    @include letterSpacingC;
                }
                .entitle {
                    display: block;
                    @include fontrdtitle;
                    font-weight: $fontWeightC;
                    @include letterSpacingA;
                    transform: translateY(0.25rem);
                    @include biggerMobile {
                        @include fontContent;
                    }
                }
            }

            ul {
                margin-left: 1rem;
                li {
                    @include fonSectitle;
                    font-weight: $fontWeightB;
                    color: $colorC;
                    @include letterSpacingC;
                    // padding-left: 0.5rem;
                    position: relative;
                    margin-bottom: 0.5rem;
                    @include mobile {
                        @include letterSpacingA;
                    }
                    &::before {
                        @include linkDot;
                    }
                    @include smallerMobile {
                        padding-left: unset;
                        @include letterSpacingA;
                        @include fontrdtitle;
                    }
                }
            }
            .detail {
                @include fontrdtitle;
                color: $colorC;
                @include letterSpacingA;
                @include pad {
                    width: 85%;
                    margin: auto;
                }
                @include biggerMobile {
                    width: 100%;
                }
            }
        }

        .mainVisualImg {
            width: 60%;
            align-self: center;

            @include pad {
                width: 65%;
            }
            @include biggerMobile {
                width: 100%;
            }
        }
    }
}
