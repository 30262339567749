@mixin aboutMainVisual {
    .aboutMainVisualContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 9vw;
        margin-bottom: 5.8vw;
        @include largeWeb {
            gap: 6vw;
        }
        @include maxWeb {
            width: 70%;
            margin: auto;
            flex-direction: column-reverse;
            margin-bottom: 10rem;
        }
        @include biggerMobile {
            width: 100%;
            gap: 5rem;
        }

        .aboutMainVisualContent {
            display: flex;
            flex-direction: column;
            gap: 4.47vw;
            width: 32%;
            @include maxWeb {
                width: 100%;
            }
            @include pad {
                width: 80%;
                gap: unset;
            }
            @include biggerMobile {
                width: 95%;
            }

            .aboutMainVisualTitle {
                @include fontitle;
                font-weight: $fontWeightB;
                color: $colorA;
                @include letterSpacingA;

                @include maxWeb {
                    text-align: center;
                    margin-bottom: 3.125rem;
                }
            }
            p {
                @include fontrdtitle;
                font-weight: $fontWeightA;
                color: $colorC;
                @include letterSpacingB;
            }
        }
    }
}

.aboutMainVisual {
    display: flex;
    flex-direction: column;
    margin-bottom: 6rem;
    @include aboutMainVisual;
    @include largeWeb {
        margin-bottom: 5rem;
    }
    @include biggerMobile {
        margin-bottom: unset;
    }

    position: relative;
    .aboutMainVisualContainer {
        @include arrowguide;
        &::after {
            right: 0;
            bottom: -5rem;
        }
    }
}
