.aboutBrandMainVisual {
    display: flex;
    flex-direction: column;
    margin-bottom: 8rem;
    @include biggerMobile {
        margin-bottom: 3rem;
    }
    @include maxWeb {
        align-items: center;
    }
    @include mobile{
        margin-bottom: 5rem;
    }
    //側邊裝飾
    &:nth-child(odd) {
        position: relative;
        .aboutMainVisualContainer {
            @include arrowguide;
            &::after {
                right: 0;
                bottom: -8rem;
            }
        }
    }
    .aboutMainVisualContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 9.5vw;
        margin-bottom: 7rem;

        @include maxWeb {
            flex-direction: column;
            width: 70%;
            gap: 5rem;
        }
        @include biggerMobile {
            margin-bottom: 8rem;
            width: 100%;
        }
        @include smallerMobile {
            gap: 3rem;
        }
        .aboutMainVisualContent {
            display: flex;
            flex-direction: column;
            gap: 4.47vw;
            width: 40%;
            font-weight: $fontWeightA;
            @include maxWeb {
                width: 100%;
                align-items: center;
                gap: unset;
            }

            .aboutMainVisualTitle {
                @include fontitle;
                color: $colorA;
                @include maxWeb {
                    text-align: center;
                    margin-bottom: 3.125rem;
                }
            }
            .logo {
                @include maxWeb {
                    margin-bottom: 3.8rem;
                    width: 52%;
                }
                @include biggerMobile {
                    width: 70%;
                }
            }
            p {
                @include fontrdtitle;
                @include letterSpacingA;
                color: $colorC;
                @include letterSpacingB;
                @include maxWeb {
                    width: 80%;
                }
                @include biggerMobile {
                    width: 100%;
                }
            }
        }
        .aboutMainVisualImg {
            width: 70%;

            @include maxWeb {
                width: 90%;
            }
            @include biggerMobile {
                width: 100%;
            }
        }
    }
    .aboutBrandLink {
        @include fontContent;
        color: $colorA;
        @include letterSpacingB;
        padding-right: 3.33vw;
        position: relative;
        width: fit-content;
        margin-top: 8rem;
        &::after {
            content: "";
            position: absolute;
            background-image: url("/src/frontImage/homeLink.svg");
            width: 1.9vw;
            height: 1.9vw;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            top: 50%;
            margin-left: 1rem;
            transform: translateY(-50%);
        }
    }
}
