.homeNewsSwiper {
    padding-right: 30px !important;
    > .swiper-pagination {
        bottom: 0px !important;
        width: 30px !important;
        right: 0 !important;
        left: unset !important;
    }
}
.homeNews {
    padding: 2rem 0 7.8vw 0;
    position: relative;
    &::after {
        @include pad {
            content: "";
            position: absolute;
            background-color: $colorG;
            width: 100%;
            height: 20%;
            top: 20%;
            left: 0;
            z-index: -1;
        }
        @include biggerMobile {
            content: unset;
        }
    }
    @include maxWeb {
        padding: 0 0 7.8vw 0;
    }
    @include biggerMobile {
        padding: unset;
    }
    @include mobile {
        margin-bottom: 5rem;
    }
    .homeNewsContainer {
        padding: 0 6.9vw 0 7.6vw;
        display: flex;
        align-items: center;
        position: relative;
        @include maxWeb {
            width: 90%;
            margin: auto;
            padding: unset;
        }

        @include pad {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 2.5rem;
        }
        @include biggerMobile {
            width: 100%;
            flex-direction: column-reverse;

            &::after {
                content: "";
                background-color: $colorG;
                position: absolute;
                width: 100%;
                bottom: -3rem;
                height: 10rem;
                left: 0;
                z-index: -1;
            }
        }
        @include mobile {
            padding: 0 1rem;
        }

        .carouselContainer {
            width: 40%;
            cursor: pointer;
            position: relative;

            @include pad {
                width: 80%;
            }
            img {
                width: 36vw;
                height: 30vw;
                object-fit: cover;
                @include pad {
                    width: 100%;
                    height: 25rem;
                }
                @include biggerMobile {
                    width: 100%;
                    height: 20rem;
                }
            }

            @include biggerMobile {
                width: 85%;
                max-width: 100%;
                margin-top: 3rem;
            }

            .homeNewImgTitle {
                position: absolute;
                z-index: 10;
                color: $colorB;
                height: calc(23% - 5px);
                width: calc(100% - 5px);
                width: 100%;
                background-color: $colorA;
                opacity: 0.8;
                bottom: 5px;
                overflow: hidden;
                transition: all 0.3s linear;
                margin: auto;
                span {
                    position: absolute;
                    text-align: left;
                    width: 95%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    @include letterSpacingA;
                    font-weight: $fontWeightB;
                    @include fontitle;
                    @include maxSizeWeb {
                        @include fonSectitle;
                    }
                    @include mobile {
                        @include fontSecContent;
                    }
                }
                @mixin boxFakele {
                    content: "";
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    bottom: 30px;
                    left: 30px;
                    transition: all 0.5s;
                }
                &::after {
                    content: "";
                    @include boxFakele;
                    border-right: 1px solid $colorB;
                    border-left: 1px solid $colorB;
                    transform: scale(1, 0);
                }
                &::before {
                    content: "";
                    @include boxFakele;
                    border-top: 1px solid $colorB;
                    border-bottom: 1px solid $colorB;
                    transform: scale(0, 1);
                }
            }
        }
    }
}
.homeNewsDetail {
    margin-left: 5.5rem;
    width: 100%;
    position: relative;
    @include maxWeb {
        margin-left: 3.5rem;
    }
    @include pad {
        margin-top: 4.875rem;
        padding-left: unset;
        margin-left: unset;
    }
    .homeNewsTitle {
        display: flex;
        justify-content: space-between;
        padding-bottom: 2.5rem;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            background-color: $colorA;
            height: 0.2vw;
            width: 100%;
            @include mobile {
                content: unset;
            }
        }
        div {
            justify-content: center;
            align-items: center;
            gap: 1.5rem;
            @include pad {
                justify-content: unset;
            }
            @include biggerMobile {
                justify-content: center;
                width: 100%;
            }
            .homeNewsTitleIcon {
                width: 7.9vw;
                height: 1.45vw;
                @include pad {
                    width: 7.75em;
                    height: 1.6rem;
                }
            }
            h3 {
                font-weight: $fontWeightB;
                @include fonSectitle;
            }
        }
        .homeNewsLinkCon {
            position: relative;
            width: fit-content;
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
            &::after {
                content: "";
                position: absolute;
                width: 70%;
                background-color: $colorE;
                height: 2px;
                bottom: -5px;
                left: 0;
                transition: all 0.2s linear;
                opacity: 0;
            }
            @include biggerMobile {
                left: 50%;
                transform: translateX(-50%);
            }
            .homeNewsLink {
                @include fontrdtitle;
                font-weight: $fontWeightB;
                @include letterSpacingA;
                position: relative;
                display: flex;
                align-items: center;
                padding-right: 3.5rem;
                @include biggerMobile {
                    display: none;
                }
                &:hover {
                    &::after {
                        background-color: $colorH;
                    }
                    &::before {
                        background-image: url("/src/frontImage/vectorWhite.svg");
                    }
                }
                &::after {
                    content: "";
                    position: absolute;
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border: 2px solid $colorA;
                    transition: all 0.2s linear;
                }
                &::before {
                    content: "";
                    position: absolute;
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    right: 0;
                    z-index: 10;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background-image: url("/src/frontImage/Vector.svg");
                    background-position: center;
                    background-size: contain;
                    background-repeat: no-repeat;
                    transition: all 0.3s linear;
                }
            }
        }
    }
    .homeNewCardContainer {
        @include fontrdtitle;
        font-weight: $fontWeightB;
        @include letterSpacingA;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 3rem;
        @include biggerMobile {
            display: none;
        }
        @include pad {
            margin-top: 2rem;
        }
        @include mobile {
            display: none;
        }
        .homeNewsLinkCard {
            background-color: $colorA;
            text-align: center;
            padding: 0.5rem 0;
            border-radius: 7px;
            color: $colorB;
            border: 1px solid $colorA;
            &:hover {
                background-color: $colorB;
                color: $colorA;
                transition: all 0.3s linear;
            }
        }

        .homeNewCard {
            display: flex;
            flex-direction: column;
            padding-top: 1.5rem;
            transition: all 0.5s linear;
            width: 50%;
            margin: auto;
            .homeNewTitle {
                @include fonSectitle;
                @include letterSpacingA;
                font-weight: $fontWeightB;
                margin-bottom: 1rem;
                height: 4rem;
            }
            &:hover {
                .homeNewImg {
                    img {
                        transition: all 1s linear;
                        transform: scale(1.1);
                    }
                }
            }
            .homeNewContent {
                font-weight: $fontWeightA;
                @include fontrdtitle;
                @include letterSpacingB;
                margin-top: 1rem;
                word-break: break-all;
                height: 5rem;
                @include largeWeb {
                    @include fontContent;
                }
            }
            .homeNewImg {
                margin: auto;
                width: 20.5vw;
                height: 14vw;
                position: relative;
                overflow: hidden;
                border-radius: $borderRadiusA;
                img {
                    @include borderRadiusA;
                    position: absolute;

                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                @include pad {
                    // width: 21.25rem;
                    width: 100%;
                    height: 15rem;
                }
            }
        }
    }
}
.homeNews {
    .homeNewsContainer {
        .slick-initialized {
            .slick-slide {
                position: relative;
            }
        }
        .slick-list {
            &:hover {
                .homeNewImgTitle {
                    height: 100%;
                    width: 100%;
                    max-width: 100%;
                    max-height: 100%;
                    span {
                        display: block;
                        text-align: center;
                        width: 80%;
                    }
                    &::after {
                        transform: scale(1, 1);
                    }
                    &::before {
                        transform: scale(1, 1);
                    }
                }
            }
        }
        .slick-dots {
            bottom: 0px !important;
            width: 0.5rem !important;
            right: -1.5rem !important;
            left: unset !important;
            display: grid !important;
            gap: 0.5vw;
            @include pad {
                bottom: unset !important;
                width: unset !important;
                right: unset !important;
                left: 50% !important;
                display: block !important;
                transform: translateX(-50%);
            }
            li {
                margin: 0px !important;
                button {
                    width: 0.72vw !important;
                    height: 0.72vw !important;
                    border-radius: 50%;
                    border: 1px solid $colorA !important;
                    position: relative;
                    &:hover {
                        &::before {
                            background-color: $colorA;
                        }
                    }
                    &::before {
                        content: "";
                        position: absolute;
                        width: 100% !important;
                        height: 100% !important;
                        border-radius: 50%;
                        font-size: 0 !important;
                    }
                }
            }
            .slick-active {
                button {
                    background-color: $colorA !important;
                    &:hover {
                        &::before {
                            background-color: $colorA;
                        }
                    }
                    &:before {
                        font-size: 20px;
                        left: 0;
                        right: 0;
                        border-radius: 50%;
                        opacity: 1 !important;
                        color: $colorA !important;
                    }
                }
            }
        }
    }
}
.homeNewsLinkRwd {
    @include fontrdtitle;
    font-weight: $fontWeightB;
    @include letterSpacingA;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 3.5rem;
    width: fit-content;
    margin-top: 5rem;
    display: none;
    @include biggerMobile {
        display: block;
        margin: 6rem 0 10rem 0;
    }
    &::after {
        content: "";
        position: absolute;
        background-image: url("/src/frontImage/homeLink.svg");
        width: 2.3rem;
        height: 2.3rem;
        background-size: contain;
        background-position: center;
        right: 0%;
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
    }
}
