@mixin newsInsidernav {
    flex: 0 1 calc(50% - 110px);
    line-height: 0;
}
.newsSection {
    margin-top: 5vw;
    max-width: 70%;
    @include pad { 
        max-width: 760px;
        margin: auto;
    }
    .titleContainer {
        gap:1rem;
        margin-bottom: 2rem;
        @include pad {
            flex-direction: column;
        }
        .title {
            @include fontitle;
            @include letterSpacingB;
            font-weight: $fontWeightA;
        }
        @mixin newsSocial{
            .social {
                margin: 0.5rem 0rem 0 0;
                display: flex;
                gap:.51rem;
                @include pad{
                    display: none;
                }
                @include mobile {
                    gap: 1rem;
                }
                li {
                    width: 3.25em;
                    height: 3.25rem;
                    border-radius: 50%;
                    .socialLink {
                        position: relative;
                        display: block;
                        width: 2.8rem;
                        height: 2.8rem;
                        img{
                            width: 1000%;
                            height: 100%;
                            object-fit: contain;
                        }
                        &::after{
                            content: "";
                            position: absolute;
                            border-radius: 50%;
                            border:1px solid $colorA;
                            top: 0;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
        // @include newsSocial;
        // .social {
        //     float: right;
        //     @include pad{
        //         display: none;
        //     }
        // }
        .shareContainer{
            display: flex;
            justify-content: space-between;
            @include newsSocial;
            .social {
                display: flex;
            }
            .date {
                @include fonSectitle;
                @include letterSpacingB;
                margin: 1rem 0;
                display: block;
            }
        }
       
    }
    //此處為未來html編輯器樣式
    .newsInsiderContent {
        // max-width: 760px;
        width: 100%;
        margin: auto;
        div {
            img {
                // max-width: 500px;
                width: 100%;
                height: auto;
                margin: auto;
            }
            p {
                line-height: 200%;
                word-break: break-word;
            }
            figure {
                width: fit-content !important;
                max-width: 760px;
                margin: 0 auto;
                clear: both;
                table {
                    margin: auto;
                }
                img {
                    max-width: 760px;
                    width: 100%;
                    height: auto;
                }
            }
            a {
                width: 100%;
                max-width: 760px;
                margin: 0 auto;
                img {
                    max-width: 760px;
                    width: 100%;
                    height: auto;
                }
            }
            table,
            thead,
            tbody,
            td,
            tr {
                margin: 0 auto;
            }
        }
    }
   
  
}

.newsInsidernav {
    height: auto;
    position: relative;
    margin: 40px auto 0;
    padding-bottom: 80px;
    .newsInsidernavArrowleft {
        position: absolute;
        left: 0;
        cursor: pointer;
        &::before {
            content:"";
            position: absolute;
            top: 0;
            left: 0;
            width: 2rem;
            height: 2rem;
            background-image: url("/src/frontImage/newsbtn.svg");
            background-repeat: no-repeat;
            background-size: contain;
            @include mobile {
                width: 1rem;
                height: 1rem;
            }
        }
        .newsInsidernavPrev {
            padding: 0 0 0 3rem;
            position: relative;
            display: inline-block;
            width: fit-content;
            @include mobile {
              
            }
            .newsInsidernavlabel {
                @include fontrdtitle;
                color: $colorA;
                display: block;
                font-weight:$fontWeightB;
            }

        }
    }
    .newsInsidernavArrowRight {
        position: absolute;
        right: 0;
        cursor: pointer;
        &:hover {
            animation: hoverblink 0.3s linear 0s 1 normal both;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 2rem;
            height: 2rem;
            background-image: url("/src/frontImage/newsbtnright.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
        .newsInsidernavNext {
            padding: 0 3rem 0 0;
            position: relative;
            display: inline-block;
            width: fit-content;
            .newsInsidernavlabel {
                @include fontrdtitle;
                color: $colorA;
                display: block;
                font-weight:$fontWeightB;
                text-align: right;
            }
            .newsInsidernavDate {
                line-height: 20px;
                color: #b2b2b2;
                margin-top: 2px;
            }
        }
    }
}
a[title]:hover::after {
    content: attr(title);
    color: $colorA;
    @include fontContent;
    padding-top: 0.5rem;
    line-height: 200%;
}
