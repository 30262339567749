.notFound {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 8rem auto auto auto;
    width: 100%;

    .imgContainer {
        background-image: url("/src/frontImage/notfound.png");
        background-repeat: no-repeat;
        background-position: center;
        width: 90%;
        height: 70vh;
        background-size: contain;
        margin: 3% auto;
        @include biggerMobile {
            height: 50%;
        }
    }
    .titleContainer {
        align-items: center;
        justify-content: center;
        gap: 5rem;
        width: 90%;
        margin: auto;
        @include pad {
            flex-direction: column;
            gap: 3rem;
        }

        h4 {
            @include letterSpacingB;
            @include fontitle;
            font-weight: $fontWeightB;
            font-style: italic;
        }

        .linkContainer {
            &:hover {
                a {
                    transition: all 0.3s linear;
                    background-color: $colorB;
                    border: 2px solid $colorH;
                    span{
                        color: $colorH;
                        &::after {
                            content: "";
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 0;
                            width: 1.25rem;
                            height: 1.25rem;
                            background-image: url("/src/frontImage/Vector.svg");
                            background-repeat: no-repeat;
                            background-position: right;
                        }
                    }
                }
            }
            a {
                display: block;
                padding: 1rem 1rem 1rem 3rem;
                background-color: $colorH;
                border: 2px solid $colorH;
                @include borderRadiusA;
                span {
                    color: $colorB;
                    font-weight: $fontWeightB;
                    position: relative;
                    left: -.75rem;
                    padding-right: 2rem;
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 0;
                        width: 1.25rem;
                        height: 1.25rem;
                        background-image: url("/src/frontImage/vectorWhite.svg");
                        background-repeat: no-repeat;
                        background-position: right;
                    }
                }

                
            }
        }
    }
}
