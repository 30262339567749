.asideNav {
    position: fixed;
    z-index: 1;
    height: 100%;
    padding: 0 1.5vw 0vw 6.1vw;
    z-index: 10;
    background-color: $colorB;
    top: 0;
    bottom: 0;
    @include pad {
        display: none;
    }
    .asideNavTitleContainer {
        &:hover {
            .asideNavTitle {
                .navEnLabel{
                    transition: all 0.3s linear;
                    color: $colorE;
                }
                .navZhLabel{
                    transition: all 0.3s linear;
                    color: $colorA;
                }
                
            }
        }
        .asideNavTitle {
            @include fontBiggestTitle;
            color: $colorA;
            margin-top: 9rem;
            @include letterSpacingA;
            display: block;
            @include maxWeb {
                margin-top: 8rem;
            }
            .navEnLabel {
                @include enFont;
            }
            .navZhLabel {
                color: $colorC;
                margin-bottom: 2rem;
                display: block;
                @include letterSpacingA;
                @include fonSectitle;
                font-weight: $fontWeightB;
            }
        }
    }
    .activeTitle {
        .navEnLabel {
            color: $colorE;
        }
        .navZhLabel {
            color: $colorA;
        }
    }
    .asideNavLabelContainer {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        position: relative;

        .asideNavLabel.active {
            .asideNavLabelArrow {
                opacity: 1;
            }
        }
        .asideNavLabel {
            @include headerfont;
            @include letterSpacingB;
            font-weight: $fontWeightB;
            position: relative;
            padding-left: 1.5rem;
            padding-right: 3rem;
            cursor: pointer;

            .asideNavLabelArrow {
                position: absolute;
                background-image: url("/src/frontImage/homeLink.png");
                width: 1.9vw;
                height: 1.9vw;
                background-size: contain;
                background-position: center;
                top: 0;
                right: 0;
                background-repeat: no-repeat;
                transform: rotate(0deg);
                opacity: 0;
            }
            &::before {
                content: "";
                width: 0.5rem;
                height: 0.5rem;
                border-radius: 50%;
                background-color: $colorA;
                position: absolute;
                left: 0rem;
                top: 50%;
                transform: translateY(-50%);
            }
            &::after {
                content: "";
                position: absolute;
                background-image: url("/src/frontImage/homeLink.png");
                width: 1.9vw;
                height: 1.9vw;
                background-size: contain;
                background-position: center;
                top: 0;
                right: 0;
                background-repeat: no-repeat;
                transform: rotate(0deg);
                opacity: 0;
            }
            &:hover {
                &::after {
                    opacity: 1;
                    transition: all 0.3s linear;
                }
            }
        }
    }
}
.asideContactContainer {
    width: 11.5rem;
    margin-top: 5rem;
    .asideContact {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border: 2px solid $colorA;
        padding: 0.4rem 0rem;
        border-radius: 15px;
        width: 90%;
        cursor: pointer;
        background: $colorB;
        transition: all 0.3s linear;
        &:hover {
            background: rgb(45, 67, 144);
            background: linear-gradient(90deg, rgba(45, 67, 144, 1) 0%, rgba(7, 107, 175, 1) 100%);
            .asideContactArrow {
                transition: all 0.3s linear;
                background-image: url("/src/frontImage/vectorWhite.svg");
            }
            a {
                transition: all 0.3s linear;
                color: $colorB;
            }
            .asideContactImgContainer {
                transition: all 0.3s linear;
                background-image: url("/src/frontImage/asideContact03.svg");
            }
        }
        .asideContactImgContainer {
            width: 2.5rem;
            height: 2.5rem;
            background-image: url("/src/frontImage/asideContact01.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
        a {
            @include fontContent;
            font-weight: $fontWeightB;
            color: $colorA;
            @include letterSpacingB;
        }
        .asideContactArrow {
            width: 1.25rem;
            height: 1.25rem;
            background-image: url("/src/frontImage/asideContact02.svg");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}
