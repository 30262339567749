.footer {
  color: $colorB;
  padding: 1rem;
  background: $backgroundA;
  text-align: left;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  bottom: 0;
  z-index: 10;

  @include mobile {
    padding: 2.25rem 1rem;
  }
  @include smallerMobile {
    padding: 2.25rem 1rem;
  }

  .footerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    @include smallerPad {
      flex-wrap: wrap;
    }
    @include mobile {
      flex-direction: column;
    }

    .footerLogoContainer {
      width: 31.5vw;
      padding: 3rem;
      margin-right: 3.5rem;
      background-image: url("/src/frontImage/footerLogo.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      @include maxWeb {
        flex-wrap: wrap;
      }
      @include pad {
        width: 8rem;
        padding: 1rem;
        margin-right: 1rem;
        background-image: url("/src/frontImage/footerLogo02.svg");
      }
      @include mobile {
        width: 80%;
        margin-bottom: 0.5rem;
        background-image: url("/src/frontImage/footerLogo.svg");
      }
      @include smallerMobile {
        margin-right: unset;
      }
    }
    .detailContainer {
      @include fontSecContent;
      font-weight: $fontWeightB;
      @include letterSpacingB;
      margin: 1.5rem 0;
      word-break: break-all;
      @include smallerMobile {
        @include fontmiddleContent;
      }
      .container {
        display: flex;
        margin: 0.3rem 0;
        .mobileName {
          position: relative;
          padding-right: 0.5rem;
          display: none;
          @include biggerMobile {
            display: block;
          }
          &::after {
            content: ":";
            position: absolute;
            right: 0;
            top: 0;
          }
        }
        .name {
          position: relative;
          padding-right: 0.5rem;
          @include pad {
            display: none;
          }
          &::after {
            content: ":";
            position: absolute;
            right: 0;
            top: 0;
          }
        }
        .detail {
          letter-spacing: unset;
          margin-left: 0.3rem;
          @include mobile {
            margin-left: unset;
          }
        }
      }
    }
    .socialContainer {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: flex-end;
      gap: 1.5rem;
      font-weight: $fontWeightA;
      @include maxWeb{
        flex-grow: unset;
      }

      .social {
        display: flex;
        gap: 1rem;
        @include pad {
          display: none;
        }
        li {
          transition: all 0.5s linear;

          .socialLink {
            position: relative;
            display: block;
            width: 2.8vw;
            height: 2.8vw;
            transition: all 1s linear;

            &:hover {
              transition: all 0.5s linear;

              .socialImg {
                opacity: 1;
              }
            }
            .socialImg {
              position: absolute;
              top: 0;
              left: 0;
              opacity: 0;
              transition: all 0.5s linear;
            }
          }
          .socialIcon {
            position: absolute;
            z-index: 12;
            color: $colorA;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }
  }
}

.remarkContainer {
  @include fontSecContent;
  font-weight: $fontWeightB;
  div {
    @include maxWeb {
      align-self: center;
      margin-bottom: 1rem;
    }
    @include pad{
      align-self: flex-start;
    }
    @include biggerMobile{
      align-self: center;
    }
  }

  .copyRight {
    font-weight: $fontWeightB;
    text-align: right;
    line-height: 200%;
    @include fontSecContent;
    @include mobile {
      text-align: center;
    }
  }
}
.policyLink {
  position: relative;
  width: fit-content;
  display: block;
  &:hover {
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      background-color: $colorB;
      height: 1px;
    }
  }
}
